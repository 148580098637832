import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
// @mui
import { alpha } from "@mui/material/styles";
import {
  List,
  IconButton,
  ListItemText,
  ListItem,
  FilledInput,
} from "@mui/material";
// utils
import { fData } from "../../utils/formatNumber";
import getFileData from "../../utils/getFileData";
//
import Image from "../Image";
import { varFade } from "../animate";
import { Close, DeleteForeverOutlined } from "@mui/icons-material";

// ----------------------------------------------------------------------

MultiFilePreview.propTypes = {
  files: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  showPreview: PropTypes.bool,
};

export default function MultiFilePreview({
  showPreview = false,
  files,
  onRemove,
}) {
  const hasFile = files.length > 0;

  return (
    <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
      <AnimatePresence>
        {files.map((file, index) => {
          const { key, name, size, preview, path } = getFileData(file, index);
          if (showPreview) {
            return (
              <ListItem
                key={index}
                component={motion.div}
                {...varFade().inRight}
                sx={{
                  p: 0,
                  m: 0.5,
                  width: 80,
                  height: 80,
                  borderRadius: 1.25,
                  overflow: "hidden",
                  position: "relative",
                  display: "inline-flex",
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                }}
              >
                <Image
                  alt="preview"
                  src={
                    preview === undefined ? path || file?.image_url : preview
                  } //!IMPORTANT validamos para que acepte el dato tipo File o String de blob
                  ratio="1/1"
                />

                {onRemove && (
                  <IconButton
                    onClick={() => onRemove(file)}
                    sx={{
                      top: 6,
                      p: "2px",
                      right: 6,
                      position: "absolute",
                      color: "common.white",
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                      "&:hover": {
                        bgcolor: (theme) =>
                          alpha(theme.palette.grey[900], 0.48),
                      },
                    }}
                  >
                    <Close sx={{ fontSize: 14 }} />
                  </IconButton>
                )}
              </ListItem>
            );
          }

          return (
            <ListItem
              key={key}
              component={motion.div}
              {...varFade().inRight}
              sx={{
                my: 1,
                px: 2,
                py: 0.75,
                borderRadius: 0.75,
                border: (theme) => `solid 1px ${theme.palette.divider}`,
              }}
            >
              <FilledInput
                sx={{ width: 28, height: 28, color: "text.secondary", mr: 2 }}
              />

              <ListItemText
                primary={typeof file === "string" ? file : name}
                secondary={typeof file === "string" ? "" : fData(size || 0)}
                primaryTypographyProps={{ variant: "subtitle2" }}
                secondaryTypographyProps={{ variant: "caption" }}
              />

              {onRemove && (
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() => onRemove(file)}
                >
                  <DeleteForeverOutlined />
                </IconButton>
              )}
            </ListItem>
          );
        })}
      </AnimatePresence>
    </List>
  );
}
