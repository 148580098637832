import React, { useCallback, useEffect } from "react";
import InitDialog from "../../../../../components/InitDialog";
// Formularios
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFTextField,
  RHFUploadSingleFile,
} from "../../../../../components/hook-form";

import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { openModal } from "../../../../../redux/slices/global";
import { Button, Stack, Typography } from "@mui/material";
import {
  createVariantVehicle,
  updateVariantVehicle,
} from "../../../../../redux/slices/variantVehicle";
import { OPERATION_TYPE, VARIANT_TYPES } from "../../../../../data/dataGeneral";
import { fData } from "../../../../../utils/formatNumber";

const FormVariantColorVehicle = ({
  title,
  isOperation,
  idSelectVehicle,
  dataIdVariantVehicle,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.variantVehicle);
  // valores
  const VARIANT_TYPE = VARIANT_TYPES.color.id;
  const defaultValues = {
    variant_type: VARIANT_TYPE, // 1: color, 2: version
    name: "",
    image: "",
    miniature_image: "",
    vehicle_id: idSelectVehicle,
  };

  // Validaciones
  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Este campo es requerido"),
    vehicle_id: Yup.string().required("Este campo es requerido"),
  });

  // Obtener los valores del formulario
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const handleDropIconColor = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue("miniature_image", file);
      }
    },
    [setValue]
  );

  const handleDropVehicle = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue("image", file);
      }
    },
    [setValue]
  );

  const onSubmit = async (data) => {
    if (isOperation === OPERATION_TYPE.CREATE) {
      // Enviamos la data como FormData para poder enviar el archivo
      const formDataCreate = new FormData();
      formDataCreate.append("variant_type", VARIANT_TYPE);
      formDataCreate.append("name", data.name);
      formDataCreate.append("miniature_image", data.miniature_image);
      formDataCreate.append("image", data.image);
      formDataCreate.append("vehicle_id", data.vehicle_id);

      const result = await dispatch(
        createVariantVehicle(formDataCreate, idSelectVehicle)
      );
      if (result.status === 201) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(
          result.data.message ||
          "Error al crear la especificación del vehículo",
          {
            variant: "error",
          }
        );
      }
    } else if (isOperation === OPERATION_TYPE.UPDATE) {
      const dataFormUpdate = new FormData();
      dataFormUpdate.append("variant_type", VARIANT_TYPE);
      dataFormUpdate.append("name", data.name);
      dataFormUpdate.append("miniature_image", data.miniature_image);
      dataFormUpdate.append("image", data.image);

      const result = await dispatch(
        updateVariantVehicle(
          dataFormUpdate,
          dataIdVariantVehicle?.id,
          idSelectVehicle
        )
      );
      if (result.status === 200) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(
          JSON.stringify(result.message) || "Error al actualizar",
          { variant: "error" }
        );
      }
    }
  };

  // Autocompletar los campos solo para editar isOperation = 2
  useEffect(() => {
    // Autocompletamos con la data que nos llega
    if (
      isOperation === OPERATION_TYPE.UPDATE &&
      dataIdVariantVehicle !== null
    ) {
      const data = {
        name: dataIdVariantVehicle.name,
        vehicle_id: dataIdVariantVehicle.vehicle_id,
        miniature_image: dataIdVariantVehicle.miniature,
        image: dataIdVariantVehicle.image_url,
      };
      reset(data);
    }
  }, [dispatch, dataIdVariantVehicle, isOperation, reset]);

  return (
    <InitDialog title={title} fullWidth loading={isLoading} size="md">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ m: 1 }} spacing={2}>
          <Stack direction={{ xs: "column", md: "column" }} spacing={2}>
            <RHFTextField name="name" label="Descripción" autoComplete="off" />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <div>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Icono de color (40 x 40), Máximo {fData(10000)}
              </Typography>
              <RHFUploadSingleFile
                disabled={isLoading}
                name="miniature_image"
                accept={{
                  "image/*": [".jpeg", ".png", ".jpg", ".svg", ".webp"],
                }}
                maxSize={10000} // 10kb
                onDrop={handleDropIconColor}
              />
            </div>

            <div>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Imagen del vehículo (880 x 540), Máximo {fData(512000)}
              </Typography>
              <RHFUploadSingleFile
                disabled={isLoading}
                name="image"
                accept={{
                  "image/*": [".jpeg", ".png", ".jpg", ".svg", ".webp"],
                }}
                maxSize={512000} // 500kb
                onDrop={handleDropVehicle}
              />
            </div>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", mx: 1, mt: 2, gap: 2 }}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ minWidth: 100 }}
            onClick={() => {
              dispatch(openModal(false));
            }}
          >
            Cancelar
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            disabled={isLoading || isSubmitting}
          >
            Guardar
          </Button>
        </Stack>
      </FormProvider>
    </InitDialog>
  );
};

export default FormVariantColorVehicle;