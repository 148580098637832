import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add,
  DeleteForever,
  EditOutlined,
  MoreVertOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { InitDialogAlert } from "../../../../../components/InitDialogs";
import { TableGeneral } from "../../../../../components/InitTables";
import MenuPopover from "../../../../../components/MenuPopover";
import FormModel from "./FormDialog/FormModel";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { dataModel, deleteModel } from "../../../../../redux/slices/model";
import { openModal } from "../../../../../redux/slices/global";

const Model = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isOpenModal } = useSelector((state) => state.global);
  const { models, pagination } = useSelector((state) => state.model);
  const { total, currentPage, lastPage } = pagination;

  const [open, setOpen] = useState(null);
  const [isFormModel, setIsFormModel] = useState(false);
  const [operation, setOperation] = useState(1); // 1 = Crear / 2= Editar
  const [openDialogDeleteModel, setOpenDialogDeleteModel] = useState(false);
  const [idSelectModel, setIdSelectModel] = useState(null);

  // Paginacion
  const handleChangePagination = (event, value) => {
    dispatch(dataModel({ page: value, search: "" }));
  };

  // Buscador
  const handleChangeSearch = async (event) => {
    event.preventDefault();
    dispatch(
      dataModel({
        page: 1,
        search: event.target.value,
      })
    );
  };

  const handleOpenMenuPop = (event) => {
    setOpen(event.currentTarget);
  };

  const handleDeleteModel = async (id) => {
    // validamos para regresas anterior o no
    if (currentPage === lastPage && total % 10 === 1) {
      const result = await dispatch(
        deleteModel(id, {
          page: currentPage - 1,
          search: "",
        })
      );
      if (result.status === 200) {
        setOpenDialogDeleteModel(false);
        enqueueSnackbar(result.data.message, { variant: "success" });
        setOpen(null);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      const result = await dispatch(
        deleteModel(id, {
          page: currentPage,
          search: "",
        })
      );
      if (result.status === 200) {
        setOpenDialogDeleteModel(false);
        enqueueSnackbar(result.data.message, { variant: "success" });
        setOpen(null);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const tableHeaders = [
    { id: "id", label: "N°" },
    { id: "description", label: "MODELO", align: "left" },
    { id: "trademark", label: "MARCA", align: "left" },
    { id: "category", label: "CATEGORIA", align: "left" },
    { id: "actions", label: "ACCIONES", align: "right" },
  ];

  const DATA_TRADEMARK = models?.map((item, index) => ({
    id: index + 1,
    description: item.description,
    category: item.category,
    trademark: item.trademark,
    actions: (
      <>
        <IconButton
          onClick={(e) => {
            handleOpenMenuPop(e);
            setIdSelectModel(item.id);
          }}
        >
          <MoreVertOutlined />
        </IconButton>

        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={() => setOpen(null)}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          arrow="right-top"
          sx={{
            boxShadow: "none",
            borderRadius: 2,
            mt: -1,
            width: 160,
            background: (theme) => theme.palette.background.paper,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
              "& svg": { mr: 2, width: 20, height: 20 },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              dispatch(openModal(true));
              setIsFormModel(true);
              setOperation(2);
            }}
          >
            <EditOutlined />
            Editar
          </MenuItem>

          <Divider sx={{ borderStyle: "dashed" }} />

          <MenuItem
            onClick={() => {
              setOpenDialogDeleteModel(true);
            }}
            sx={{ color: "error.main" }}
          >
            <DeleteForever />
            Eliminar
          </MenuItem>
        </MenuPopover>
      </>
    ),
  }));

  useEffect(() => {
    dispatch(
      dataModel({
        page: 1,
        search: "",
      })
    );
  }, [dispatch]);

  return (
    <>
      <Stack alignItems="end" sx={{ mt: -2, mb: 1 }}>
        <Button
          variant="contained"
          startIcon={<Add />}
          sx={{ textTransform: "none" }}
          onClick={() => {
            dispatch(openModal(true));
            setIsFormModel(true);
            setOperation(1);
            setIdSelectModel(null);
          }}
        >
          Nuevo modelo
        </Button>
      </Stack>

      <Stack direction="row" justifyContent="flex-end" sx={{ p: 1 }}>
        <TextField
          onChange={handleChangeSearch}
          variant="outlined"
          placeholder="Buscar modelo..."
          sx={{ m: 1, width: "40ch" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <TableGeneral rows={DATA_TRADEMARK} headLabel={tableHeaders} />

      {/* PAGINACION */}
      <Stack
        direction="row"
        sx={{ p: 2, textAlign: "center", justifyContent: "flex-end" }}
      >
        <Typography variant="caption" sx={{ marginTop: "8px" }}>
          TOTAL: {total}
        </Typography>
        <Pagination
          count={lastPage}
          page={currentPage}
          onChange={handleChangePagination}
          showFirstButton
          showLastButton
        />
      </Stack>
      {/* PAGINACION */}

      {isOpenModal && isFormModel ? (
        <FormModel
          title={operation === 1 ? "NUEVO MODELO" : "NUEVO MODELO"}
          operation={operation}
          idSelectModel={idSelectModel}
          setOpen={setOpen}
        />
      ) : null}

      {/* Alerta eliminar el MODELO */}
      <InitDialogAlert
        isOpen={openDialogDeleteModel}
        setOpen={setOpenDialogDeleteModel}
        onOk={() => handleDeleteModel(idSelectModel)}
        title={`¿Desea eliminar "${models?.find((item) => item.id === idSelectModel)?.description
          }"? `}
        type="error"
      />
    </>
  );
};

export default Model;
