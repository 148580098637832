import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { fCurrencyPEN, fCurrencyUSD } from "../../../../utils/formatNumber";
import { VARIANT_TYPES } from "../../../../data/dataGeneral";
import { Stack } from "@mui/system";

const CardVariant = ({ item, onClickDelete, onClickEdit }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        sx={{
          height: "max-content",
        }}
      >
        {item.variant_type === VARIANT_TYPES.color.id && (
          <Stack sx={{ width: "40px", height: "40px", position: "absolute" }}>
            <CardMedia
              component="img"
              height="40"
              image={item?.miniature}
              alt={item?.name}
            />
          </Stack>
        )}

        <CardMedia
          component="img"
          height="140"
          image={item?.image_url}
          alt={item?.name}
        />
        <CardContent>
          {item?.variant_type === 1 ? (
            <Typography gutterBottom variant="h2" component="div">
              {item.name}
            </Typography>
          ) : (
            <>
              <Typography gutterBottom variant="h2" component="div">
                {JSON.parse(item?.name)?.model}
              </Typography>
              <Typography gutterBottom variant="subtitle2" component="div">
                Precio: S/.
                {fCurrencyPEN(JSON.parse(item?.name)?.price_pen)} ó{" "}
                {fCurrencyUSD(JSON.parse(item?.name)?.price_usd)}
              </Typography>
            </>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={() => onClickDelete(item)}
          >
            Eliminar
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => onClickEdit(item)}
          >
            Editar
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default CardVariant;