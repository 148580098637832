import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  query: false,
  alert: {
    message: false,
    type: "success",
  },
  isOpenSiderbar: false,
  isOpenModal: false,
  params: {
    gender: [
      {
        id: "F",
        description: "Femenino",
      },
      {
        id: "M",
        description: "Masculino",
      },
      {
        id: "O",
        description: "Otros",
      },
    ],
    typeDocument: [
      {
        id: "1",
        slug: "DNI",
        description: "Documento de Identidad Nacional",
      },
      {
        id: "2",
        slug: "C.Ext",
        description: "Carnet de Extranjería",
      },
    ],
    services: [
      {
        id: 1,
        name: "Tv Cable",
      },
      {
        id: 2,
        name: "Estacionamiento Gratis",
      },
      {
        id: 3,
        name: "Aire acondicionado",
      },
      {
        id: 4,
        name: "Wifi ",
      },
    ],
    status_room: [
      {
        id: 0,
        backgroundColor: "#00AB55",
      },
      {
        id: 1,
        backgroundColor: "#ff7800",
      },
      {
        id: 2,
        backgroundColor: "#ef2020",
      },
      {
        id: 3,
        backgroundColor: "#8b8b8b",
      },
    ],
    typeOperation: [
      {
        id: "1",
        text: "Reserva",
      },
      {
        id: "2",
        text: "Alquiler",
      },
    ],
  },
  parameters: {},
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    // REGISTER SEARCH
    setSeach(state, action) {
      state.query = action.payload;
    },

    // ALERT REGISTER
    setAlert(state, action) {
      state.alert.message = action.payload ? action.payload.message : false;
      state.alert.type = action.payload ? action.payload.type : false;
    },

    // SLIDERBAR
    setOpenSiderbar(state, action) {
      state.isOpenSiderbar = action.payload;
    },

    // MODAL
    setOpenModal(state, action) {
      state.isOpenModal = action.payload;
    },
    loadParameters(state, action) {
      state.parameters = { ...state.parameters, ...action.payload };
    },
  },
});
export default globalSlice.reducer;

export const {
  setSeach,
  setAlert,
  setOpenSiderbar,
  setOpenModal,
  loadParameters,
} = globalSlice.actions;
// ----------------------------------------------------------------------

export function registerSeach(payload) {
  return (dispatch) => {
    dispatch(setSeach(payload || false));
  };
}

export function registerAlert(payload) {
  return (dispatch) => {
    dispatch(setAlert(payload));
  };
}

export function openSiderbar(payload) {
  return (dispatch) => {
    dispatch(setOpenSiderbar(payload));
  };
}

export function openModal(payload) {
  return (dispatch) => {
    dispatch(setOpenModal(payload));
  };
}
