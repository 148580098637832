import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  generic: {},
  me: {},
};

const genericSlice = createSlice({
  name: "GENERIC",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Get Generic
    getGenericSuccess(state, action) {
      state.isLoading = false;
      state.message = "Obteniendo datos genericos";
      state.generic = action.payload;
      state.me = action.payload.me;
    },

    // Elimnar documentos de prueba
    deleteDocumentsuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },
  },
});

// Reducer - Aca va la data o los valores
export default genericSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  getGenericSuccess,
  deleteDocumentsuccess,
} = genericSlice.actions;

// ========Funciones===========

export function getGeneric() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get("/generic");
      dispatch(getGenericSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// Eliminar documentos
export function deleteDocuments() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.delete("/options/documents");
      dispatch(deleteDocumentsuccess(response.data.message));
      return response.data;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error;
    }
  };
}
