import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  participant: [],
  participants: [],
  participantsAll: [],
  pagination: {
    total: 0,
    currentPage: 0,
    pageSize: 0,
    lastPage: 0,
  },
};

const dataPrivacySlice = createSlice({
  name: "dataPrivacy",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    dataPrivacySuccess(state, action) {
      const pagination = action.payload.meta;
      state.isError = false;
      state.isLoading = false;
      state.message = "Obtuvimos todos los derechos arco 🙌";
      state.participants = action.payload.data;
      // Paginacion
      state.pagination.currentPage = pagination.current_page;
      state.pagination.pageSize = pagination.per_page * 1;
      state.pagination.total = pagination.total;
      state.pagination.lastPage = pagination.last_page;
    },
    exportDataPrivacySuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.message = "Exportamos todos los derechos arco 🙌";
      state.participantsAll = action.payload.data;
    },
  },
});

// Reducer - Aca va la data o los valores
export default dataPrivacySlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  dataPrivacySuccess,
  exportDataPrivacySuccess,
} = dataPrivacySlice.actions;

// ========Funciones===========
// -------------------------------------------------------

// Data de  data de privacidad derechos arco
// -------------------------------------------------------
export function dataPrivacy(query) {
  const { is_paginate = true, page, search } = query;
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(
        `data-privacy-rights/records?is_paginate=${is_paginate}&per_page=15&page=${page}&search=${search}`
      );
      dispatch(dataPrivacySuccess(response?.data));
      return response?.data;
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}

// Para exportar data de privacidad
// -------------------------------------------------------
export function exportDataPrivacy() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`data-privacy-rights/records`);
      dispatch(exportDataPrivacySuccess(response?.data));
      return response?.data?.data;
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}
