import React, { useEffect } from "react";
import { Button, MenuItem, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
// componentes
import InitDialog from "../../../../../../components/InitDialog";
// formularios
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFSelect2,
  RHFTextField,
} from "../../../../../../components/hook-form";

// redux
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../../redux/slices/global";
import {
  createModel,
  getDataIdModel,
  updateModel,
  getTableModel,
} from "../../../../../../redux/slices/model";

const FormModel = ({ title, operation, idSelectModel, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, trademarks, categories, pagination } = useSelector(
    (state) => state.model
  );
  const { currentPage } = pagination;

  // valores
  const defaultValues = {
    description: "",
    trademark_id: "",
    category_id: "",
  };

  // Validaciones
  const FormSchema = Yup.object().shape({
    description: Yup.string().required("Descripción es requerido"),
    trademark_id: Yup.string().required("Marca es requerido"),
    category_id: Yup.string().required("Categoría es requerido"),
  });

  // Obtener los valores del formulario
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  const onSubmit = async (data) => {
    if (operation === 1) {
      //crear nuevo
      const result = await dispatch(
        createModel(data, {
          page: currentPage,
          search: "",
        })
      );
      if (result.status === 201) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      // editar
      const result = await dispatch(
        updateModel(idSelectModel, data, {
          page: currentPage,
          search: "",
        })
      );
      if (result.status === 200) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
        setOpen(null);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  // Autocompletar los campos solo para editar Operation = 2
  useEffect(() => {
    if (operation === 2 && idSelectModel !== null) {
      const data = async () => {
        const result = await dispatch(getDataIdModel(idSelectModel));
        const data = {
          description: result?.description,
          trademark_id: result?.trademark_id,
          category_id: result?.category_id,
        };
        reset(data);
      };
      data();
    }
  }, [dispatch, idSelectModel, operation, reset]);

  // Obtener tables
  useEffect(() => {
    dispatch(getTableModel());
  }, [dispatch]);

  return (
    <InitDialog title={title} size="xs" fullWidth loading={isLoading}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ m: 1 }}>
          <Stack
            direction={{ xs: "column", md: "column" }}
            sx={{ alignItems: "flex-start" }}
            spacing={2}
          >
            <RHFSelect2 name="trademark_id" label="Marca">
              {trademarks?.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </RHFSelect2>

            <RHFSelect2 name="category_id" label="Categoría">
              {categories?.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </RHFSelect2>

            <RHFTextField
              name="description"
              label="Descripción del modelo"
              autoComplete="off"
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", mx: 1, mt: 2 }}
          spacing={2}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ minWidth: 100 }}
            onClick={() => {
              dispatch(openModal(false));
            }}
          >
            Cancelar
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            disabled={!isDirty || isSubmitting}
          >
            Guardar
          </Button>
        </Stack>
      </FormProvider>
    </InitDialog>
  );
};

export default FormModel;
