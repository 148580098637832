import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { isNil } from "lodash";
import TableHeadCustom from "./TableHeadCustom";
import { LinearProgress, Paper, Stack, Typography } from "@mui/material";
import Scrollbar from "../Scrollbar";

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   borderColor: theme.palette.grey[50024],
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   border: 'none',
//   '&:nth-of-type(odd)': {
//     // backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   '&:last-child td, &:last-child th': {
//     border: 0,
//   },
//   ':hover': {
//     backgroundColor: theme.palette.action.hover,
//   },
// }));

const TableGeneral = ({ rows, headLabel, isLoading = false }) => {
  return (
    <TableContainer component={Paper}>
      <Scrollbar>
        {isLoading && <LinearProgress />}
        <Table aria-label="customized table" size="small">


          <TableHeadCustom
            headLabel={headLabel}
            sx={{ backgroundColor: (theme) => theme.palette.grey[50024] }}
          />

          {/* <TableHead>
          <TableRow>
            {headLabel?.map((header) => (
              <StyledTableCell
                key={header.title}
                align={header.align}
                sx={{ width: isNil(header.width) ? 'auto' : header.width }}
              >
                {header.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead> */}

          <TableBody>
            {rows?.map((row, i) => (
              <TableRow
                sx={{
                  border: "none",
                  "&:nth-of-type(odd)": {
                    // backgroundColor: theme.palette.action.hover,
                  },
                  // hide last border
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                  ":hover": {
                    // backgroundColor: (theme) => theme.palette.action.hover,
                    bgcolor: "action.hover",
                  },
                }}
                key={row.id || i}
              >
                {headLabel.map((prop, index) =>
                  index === 0 ? (
                    <TableCell
                      sx={{
                        borderColor: (theme) => theme.palette.grey[50024],
                        [`&.${tableCellClasses.head}`]: {
                          backgroundColor: (theme) => theme.palette.primary.main,
                          color: (theme) => theme.palette.common.white,
                        },
                        [`&.${tableCellClasses.body}`]: {
                          fontSize: 14,
                        },
                      }}
                      key={`${row[headLabel[0].id]}_${row[prop.id]}`}
                      component="th"
                      scope="row"
                    >
                      {row[prop.id]}
                    </TableCell>
                  ) : (
                    <TableCell
                      key={`${row.id}_${prop.id}`}
                      align={isNil(prop.align) ? "left" : prop.align}
                      sx={{
                        width: isNil(prop.width) ? "auto" : prop.width,
                        borderColor: (theme) => theme.palette.grey[50024],
                        [`&.${tableCellClasses.head}`]: {
                          backgroundColor: (theme) => theme.palette.primary.main,
                          color: (theme) => theme.palette.common.white,
                        },
                        [`&.${tableCellClasses.body}`]: {
                          fontSize: 14,
                        },
                      }}
                    >
                      {row[prop.id]}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Not Data */}
        <Stack
          justifyContent="center"
          alignItems="center"
          direction={{ xs: "column", md: "row", padding: 4, margin: 20 }}
        >
          {rows?.length === 0 && (
            <Stack sx={{ minHeight: 400, justifyContent: "center" }}>
              <Typography variant="subtitle1">No existe registros</Typography>
            </Stack>
          )}
        </Stack>
      </Scrollbar>

    </TableContainer>
  );
};

TableGeneral.propTypes = {
  headLabel: PropTypes.array,
  isLoading: PropTypes.bool,
  rows: PropTypes.array,
};
export default TableGeneral;
