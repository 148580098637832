import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
// componentes
import InitDialog from "../../../../components/InitDialog";

// formularios
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
  RHFUploadSingleFile,
} from "../../../../components/hook-form";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "../../../../redux/slices/global";
import {
  createPublication,
  getDataIdPublication,
  updatePublication,
} from "../../../../redux/slices/publication";
import { Button, Stack, Typography } from "@mui/material";
import { useCallback } from "react";

const FormPublication = ({ title, operation, idSelectPublication, valueSearch }) => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { isLoading, pagination } = useSelector((state) => state.publication);
  const { currentPage } = pagination;

  // valores
  const defaultValues = {
    image: "",
    url: "",
    description: "",
    is_publish: false,
  };

  // TODO: VALIDAR LOS TIPOS DE IMAGNES QUE QUIERE EL CLIENTE
  // Validaciones
  const FormSchema = Yup.object().shape({
    // validar solo varios tipos de imagenes es importante que sea MIME TYPE y no solo extension de archivo y que sea un array con yup mixed
    // image: Yup.required("Por favor seleccione una imagen."),
    image:
      operation === 1
        ? Yup.mixed()
          .required("Por favor seleccione una imagen.")
          .test(
            "is-image",
            "Solo se permiten archivos de imagen.",
            (value) => {
              if (!value) return false;
              return [
                "image/png",
                "image/jpeg",
                "image/jpg",
                "image/svg+xml",
                "image/webp",
              ].includes(value.type);
            }
          )
        : Yup.mixed().required("Por favor seleccione una imagen."),

    url: Yup.string().required("Por favor ingrese una url."),
    description: Yup.string().required("Por favor ingrese una descripción."),
  });

  // Obtener los valores del formulario
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    // Enviamos la data como FormData para poder enviar el archivo
    const formData = new FormData();
    formData.append("image", data.image);
    formData.append("url", data.url);
    formData.append("description", data.description);
    formData.append("is_publish", data.is_publish);

    if (operation === 1) {
      //crear nuevo
      const result = await dispatch(
        createPublication(formData, {
          page: currentPage,
          search: "",
        })
      );

      if (result.status === 201) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(<div>{JSON.stringify(result.message)}</div>, {
          variant: "error",
        });
      }
    } else {
      // editar
      const result = await dispatch(
        updatePublication(idSelectPublication, formData, {
          page: currentPage,
          search: valueSearch,
        })
      );
      if (result.status === 200) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(<div>{JSON.stringify(result.message)}</div>, {
          variant: "error",
        });
      }
    }
  };

  // Para la imagen
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue("image", file);
        // Quitamos URL.createObjectURL(file) para que podamos tener en formato FILE y no en formato BLOB para poder enviarlo al backend
        // Pero para poder previsualizar modificamos en el componente RHFUploadSingleFile
        // setValue(
        //   "cover",
        //   Object.assign(file, {
        //     preview: URL.createObjectURL(file),
        //   })
        // );
      }
    },
    [setValue]
  );

  // Autocompletar los campos solo para editar Operation = 2
  useEffect(() => {
    if (operation === 2 && idSelectPublication !== null) {
      const data = async () => {
        const result = await dispatch(
          getDataIdPublication(idSelectPublication)
        );
        const data = {
          image: result?.image_url,
          url: result?.url,
          description: result?.description,
          is_publish: result?.is_publish,
        };
        reset(data);
      };
      data();
    }
  }, [dispatch, idSelectPublication, operation, reset]);

  return (
    <InitDialog title={title} size="md" fullWidth loading={isLoading}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ m: 1 }}>
          <Stack spacing={{ xs: 2, md: 2 }}>
            <Stack
              direction={{ xs: "column", md: "column" }}
              sx={{ alignItems: "flex-start" }}
              spacing={2}
            >
              <RHFTextField
                name="description"
                label="Descripción"
                autoComplete="off"
              />
              <RHFTextField
                name="url"
                label="URL de la publicación"
                autoComplete="off"
              />
              <RHFCheckbox
                name="is_publish"
                label="Publicar?"
                autoComplete="off"
              />
            </Stack>

            <Typography variant="subtitle2">
              Cargar Banner (300 x 300)
            </Typography>
            <div>
              <RHFUploadSingleFile
                name="image"
                disabled={isLoading}
                accept={{
                  "image/*": [".jpeg", ".png", ".jpg", ".svg", ".webp"],
                }}
                maxSize={3145728} // 3MB TODO: Consultar a que tamaño se debe limitar
                onDrop={handleDrop}
              />
            </div>
          </Stack>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-end", mx: 1, mt: 2 }}
            spacing={2}
          >
            <Button
              variant="outlined"
              color="error"
              sx={{ minWidth: 100 }}
              onClick={() => {
                dispatch(openModal(false));
              }}
            >
              Cancelar
            </Button>
            <Button
              size="large"
              type="submit"
              variant="contained"
              disabled={isSubmitting || isLoading}
            >
              Guardar
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </InitDialog>
  );
};

export default FormPublication;
