import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  category: [],
  categories: [],
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Lista de slayers
    dataCategoriesSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.categories = action.payload;
      state.message = "Obtuvimos todos los slayers 🙌";
    },

    // Obtener data del sliders por ID
    getDataIdCategorySuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.category = action.payload;
      state.message = "Obtuvimos data del slayer 🙌";
    },

    //Crear slayer
    createCategorySuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Actualizar slayer
    updateCategorySuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Eliminar slayer
    deleteCategorySuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },
  },
});

// Reducer - Aca va la data o los valores
export default categorySlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  dataCategoriesSuccess,
  getDataIdCategorySuccess,
  createCategorySuccess,
  updateCategorySuccess,
  deleteCategorySuccess,
} = categorySlice.actions;

// ========Funciones===========

// Data de las categorias
// -------------------------------------------------------
export function dataCategories() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`/categories/records`);
      dispatch(dataCategoriesSuccess(response?.data?.data));
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}

// Obtener dato de categoria por ID
// -------------------------------------------------------
export function getDataIdCategory(id) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`/categories/record/${id}`);
      dispatch(getDataIdCategorySuccess(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error;
    }
  };
}

// Crear categoria
// -------------------------------------------------------
export function createCategory(data) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.post("/categories", data);
      dispatch(createCategorySuccess(response.data.message));
      dispatch(dataCategories()); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Actualizar categoria
// -------------------------------------------------------
export function updateCategory(id, data) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.put(`/categories/${id}`, data);
      dispatch(updateCategorySuccess(response.data.message));
      dispatch(dataCategories()); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Eliminar  categoria
// -------------------------------------------------------
export function deleteCategory(id) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.delete(`/categories/${id}`);
      dispatch(deleteCategorySuccess(response.data.message));
      dispatch(dataCategories()); // actualizamos la lista de categoria
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}
