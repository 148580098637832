import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  specificationsVehicle: [],
};

const specificationVehicleSlice = createSlice({
  name: "specificationVehicle",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Obtener data especificacion del vehiculo
    getSpecificationVehicleSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.specificationsVehicle = action.payload;
      state.message = "Obtuvimos data del vehiculo 🚗";
    },

    //Crear especificacion del vehiculo
    createSpecificationVehicleSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Actualizar especificacion del vehiculo
    updateSpecificationVehicleSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Eliminar especificacion del vehiculo
    deleteSpecificationVehicleSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },
  },
});

// Reducer - Aca va la data o los valores
export default specificationVehicleSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  getSpecificationVehicleSuccess,
  createSpecificationVehicleSuccess,
  updateSpecificationVehicleSuccess,
  deleteSpecificationVehicleSuccess,
} = specificationVehicleSlice.actions;

// ========Funciones===========

// Obtener especificacion del vehiculo por ID
// -------------------------------------------------------
export function getSpecificationVehicle(idVehicle) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(
        `/specifications/records/${idVehicle}`
      );
      dispatch(getSpecificationVehicleSuccess(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error;
    }
  };
}

// Crear especificacion del vehiculo
// -------------------------------------------------------
export function createSpecificationVehicle(data, idVehicle) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.post("/specifications", data);
      dispatch(createSpecificationVehicleSuccess(response.data.message));
      dispatch(getSpecificationVehicle(idVehicle));
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Actualizar  especificacion del vehiculo
// -------------------------------------------------------
export function updateSpecificationVehicle(
  data,
  idSpecificationVehicle,
  idVehicle
) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.put(
        `/specifications/${idSpecificationVehicle}`,
        data
      );
      dispatch(updateSpecificationVehicleSuccess(response.data.message));
      dispatch(getSpecificationVehicle(idVehicle));
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Eliminar  especificacion del vehiculo
// -------------------------------------------------------
export function deleteSpecificationVehicle(idSpecificationVehicle, idVehicle) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.delete(
        `/specifications/${idSpecificationVehicle}`
      );
      dispatch(deleteSpecificationVehicleSuccess(response.data.message));
      dispatch(getSpecificationVehicle(idVehicle));
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}
