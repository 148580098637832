export const arrayIsUndefinned = (array) => array === undefined;
export const arrayIsEmpty = (array) => array.length === 0;
export const objectIsEmpty = (object) => object[0] === undefined;
export const validateError = (error, errorByModule) => {
  if (typeof error === "string") return error;

  if (!errorByModule) return JSON.stringify(error);

  let errorMessage = "";
  for (const key in error) {
    const message = errorByModule[key]
      ? errorByModule[key].concat(" | ", JSON.stringify(error[key]))
      : JSON.stringify(error[key]);
    errorMessage = errorMessage ? errorMessage.concat(", ", message) : message;
  }

  return errorMessage || JSON.stringify(error);
};