import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  variantsVehicle: [],
};

const variantVehicleSlice = createSlice({
  name: "variantVehicle",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Obtener data variante del vehiculo
    getVariantVehicleSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.variantsVehicle = action.payload;
      state.message = "Obtuvimos data del vehiculo 🚗";
    },

    //Crear variante del vehiculo
    createVariantVehicleSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Actualizar variante del vehiculo
    updateVariantVehicleSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Eliminar variante del vehiculo
    deleteVariantVehicleSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },
  },
});

// Reducer - Aca va la data o los valores
export default variantVehicleSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  getVariantVehicleSuccess,
  createVariantVehicleSuccess,
  updateVariantVehicleSuccess,
  deleteVariantVehicleSuccess,
} = variantVehicleSlice.actions;

// ========Funciones===========

// Obtener variante del vehiculo por ID
// -------------------------------------------------------
export function getVariantVehicle(idVehicle) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`/variants/records/${idVehicle}`);
      dispatch(getVariantVehicleSuccess(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error;
    }
  };
}

// Crear variante del vehiculo
// -------------------------------------------------------
export function createVariantVehicle(data, idVehicle) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.post("/variants", data);
      dispatch(createVariantVehicleSuccess(response.data.message));
      dispatch(getVariantVehicle(idVehicle));
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Actualizar  variante del vehiculo
// -------------------------------------------------------
export function updateVariantVehicle(data, idVariantVehicle, idVehicle) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.put(
        `/variants/${idVariantVehicle}`,
        data
      );
      dispatch(updateVariantVehicleSuccess(response.data.message));
      dispatch(getVariantVehicle(idVehicle));
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Eliminar  variante del vehiculo
// -------------------------------------------------------
export function deleteVariantVehicle(idVariantVehicle, idVehicle) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.delete(
        `/variants/${idVariantVehicle}`
      );
      dispatch(deleteVariantVehicleSuccess(response.data.message));
      dispatch(getVariantVehicle(idVehicle));
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}
