import React, { useCallback, useEffect } from "react";
import InitDialog from "../../../../../components/InitDialog";
// Formularios
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFEditor,
  RHFTextField,
  RHFUploadSingleFile,
} from "../../../../../components/hook-form";
import {
  getDataIdVehicle,
  getTableVehicle,
  updateVehicle,
} from "../../../../../redux/slices/vehicle";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { openModal } from "../../../../../redux/slices/global";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { HighlightOffOutlined, Upload } from "@mui/icons-material";

const FormFichaTecnicaVehicle = ({
  title,
  idSelectVehicle,
  setOpenModalPop,
  valueSearch
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, pagination } = useSelector((state) => state.vehicle);
  const { currentPage } = pagination;

  // valores
  const defaultValues = {
    title: "",
    description: "",
    subtitle: "",
    footer_image: "",
    data_sheet: "",
  };

  // Validaciones
  const FormSchema = Yup.object().shape({
    // maximo 100 caracteres en name
  });

  // Obtener los valores del formulario
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    const DataUpdate = {
      footer: {
        title: data.title,
        description: data.description,
        subtitle: data.subtitle,
      },
      data_sheet: data.data_sheet,
      footer_image: data.footer_image,
    };
    // ACTUALIZAR
    const formDataUpdate = new FormData();

    formDataUpdate.append("footer", JSON.stringify(DataUpdate.footer));
    formDataUpdate.append("footer_image", DataUpdate.footer_image);
    formDataUpdate.append("data_sheet", DataUpdate.data_sheet);

    const result = await dispatch(
      updateVehicle(idSelectVehicle, formDataUpdate, {
        page: currentPage,
        search: valueSearch,
      })
    );
    if (result.status === 200) {
      enqueueSnackbar(result?.data?.message, { variant: "success" });
      dispatch(openModal(!true));
      setOpenModalPop(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const {
    reset,
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const values = watch();


  useEffect(() => {
    if (idSelectVehicle !== null) {
      const data = async () => {
        const result = await dispatch(getDataIdVehicle(idSelectVehicle));
        const data = {
          description: result.footer.description,
          title: result.footer.title,
          subtitle: result.footer.subtitle,
          footer_image: result.footer.image_url,
          data_sheet: result.data_sheet,
        };
        reset(data);
      };
      data();
    }
  }, [dispatch, idSelectVehicle, reset]);

  // Obtener datos del modelo
  useEffect(() => {
    dispatch(getTableVehicle());
  }, [dispatch]);

  const handleDropImgFooter = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue("footer_image", file);
      }
    },
    [setValue]
  );

  // subir PDF
  const handleDropPDF = (e) => {
    const file = e.target.files[0];
    if (file) {
      setValue("data_sheet", file);
    }
  };

  return (
    <InitDialog title={title} fullWidth loading={isLoading} size="lg">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ m: 1 }} spacing={2}>
          <Stack direction={{ xs: "column", md: "column" }} spacing={2}>

            <RHFTextField name="title" label="Título footer vehículo" />
            <RHFTextField name="subtitle" label=" Subtitulo footer vehículo" />
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: -1 }}>
              Descripción footer vehículo
            </Typography>
            <RHFEditor name="description" label="Descripción del vehículo" />
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: -1 }}>
              Imagen footer vehículo
            </Typography>

            <div>
              <RHFUploadSingleFile
                disabled={isLoading}
                name="footer_image"
                accept={{
                  "image/*": [".jpeg", ".png", ".jpg", ".svg", ".webp"],
                }}
                maxSize={1024000} // 1MB TODO: Consultar a que tamaño se debe limitar
                onDrop={handleDropImgFooter}
              />
            </div>

            <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: -1 }}>
              Ficha técnica del vehículo
            </Typography>

            <div>
              {values?.data_sheet instanceof File ||
                values?.data_sheet?.file_url ? (
                <Stack flexDirection="row" alignItems="center">
                  <Box
                    component="img"
                    src="/assets/resources/pdf.png"
                    sx={{
                      width: 50,
                      height: 50,
                    }}
                  />
                  <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                    {values?.data_sheet instanceof File ? (
                      values?.data_sheet?.name
                    ) : (
                      <Typography
                        variant="subtitle2"
                        component="a"
                        sx={{
                          fontWeight: "bold",
                          ml: 1,
                          color: "text.primary",
                        }}
                        target="_blank"
                        href={values?.data_sheet?.file_url}
                      >
                        VER PDF
                      </Typography>
                    )}
                  </Typography>

                  <IconButton
                    color="error"
                    size="large"
                    onClick={() => {
                      setValue("data_sheet", "");
                    }}
                  >
                    <HighlightOffOutlined />
                  </IconButton>
                </Stack>
              ) : (
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<Upload />}
                  onChange={handleDropPDF}
                  disabled={isLoading}
                >
                  Subir PDF
                  <input
                    hidden
                    accept="
                    application/pdf,
                  "
                    type="file"
                  />
                </Button>
              )}
            </div>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", mx: 1, mt: 2, gap: 2 }}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ minWidth: 100 }}
            onClick={() => {
              dispatch(openModal(false));
            }}
          >
            Cancelar
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            disabled={isLoading || isSubmitting}
          >
            Guardar
          </Button>
        </Stack>
      </FormProvider>
    </InitDialog>
  );
};

export default FormFichaTecnicaVehicle;
