import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  company: [],
  social_netkork_types: [],
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Obtener data de la compañia
    getDataIdCompanySuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.company = action.payload;
      state.message = "Obtuvimos data de la compañia 🙌";
    },

    //Actualizar compañia
    updateCompanySuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    // Table = datos necesarios para el formulario compañia
    getTableCompanySuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.social_netkork_types = action.payload.social_netkork_types;
    },
  },
});

// Reducer - Aca va la data o los valores
export default companySlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  getDataIdCompanySuccess,
  updateCompanySuccess,
  getTableCompanySuccess,
} = companySlice.actions;

// ========Funciones===========

// Obtener dato del modelo por ID
// -------------------------------------------------------
export function getDataIdCompany() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`/companies/record/1`);
      dispatch(getDataIdCompanySuccess(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error;
    }
  };
}

// Actualizar company
// -------------------------------------------------------
export function updateCompany(data) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.put(`/companies/1`, data);
      dispatch(updateCompanySuccess(response.data.message));
      dispatch(getDataIdCompany()); //llamamos a la funcion para actualizar los datos de la compañia
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Table - Datos necesarios para la tabla
// -------------------------------------------------------
export function getTableCompany() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get("/companies/table");
      dispatch(getTableCompanySuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error.response.data.message));
    }
  };
}
