import { CssBaseline, ThemeProvider } from "@mui/material";
import Navigation from "./routes/Navigation";
import { useSelector } from "react-redux";
import NotistackProvider from "./components/NotistackProvider";
import { lightTheme, darkTheme } from "./themes";

function App() {
  const { themeApp } = useSelector((state) => state.config);
  const themeSystem = themeApp === "dark" ? darkTheme : lightTheme;
  // console.warn(themeSystem);

  return (
    <ThemeProvider theme={themeSystem}>
      <NotistackProvider>
        <CssBaseline />
        <Navigation />
      </NotistackProvider>
    </ThemeProvider>
  );
}

export default App;
