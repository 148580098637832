import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  trademark: [],
  trademarks: [],
  social_netkork_types: [],
};

const trademarkSlice = createSlice({
  name: "trademark",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Lista de marcas
    dataTrademarksSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.trademarks = action.payload;
      state.message = "Obtuvimos todos los marcas 🙌";
    },

    // Obtener data del marca por ID
    getDataIdTrademarkSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.trademark = action.payload;
      state.message = "Obtuvimos data del marca 🙌";
    },

    //Crear marca
    createTrademarkSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Actualizar marca
    updateTrademarkSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Eliminar marca
    deleteTrademarkSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    // Obtener data para la tabla
    getTableTrademarkSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.social_netkork_types = action.payload.social_netkork_types;
      state.message = "Obtuvimos todos los tables 🙌";
    },
  },
});

// Reducer - Aca va la data o los valores
export default trademarkSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  dataTrademarksSuccess,
  getDataIdTrademarkSuccess,
  createTrademarkSuccess,
  updateTrademarkSuccess,
  deleteTrademarkSuccess,
  getTableTrademarkSuccess,
} = trademarkSlice.actions;

// ========Funciones===========

// Data de las marcas
// -------------------------------------------------------
export function dataTrademark() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`trademarks/records`);
      dispatch(dataTrademarksSuccess(response?.data?.data));
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}

// Obtener dato de marca por ID
// -------------------------------------------------------
export function getDataIdTrademark(id) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`/trademarks/record/${id}`);
      dispatch(getDataIdTrademarkSuccess(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error;
    }
  };
}

// Crear marca
// ------------------------------------------------------
// crearemos como formdata para poder subir archivos
export function createTrademark(data) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.post("/trademarks", data);
      dispatch(createTrademarkSuccess(response.data.message));
      dispatch(dataTrademark()); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response;
    }
  };
}

// Actualizar marca
// -------------------------------------------------------
export function updateTrademark(id, data) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.put(`/trademarks/${id}`, data);
      dispatch(updateTrademarkSuccess(response.data.message));
      dispatch(dataTrademark()); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response;
    }
  };
}

// Eliminar  marca
// -------------------------------------------------------
export function deleteTrademark(id) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.delete(`/trademarks/${id}`);
      dispatch(deleteTrademarkSuccess(response.data.message));
      dispatch(dataTrademark()); // actualizamos la lista de marcas
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Table - Datos necesarios para la tabla
// -------------------------------------------------------
export function getTableTrademark() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get("/trademarks/table");
      dispatch(getTableTrademarkSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error.response.data.message));
    }
  };
}