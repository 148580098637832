import { useEffect, useState } from "react";
import {
  Button,
  Container,
  IconButton,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { InfoRounded, SearchOutlined } from "@mui/icons-material";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import CsvDownloadButton from "react-json-to-csv";

import TableGeneral from "../../../components/InitTables/TableGeneral";
import Page from "../../../components/Page";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  dataQuotation,
  exportQuotation,
} from "../../../redux/slices/quotation";
import Label from "../../../components/Label";

const Cotizacion = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [allDataCotizaciones, setAllDataCotizaciones] = useState([]);
  const { quotations, pagination, isLoading } = useSelector(
    (state) => state.quotation
  );
  const { total, currentPage, lastPage } = pagination;

  // Paginacion
  const handleChangePagination = (event, value) => {
    dispatch(dataQuotation({ page: value, search: "" }));
  };

  // Buscador
  const handleChangeSearch = async (event) => {
    event.preventDefault();
    dispatch(
      dataQuotation({
        page: 1,
        search: event.target.value,
      })
    );
  };

  const tableHeaders = [
    { id: "id", label: "N°" },
    { id: "name", label: "Nombres" },
    { id: "quotation_type", label: "Tipo Cotización" },
    { id: "document_number", label: "N de Documento" },
    { id: "email", label: "Correo", align: "left" },
    { id: "phone", label: "Teléfono" },
    {
      id: "client_has_notification",
      label: "Cliente Notificado?",
      align: "center",
    },
    {
      id: "company_has_notification",
      label: "Compañia Notificado?",
      align: "center",
    },
    {
      id: "info",
      label: "-",
      align: "center",
    },
  ];

  const DATA_COTIZACIONES = quotations?.map((item, index) => ({
    id: index + 1,
    name: item.name + " " + item.last_name,
    quotation_type: item.quotation_type,
    document_number: item.document_number,
    email: item.email,
    phone: item.phone,
    client_has_notification: (
      <Label
        variant={theme.palette.mode === "light" ? "ghost" : "filled"}
        color={item.client_has_notification ? "success" : "error"}
      >
        {item.client_has_notification ? "Si" : "No"}
      </Label>
    ),

    company_has_notification: (
      <Label
        variant={theme.palette.mode === "light" ? "ghost" : "filled"}
        color={item.company_has_notification ? "success" : "error"}
      >
        {item.company_has_notification ? "Si" : "No"}
      </Label>
    ),
    info: (
      <Tooltip
        title={
          <Stack>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              Información
            </Typography>
            <Typography variant="caption">
              <strong>Suscriptor::</strong>{" "}
              {item?.is_suscription === true ? "Si" : "No"}
            </Typography>
            <Typography variant="caption">
              <strong>Datos del Vehiculo:</strong>{" "}
              {item?.vehicle_data.map((item, index) => (
                <Stack key={index}>
                  <Typography variant="caption">
                    <strong>Marca:</strong> {item?.trademark}
                  </Typography>{" "}
                  <Typography variant="caption">
                    <strong>Modelo:</strong> {item?.model}
                  </Typography>{" "}
                  <Typography variant="caption">
                    <strong>Sucursal:</strong> {item?.establishment}
                  </Typography>
                </Stack>
              ))}
            </Typography>

            <Typography variant="caption">
              <strong>Decisión de compra::</strong>{" "}
              {item?.purchase_decision === null ? "-" : item?.purchase_decision}
            </Typography>
            <Typography variant="caption">
              <strong>Monto a financiar:</strong>{" "}
              {item?.financed_amount === null ? "-" : item?.financed_amount}
            </Typography>
            <Typography variant="caption">
              <strong>Ingreso Mensual:</strong>{" "}
              {item?.monthly_income === null ? "-" : item?.monthly_income}
            </Typography>
            <Typography variant="caption">
              <strong>Régimen:</strong>{" "}
              {item?.regime === null ? "-" : item?.regime}
            </Typography>
            <Typography variant="caption">
              <strong>Incluye financiamiento?::</strong>{" "}
              {item?.include_financing === true ? "Si" : "No"}
            </Typography>

            <Typography variant="caption">
              <strong>Nombre empresa:</strong>{" "}
              {item?.company_name === null ? "-" : item?.company_name}
            </Typography>
            <Typography variant="caption">
              <strong>Rubro de la empresa:</strong>{" "}
              {item?.company_sector === null ? "-" : item?.company_sector}
            </Typography>

            <Typography variant="caption">
              <strong>Vehículo parte de pago::</strong>{" "}
              {item?.vehicle_part_exchange === null ? "-" : item?.vehicle_part_exchange}
            </Typography>

          </Stack>
        }
      >
        <IconButton>
          <InfoRounded />
        </IconButton>
      </Tooltip>
    ),
  }));

  // Obtener suscriptores
  useEffect(() => {
    dispatch(dataQuotation({ page: 1, search: "" }));
  }, [dispatch]);

  // obtener todos las cotizaciones
  const handleDowloandCsv = async () => {
    const data = await dispatch(exportQuotation());
    setAllDataCotizaciones(data);
  };

  return (
    <Page title="Administración: Cotizaciones">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Lista de cotizaciones"
          links={[{ name: "Inicio", href: "/" }, { name: "Cotizaciones" }]}
          action={
            <>
              {allDataCotizaciones.length < 1 ? (
                <Button
                  variant="contained"
                  onClick={handleDowloandCsv}
                  disabled={isLoading}
                >
                  Generar reporte
                </Button>
              ) : (
                <CsvDownloadButton
                  data={allDataCotizaciones}
                  filename="reporte-cotizaciones.csv"
                  style={{
                    //pass other props, like styles
                    boxShadow: "inset 0px 1px 0px 0px #287233",
                    backgroundColor: "#287233",
                    borderRadius: "6px",
                    border: "1px solid #287233",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "#ffffff",
                    fontSize: "15px",
                    fontWeight: "bold",
                    padding: "6px 24px",
                    textDecoration: "none",
                    textShadow: "0px 1px 0px #287233",
                  }}
                >
                  Descargar reporte
                </CsvDownloadButton>
              )}
            </>
          }
        />
        {/* Tabla */}

        <Stack>
          <Stack direction="row" justifyContent="flex-end" sx={{ p: 1 }}>
            <TextField
              onChange={handleChangeSearch}
              variant="outlined"
              placeholder="Buscar cotización"
              sx={{ m: 1, width: "40ch" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <TableGeneral
            rows={DATA_COTIZACIONES}
            headLabel={tableHeaders}
            isLoading={isLoading}
          />
        </Stack>
        <Stack
          direction="row"
          sx={{ p: 1, textAlign: "center", justifyContent: "flex-end" }}
        >
          <Typography variant="caption" sx={{ marginTop: "8px" }}>
            TOTAL: {total}
          </Typography>

          <Pagination
            count={lastPage}
            page={currentPage}
            onChange={handleChangePagination}
            showFirstButton
            showLastButton
          />
        </Stack>
      </Container>
    </Page>
  );
};

export default Cotizacion;
