import React, { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
import {
  Avatar,
  Button,
  Container,
  Divider,
  IconButton,
  MenuItem,
  useTheme,
} from "@mui/material";
import {
  Add,
  DeleteForever,
  EditOutlined,
  MoreVertOutlined,
} from "@mui/icons-material";
// redux
import { useDispatch, useSelector } from "react-redux";
import { dataUsers, deleteUser } from "../../../../redux/slices/user";
import { openModal } from "../../../../redux/slices/global";

import TableGeneral from "../../../../components/InitTables/TableGeneral";
import { Stack } from "@mui/system";
import MenuPopover from "../../../../components/MenuPopover";
import FormUser from "./components/FormDialog/FormUser";
import { InitDialogAlert } from "../../../../components/InitDialogs";
import { useSnackbar } from "notistack";

const UsersConfig = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isOpenModal } = useSelector((state) => state.global);
  const { users } = useSelector((state) => state.user);
  const [open, setOpen] = useState(null);
  const [operation, setOperation] = useState(1); // 1 = Crear / 2= Editar
  const [idSelectUser, setIdSelectUser] = useState(null);
  const [openDialogDeleteUser, setOpenDialogDeleteUser] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleOpenMenuPop = (event) => {
    setOpen(event.currentTarget);
  };

  useEffect(() => {
    dispatch(dataUsers());
  }, [dispatch]);

  const tableHeaders = [
    { id: "id", label: "N°" },
    { id: "name", label: "Nombre" },
    { id: "email", label: "Email" },
    { id: "actions", label: "Acciones", align: "center" },
  ];

  const DATA_USERS = users?.map((item, index) => ({
    id: index + 1,
    name: (
      <Stack direction="row" alignItems="center">
        <Avatar
          sx={{ width: 24, height: 24, mr: 1 }}
          alt={item.name}
          src="/assets/resources/avatar.png"
        />
        {item.name}
      </Stack>
    ),
    number: item.number,
    email: item.email,
    actions: (
      <>
        <IconButton
          onClick={(e) => {
            handleOpenMenuPop(e);
            setIdSelectUser(item.id);
          }}
        >
          <MoreVertOutlined />
        </IconButton>

        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={() => setOpen(null)}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          arrow="right-top"
          sx={{
            boxShadow: "none",
            borderRadius: 2,
            mt: -1,
            width: 160,
            background: theme.palette.background.paper,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
              "& svg": { mr: 2, width: 20, height: 20 },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              dispatch(openModal(true));
              setOperation(2);
            }}
          >
            <EditOutlined />
            Editar
          </MenuItem>

          <Divider sx={{ borderStyle: "dashed" }} />

          <MenuItem
            onClick={() => {
              setOpenDialogDeleteUser(true);
            }}
            sx={{ color: "error.main" }}
          >
            <DeleteForever />
            Eliminar
          </MenuItem>
        </MenuPopover>
      </>
    ),
  }));

  const handleDeleteUser = async (id) => {
    if (id !== "") {
      const result = await dispatch(deleteUser(id));
      if (result.status === 200 || result.status === 201) {
        enqueueSnackbar(result?.data?.message, { variant: "success" });
        setOpen(null);
        setOpenDialogDeleteUser(false);
      } else {
        enqueueSnackbar(result?.data?.message, { variant: "error" });
      }
    }
  };

  return (
    <Page title="Configuración: Usuarios">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Usuarios"
          links={[
            { name: "Configuración", href: "/configuracion" },
            { name: "Usuarios" },
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Add />}
              sx={{ textTransform: "none" }}
              onClick={() => {
                dispatch(openModal(true));
                setOperation(1);
                setIdSelectUser(null);
              }}
            >
              Nuevo usuario
            </Button>
          }
        />

        {/* Tabla */}
        <TableGeneral rows={DATA_USERS} headLabel={tableHeaders} />
        {/* Tabla */}
      </Container>

      {/* Formulario Usuarios */}
      {isOpenModal ? (
        <FormUser
          title={operation === 1 ? "CREAR USUARIO" : "EDITAR USUARIO"}
          operation={operation}
          idSelectUser={idSelectUser}
        />
      ) : null}

      {/* Alerta eliminar el usuario */}
      <InitDialogAlert
        isOpen={openDialogDeleteUser}
        setOpen={setOpenDialogDeleteUser}
        onOk={() => handleDeleteUser(idSelectUser)}
        title={`¿Desea eliminar a "${users?.find((item) => item.id === idSelectUser)?.name
          }"? `}
        type="error"
      />
    </Page>
  );
};

export default UsersConfig;
