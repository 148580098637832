import React, { useCallback, useEffect } from "react";
import { chunk, flattenDeep } from "lodash";
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
// componentes
import InitDialog from "../../../../../../components/InitDialog";
// formularios
import { useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFSelect2,
  RHFTextField,
  RHFUploadAvatar,
  RHFUploadMultiFile,
} from "../../../../../../components/hook-form";

// redux
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../../redux/slices/global";
import {
  createTrademark,
  getDataIdTrademark,
  getTableTrademark,
  updateTrademark,
} from "../../../../../../redux/slices/trademark";
import { fData } from "../../../../../../utils/formatNumber";
import { AddCircle, Delete } from "@mui/icons-material";
import { OPERATION_TYPE } from "../../../../../../data/dataGeneral";
import {
  arrayIsEmpty,
  objectIsEmpty,
  validateError,
} from "../../../../../../utils/validate";

const FormTrademark = ({ title, operation, idSelectTrademark, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const NUM_MEDIA_TO_SAVE = 2;
  const dispatch = useDispatch();
  const { isLoading, social_netkork_types } = useSelector(
    (state) => state.trademark
  );

  // valores
  const defaultValues = {
    banners_removed: [],
    name: "",
    profile_picture: "",
    url: "",
    slogan: "",
    banners: [],
    responsive_banners: [],
    responsive_banners_removed: [],
    social_networks: [
      {
        type_of_social_network: "",
        path: "",
      },
    ],
  };

  // Validaciones
  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Marca es requerido"),
    profile_picture: Yup.mixed().required("Por favor seleccione una imagen."),
    url: Yup.string().url("Url no es valida").nullable(),
    slogan: Yup.string().required("Marca es requerido"),
  });

  // Obtener los valores del formulario
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    if (operation === OPERATION_TYPE.CREATE) {
      await createProcess(data);
    } else {
      await updateProcess(data);
    }
  };

  const createProcess = async (data) => {
    const redSocialValidate = data.social_networks.filter(
      (item) => item.type_of_social_network !== ""
    );
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("slogan", data.slogan);
    formData.append("profile_picture", data.profile_picture);

    /** MAP Social Networks */
    if (isUrlExtend) formData.append("url", data.url);
    else formData.append("social_networks", JSON.stringify(redSocialValidate));

    /*** BANNER TO CREATE */
    // BANNERS passa de uno en uno al ser un array de archivos, por eso hacemos un for
    let bannerGroup, bannerResponsiveGroup;
    if (!isUrlExtend) {
      bannerGroup = chunk(data.banners, NUM_MEDIA_TO_SAVE);
      (bannerGroup[0] || []).forEach((banner) => {
        formData.append("banners", banner);
      });

      bannerResponsiveGroup = chunk(data.responsive_banners, NUM_MEDIA_TO_SAVE);
      (bannerResponsiveGroup[0] || []).forEach((banner) => {
        formData.append("responsive_banners", banner);
      });

      // Se elimina el primer grupo de 2 imagenes
      bannerGroup.shift();
      bannerResponsiveGroup.shift();
    }

    /*** SEND DATA: Create*/
    const result = await dispatch(createTrademark(formData));
    if (result.status >= 400) {
      errorProcess(result.data.message);
      return;
    }

    // Si se cargo mas de 2 imagenes en banner y/0 banner responsive, las posteriores a la segunda se registrarán como actualización
    if (
      (bannerGroup && !arrayIsEmpty(bannerGroup)) ||
      (bannerResponsiveGroup && !arrayIsEmpty(bannerResponsiveGroup))
    ) {
      data.id = result.data.id;
      const bannerPendingSave = flattenDeep(bannerGroup);
      const bannerResponsivePendingSave = flattenDeep(bannerResponsiveGroup);
      await updateProcess(data, {
        bannerPendingSave,
        bannerResponsivePendingSave,
      });
    }

    successProcess(result);
  };

  const updateProcess = async (data, bannersPendingCreate = {}) => {
    const redSocialValidate = data.social_networks.filter(
      (item) => item.type_of_social_network !== ""
    );

    const formDataUpdate = new FormData();
    formDataUpdate.append("name", data.name);
    formDataUpdate.append("slogan", data.slogan);
    formDataUpdate.append("profile_picture", data.profile_picture);

    /** MAP Social Networks */
    if (isUrlExtend) formDataUpdate.append("url", data.url);
    else
      formDataUpdate.append(
        "social_networks",
        JSON.stringify(redSocialValidate)
      );

    /*** BANNER TO CREATE */
    // BANNERS passa de uno en uno al ser un array de archivos, por eso hacemos un for
    let bannerGroup, bannerResponsiveGroup;
    if (!isUrlExtend) {
      const banners = bannersPendingCreate.bannerPendingSave || data.banners;
      const bannersResponsive =
        bannersPendingCreate.bannerResponsivePendingSave ||
        data.responsive_banners;

      bannerGroup = chunk(banners?.filter((item) => item instanceof File), NUM_MEDIA_TO_SAVE);
      bannerResponsiveGroup = chunk(bannersResponsive.filter((item) => item instanceof File), NUM_MEDIA_TO_SAVE);

      (bannerGroup[0] || []).forEach((banner) => {
        if (banner instanceof File) formDataUpdate.append("banners", banner);
      });
      (bannerResponsiveGroup[0] || []).forEach((banner) => {
        if (banner instanceof File)
          formDataUpdate.append("responsive_banners", banner);
      });
      // Se elimina el primer grupo de 2 imagenes
      bannerGroup.shift();
      bannerResponsiveGroup.shift();
    }

    /*** BANNER TO REMOVE */
    // Debemos colocar el ID de la imagen que removeremos y enviarlo al backend en un array ['id1', 'id2']
    // banners_removed ENVIAMOS como un array
    // si data.banners_removed  contine undefined, entonces enviamos un array vacio
    const bannerToRemove = (data.banners_removed || []).filter(
      (item) => item !== undefined
    );
    formDataUpdate.append("banners_removed", JSON.stringify(bannerToRemove));

    const bannerResponsiveToRemove = (
      data.responsive_banners_removed || []
    ).filter((item) => item !== undefined);

    formDataUpdate.append(
      "responsive_banners_removed",
      JSON.stringify(bannerResponsiveToRemove)
    );

    /*** SEND DATA: Update */
    const idTrademark = data.id || idSelectTrademark;
    const result = await dispatch(updateTrademark(idTrademark, formDataUpdate));

    if (result.status >= 400) {
      if (bannerGroup && bannerResponsiveGroup)
        errorProcess("Error al cargar algunas imagenes");
      else errorProcess(result.data.message);
      return;
    }

    if (
      operation === OPERATION_TYPE.UPDATE &&
      objectIsEmpty(bannersPendingCreate)
    ) {
      successProcess(result);
      return;
    }

    // Si se cargo mas de 2 imagenes en banner y/0 banner responsive, las posteriores a la segunda se registrarán como actualización
    if (
      (bannerGroup && !arrayIsEmpty(bannerGroup)) ||
      (bannerResponsiveGroup && !arrayIsEmpty(bannerResponsiveGroup))
    ) {
      const bannerPendingSave = flattenDeep(bannerGroup);
      const bannerResponsivePendingSave = flattenDeep(bannerResponsiveGroup);
      await updateProcess(data, {
        bannerPendingSave,
        bannerResponsivePendingSave,
      });
    }
  };

  const {
    fields: socialFields,
    append: socialAppend,
    remove: socialRemove,
  } = useFieldArray({ control, name: "social_networks" });

  // Autocompletar los campos solo para editar Operation = 2
  useEffect(() => {
    if (operation === OPERATION_TYPE.UPDATE && idSelectTrademark !== null) {
      const data = async () => {
        const result = await dispatch(getDataIdTrademark(idSelectTrademark));
        const data = {
          name: result.name,
          slogan: result.slogan,
          profile_picture: result.profile_picture_url,
          url: result.url,
          banners: result.banners_url,
          responsive_banners: result.responsive_banners_url,
          social_networks:
            result?.social_networks?.length === 0
              ? [
                {
                  type_of_social_network: "",
                  path: "",
                },
              ]
              : result.social_networks,
        };
        reset(data);
      };
      data();
    }
  }, [dispatch, idSelectTrademark, operation, reset]);

  const values = watch();
  let isUrlExtend = Boolean(values.url); // sirve para validar si el campo url esta vacio o no

  const handleDropIcon = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          "profile_picture",
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const images = values.banners || [];

      setValue("banners", [
        ...images,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    [setValue, values.banners]
  );

  const handleDropResponsive = useCallback(
    (acceptedFiles) => {
      const images = values.responsive_banners || [];

      setValue("responsive_banners", [
        ...images,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    [setValue, values.responsive_banners]
  );

  const handleRemoveAll = () => {
    operation === OPERATION_TYPE.CREATE && setValue("banners", []);
    // cuando es editar y se quiere remover todas las imagenes añadumos el uuid de la imagen que queremos remover y seteamos en valioor banners_removed en un array
    if (operation === OPERATION_TYPE.UPDATE) {
      setValue("banners_removed", [
        ...values.banners.map((banner) => banner.id),
      ]);
      setValue("banners", []);
    }
  };

  const handleRemoveAllResponsive = () => {
    operation === OPERATION_TYPE.CREATE && setValue("responsive_banners", []);
    // cuando es editar y se quiere remover todas las imagenes añadumos el uuid de la imagen que queremos remover y seteamos en valioor banners_removed en un array
    if (operation === OPERATION_TYPE.UPDATE) {
      setValue("responsive_banners_removed", [
        ...values.responsive_banners.map((banner) => banner.id),
      ]);
      setValue("responsive_banners", []);
    }
  };

  const handleRemove = (file) => {
    // cuando CREAMOS un nuevo registro
    if (operation === OPERATION_TYPE.CREATE) {
      const filteredItems = values.banners?.filter((_file) => _file !== file);
      setValue("banners", filteredItems);
    }

    // cuando ACTUALIZAMOS un  registro debemos enviar el ID de la imagen que queremos remover a banners_removed
    if (operation === OPERATION_TYPE.UPDATE) {
      // Tenemos que almacenar el valor actual de banners en una variable para poder remover el archivo que queremos eliminar
      const banners = values.banners;
      // Tenemos que almacenar el valor actual de banners_removed en una variable para poder remover el archivo que queremos eliminar
      const banners_removed = values.banners_removed || [];
      // Tenemos que remover el archivo que queremos eliminar
      const filteredItems = banners?.filter(
        (_file) => _file.path !== file.path
      );
      // Tenemos que setear el valor de banners con el archivo que queremos eliminar de la vista previa
      setValue("banners", filteredItems);
      // Tenemos que setear el valor de banners_removed con el ID del archivo que queremos eliminar
      setValue("banners_removed", [...banners_removed, file.id]);
    }
  };

  const successProcess = (result) => {
    dispatch(openModal(!true));
    enqueueSnackbar(result.data.message, { variant: "success" });
    setOpen(null);
  };

  const errorProcess = (messageData) => {
    const message = validateError(messageData);
    enqueueSnackbar(message, { variant: "error" });
  };

  const handleRemoveResponsive = (file) => {
    // cuando CREAMOS un nuevo registro
    if (operation === OPERATION_TYPE.CREATE) {
      const filteredItems = values.responsive_banners?.filter(
        (_file) => _file !== file
      );
      setValue("responsive_banners", filteredItems);
    }

    // cuando ACTUALIZAMOS un  registro debemos enviar el ID de la imagen que queremos remover a banners_removed
    if (operation === OPERATION_TYPE.UPDATE) {
      // Tenemos que almacenar el valor actual de banners en una variable para poder remover el archivo que queremos eliminar
      const banners = values.responsive_banners;
      // Tenemos que almacenar el valor actual de banners_removed en una variable para poder remover el archivo que queremos eliminar
      const banners_removed = values.responsive_banners_removed || [];
      // Tenemos que remover el archivo que queremos eliminar
      const filteredItems = banners?.filter(
        (_file) => _file.path !== file.path
      );
      // Tenemos que setear el valor de banners con el archivo que queremos eliminar de la vista previa
      setValue("responsive_banners", filteredItems);
      // Tenemos que setear el valor de banners_removed con el ID del archivo que queremos eliminar
      setValue("responsive_banners_removed", [...banners_removed, file.id]);
    }
  };

  useEffect(() => {
    dispatch(getTableTrademark());
  }, [dispatch]);

  return (
    <InitDialog title={title} size="md" fullWidth loading={isLoading}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ m: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <RHFUploadAvatar
                name="profile_picture"
                sx={{ borderRadius: 1, width: 180, height: 180 }}
                isSquare
                accept={{
                  "image/*": [".jpeg", ".png", ".jpg", ".svg", ".webp"],
                }}
                maxSize={10000} // 10kb
                onDrop={handleDropIcon}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: "auto",
                      display: "block",
                      textAlign: "center",
                      color: "text.secondary",
                    }}
                  >
                    Permitido *.jpeg, *.jpg, *.png, *.gif
                    <br /> 200x200, Máximo {fData(10000)}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Stack direction={{ xs: "column", md: "column" }} spacing={2}>
                <RHFTextField name="name" label="Marca" autoComplete="off" />
                <RHFTextField name="slogan" label="Slogan de la marca" autoComplete="off" />

                <RHFTextField
                  name="url"
                  label="URL de la marca (EXTERNA)"
                  autoComplete="off"
                  helperText="Cuando la marca tiene una página externa (Ej: https://incamotors.com.pe)"
                />
              </Stack>
            </Grid>

            {!isUrlExtend && (
              <>
                <Grid item xs={12} md={12}>
                  <Stack
                    direction={{ xs: "column", md: "column" }}
                    sx={{ alignItems: "flex-start" }}
                    spacing={2}
                  >
                    {socialFields.map((item, index) => (
                      <Stack
                        key={item.id}
                        flexDirection="row"
                        sx={{
                          width: "100%",
                          alignItems: "center",
                        }}
                        gap={1}
                      >
                        <RHFSelect2
                          name={`social_networks.${index}.type_of_social_network`}
                          label="Red social"
                          defaultValue=""
                        >
                          <MenuItem value="">
                            <em>Seleccione red social</em>
                          </MenuItem>
                          {social_netkork_types?.map((item, i) => (
                            <MenuItem key={i} value={item.id}>
                              {item.description}
                            </MenuItem>
                          ))}
                        </RHFSelect2>
                        <RHFTextField
                          name={`social_networks.${index}.path`}
                          label="Redes sociales"
                        />

                        {index === 0 ? (
                          <IconButton
                            color="primary"
                            onClick={() => {
                              socialAppend({
                                type_of_social_network: "",
                                path: "",
                              });
                            }}
                          >
                            <AddCircle />
                          </IconButton>
                        ) : (
                          <IconButton
                            color="primary"
                            onClick={() => socialRemove(index)}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </Stack>
                    ))}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">
                    Banners de la Marca
                  </Typography>
                  <Typography variant="caption">
                    Tamaño recomendando WxH: 1600 x 600, Máximo
                    {fData(1024000)}
                  </Typography>
                  <RHFUploadMultiFile
                    showPreview
                    name="banners"
                    accept={{
                      "image/*": [".jpeg", ".png", ".jpg", ".svg", ".webp"],
                    }}
                    // maxFiles={2} // maximo seleccionar 3 archivos
                    maxSize={1024000}
                    onDrop={handleDrop}
                    onRemove={handleRemove}
                    onRemoveAll={handleRemoveAll}
                    onUpload={() => console.log("ON UPLOAD")}
                    isUpload={false}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">
                    Banners de la Marca Responsivo
                  </Typography>
                  <Typography variant="caption">
                    Tamaño recomendando WxH: 440 x 330, Máximo
                    {fData(1024000)}
                  </Typography>
                  <RHFUploadMultiFile
                    showPreview
                    name="responsive_banners"
                    accept={{
                      "image/*": [".jpeg", ".png", ".jpg", ".svg", ".webp"],
                    }}
                    // maxFiles={2} // maximo seleccionar 3 archivos
                    maxSize={1024000}
                    onDrop={handleDropResponsive}
                    onRemove={handleRemoveResponsive}
                    onRemoveAll={handleRemoveAllResponsive}
                    onUpload={() => console.log("ON UPLOAD")}
                    isUpload={false}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Stack>

        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", mx: 1, mt: 2 }}
          spacing={2}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ minWidth: 100 }}
            onClick={() => {
              dispatch(openModal(false));
            }}
          >
            Cancelar
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            disabled={(isSubmitting, isLoading)}
          >
            Guardar
          </Button>
        </Stack>
      </FormProvider>
    </InitDialog>
  );
};

export default FormTrademark;