import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  subscription: [],
  subscriptions: [],
  subscriptionsAll: [],
  pagination: {
    total: 0,
    currentPage: 0,
    pageSize: 0,
    lastPage: 0,
  },
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    dataSubscriptionsSuccess(state, action) {
      const pagination = action.payload.meta;
      state.isError = false;
      state.isLoading = false;
      state.message = "Obtuvimos todos los suscriptores 🙌";
      state.subscriptions = action.payload.data;
      // Paginacion
      state.pagination.currentPage = pagination.current_page;
      state.pagination.pageSize = pagination.per_page * 1;
      state.pagination.total = pagination.total;
      state.pagination.lastPage = pagination.last_page;
    },
    exportSubscriptionsSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.message = "Exportamos todos los suscriptores 🙌";
      state.subscriptionsAll = action.payload.data;
    },
  },
});

// Reducer - Aca va la data o los valores
export default subscriptionSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  dataSubscriptionsSuccess,
  exportSubscriptionsSuccess,
} = subscriptionSlice.actions;

// ========Funciones===========
// -------------------------------------------------------

// Data de las categorias
// -------------------------------------------------------
export function dataSubscriptions(query) {
  const { is_paginate = true, page, search } = query;
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(
        `subscriptions/records?is_paginate=${is_paginate}&per_page=15&page=${page}&search=${search}`
      );
      dispatch(dataSubscriptionsSuccess(response?.data));
      return response?.data;
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}

// Para exportar suscriptores
// -------------------------------------------------------
export function exportSubscriptions() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`subscriptions/records`);
      dispatch(exportSubscriptionsSuccess(response?.data));
      return response?.data?.data;
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}
