import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  quotations: [],
  pagination: {
    total: 0,
    currentPage: 0,
    pageSize: 0,
    lastPage: 0,
  },
};

const quotationSlice = createSlice({
  name: "quotation",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Obtener data variante del vehiculo
    dataQuotationSuccess(state, action) {
      const pagination = action.payload.meta;

      state.isError = false;
      state.isLoading = false;
      state.quotations = action.payload.data;
      state.message = "Obtuvimos data de las quotation";

      // Paginacion
      state.pagination.currentPage = pagination.current_page;
      state.pagination.pageSize = pagination.per_page * 1;
      state.pagination.total = pagination.total;
      state.pagination.lastPage = pagination.last_page;
    },

    exportQuotationSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.message = "Exportamos todos las cotizaciones  🙌";
      state.subscriptionsAll = action.payload.data;
    },
  },
});

// Reducer - Aca va la data o los valores
export default quotationSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  dataQuotationSuccess,
  exportQuotationSuccess,
} = quotationSlice.actions;

// ========Funciones===========

// Obtener variante del vehiculo por ID
// -------------------------------------------------------
export function dataQuotation(query) {
  const { page = 1, per_page = 10, search = "" } = query;
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(
        `quotations/records?is_paginate=true&per_page=${per_page}&page=${page}&search=${search}`
      );
      dispatch(dataQuotationSuccess(response?.data));
      return response.data.data;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error;
    }
  };
}

// Para exportar todas las quotation
// -------------------------------------------------------
export function exportQuotation() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`quotations/records`);
      dispatch(exportQuotationSuccess(response?.data));
      return response?.data?.data;
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}
