import React, { useEffect, useState } from "react";

import {
  Avatar,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Stack,
} from "@mui/material";
import {
  Add,
  DeleteForever,
  EditOutlined,
  MoreVertOutlined,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { InitDialogAlert } from "../../../../../components/InitDialogs";
import { TableGeneral } from "../../../../../components/InitTables";
import MenuPopover from "../../../../../components/MenuPopover";
import FormTrademark from "./FormDialog/FormTrademark";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  dataTrademark,
  deleteTrademark,
} from "../../../../../redux/slices/trademark";
import { openModal } from "../../../../../redux/slices/global";

const Trademark = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isOpenModal } = useSelector((state) => state.global);
  const { trademarks, isLoading } = useSelector((state) => state.trademark);

  const [open, setOpen] = useState(null);
  const [isFormTrademark, setIsFormTrademark] = useState(false);
  const [operation, setOperation] = useState(1); // 1 = Crear / 2= Editar
  const [openDialogDeleteTrademark, setOpenDialogTrademark] = useState(false);
  const [idSelectTrademark, setIdSelectTrademark] = useState(null);

  const handleOpenMenuPop = (event) => {
    setOpen(event.currentTarget);
  };

  const handleDeleteTrademark = async (id) => {
    if (id !== "") {
      const result = await dispatch(deleteTrademark(id));
      if (result.status === 200) {
        setOpenDialogTrademark(false);
        enqueueSnackbar(result.data.message, { variant: "success" });
        setOpen(null);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const tableHeaders = [
    { id: "id", label: "N°" },
    { id: "name", label: "MARCA", align: "left" },
    { id: "url", label: "URL", align: "left" },
    { id: "actions", label: "ACCIONES", align: "right" },
  ];

  const DATA_TRADEMARK = trademarks?.map((item, index) => ({
    id: index + 1,
    name: (
      <Stack direction="row" alignItems="center">
        <Avatar
          variant="square"
          sx={{ mr: 1 }}
          alt={item.name}
          src={item.profile_picture_url}
        />
        {item.name}
      </Stack>
    ),
    url: !item.url ? "-" : item.url,
    actions: (
      <>
        <IconButton
          onClick={(e) => {
            handleOpenMenuPop(e);
            setIdSelectTrademark(item.id);
          }}
        >
          <MoreVertOutlined />
        </IconButton>

        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={() => setOpen(null)}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          arrow="right-top"
          sx={{
            boxShadow: "none",
            borderRadius: 2,
            mt: -1,
            width: 160,
            background: (theme) => theme.palette.background.paper,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
              "& svg": { mr: 2, width: 20, height: 20 },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              dispatch(openModal(true));
              setIsFormTrademark(true);
              setOperation(2);
            }}
          >
            <EditOutlined />
            Editar
          </MenuItem>

          <Divider sx={{ borderStyle: "dashed" }} />

          <MenuItem
            onClick={() => {
              setOpenDialogTrademark(true);
            }}
            sx={{ color: "error.main" }}
          >
            <DeleteForever />
            Eliminar
          </MenuItem>
        </MenuPopover>
      </>
    ),
  }));

  useEffect(() => {
    dispatch(dataTrademark());
  }, [dispatch]);

  return (
    <>
      <Stack alignItems="end" sx={{ mt: -2, mb: 1 }}>
        <Button
          variant="contained"
          startIcon={<Add />}
          sx={{ textTransform: "none" }}
          onClick={() => {
            dispatch(openModal(true));
            setIsFormTrademark(true);
            setOperation(1);
            setIdSelectTrademark(null);
          }}
        >
          Nueva marca
        </Button>
      </Stack>

      <TableGeneral
        rows={DATA_TRADEMARK}
        headLabel={tableHeaders}
        isLoading={isLoading}
      />

      {isOpenModal && isFormTrademark ? (
        <FormTrademark
          title={operation === 1 ? "NUEVA MARCA" : "EDITAR MARCA"}
          operation={operation}
          idSelectTrademark={idSelectTrademark}
          setOpen={setOpen}
        />
      ) : null}

      {/* Alerta eliminar la MARCA */}
      <InitDialogAlert
        isLoading={isLoading}
        isOpen={openDialogDeleteTrademark}
        setOpen={setOpenDialogTrademark}
        onOk={() => handleDeleteTrademark(idSelectTrademark)}
        title={`¿Desea eliminar "${
          trademarks?.find((item) => item.id === idSelectTrademark)?.name
        }"? `}
        type="error"
      />
    </>
  );
};

export default Trademark;
