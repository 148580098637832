import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  themeApp: 'light', // 'dark',
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // seteamos tema de la app
    setThemeSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.themeApp = action.payload;
    },
  },
});

// Reducer - Aca va la data o los valores
export default configSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const { newRequest, hasError, setThemeSuccess } = configSlice.actions;

// ========Funciones===========

// Cambiar el tema de la aplicacion
export function setThemeApp(theme) {
  return async (dispatch) => {
    dispatch(setThemeSuccess(theme));
  };
}
