import React, { useCallback, useEffect, useState } from "react";
import { chunk, flattenDeep } from "lodash";
import InitDialog from "../../../../../components/InitDialog";
// Formularios
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFSelect2,
  RHFSwitch,
  RHFTextField,
  RHFUploadMultiFile,
} from "../../../../../components/hook-form";
import {
  createVehicle,
  getDataIdVehicle,
  getTableVehicle,
  updateVehicle,
} from "../../../../../redux/slices/vehicle";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { openModal } from "../../../../../redux/slices/global";
import {
  Button,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { OPERATION_TYPE, errorVehicle } from "../../../../../data/dataGeneral";
import { validateError } from "../../../../../utils/validate";

const FormVehicle = ({
  title,
  operation,
  idSelectVehicle,
  setOpenModalPop,
  valueSearch
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading, models, pagination } = useSelector(
    (state) => state.vehicle
  );
  const [modelID, setModelID] = useState('')
  const { currentPage } = pagination;

  // valores
  const defaultValues = {
    name: "",
    description: "",
    price_usd: "",
    price_pen: "",
    is_publish: false,
    is_offer: false,
    percentage_offer: 0,
    model_id: "",
    gallery: [],
    gallery_removed: [],
  };

  // Validaciones
  const FormSchema = Yup.object().shape({
    // maximo 100 caracteres en name
    name: Yup.string()
      .max(100, "Máximo 100 caracteres")
      .required("Nombre es requerido"),
    model_id: Yup.string().required("Modelo es requerido"),
    price_usd: Yup.number()
      .typeError("Debe ser un número")
      .required("Precio es requerido"),
    price_pen: Yup.number()
      .typeError("Debe ser un número")
      .required("Precio es requerido"),

    description: Yup.string().required("Descripción es requerido"),
  });

  // Obtener los valores del formulario
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    if (operation === OPERATION_TYPE.CREATE) {
      await createProcess(data);
    } else {
      // ACTUALIZAR
      await updateProcess(data);
    }
  };

  const createProcess = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("price_usd", data.price_usd);
    formData.append("price_pen", data.price_pen);
    formData.append("is_publish", data.is_publish);
    formData.append("is_offer", data.is_offer);
    formData.append("percentage_offer", data.percentage_offer);
    formData.append("model_id", data.model_id);

    /*** GALERY TO CREATE */
    const _galeryGroupBy3 = chunk(data.gallery, 3);

    (_galeryGroupBy3[0] || []).forEach((img) => {
      formData.append("gallery", img);
    });

    /*** SEND DATA: Create*/
    const result = await dispatch(
      createVehicle(formData, {
        page: currentPage,
        search: "",
      })
    );

    if (result.status >= 400) {
      errorProcess(result.data.message);
      return;
    }

    // Se elimina el primer grupo de 3 imagenes
    _galeryGroupBy3.shift();

    // Si se cargo mas de 3 imagenes las posteriores a la tercera se registrarán como actualización
    if (_galeryGroupBy3.length > 0) {
      data.id = result.data.id;
      const galeriesPendingSave = flattenDeep(_galeryGroupBy3);
      await updateProcess(data, galeriesPendingSave);
    }

    // Si se cargo menor o solo 3 imagenes
    successProcess(result);
  };

  // Actualizar vehiculo
  const updateProcess = async (data, galeriesPendingCreate) => {
    const formDataUpdate = new FormData();
    formDataUpdate.append("name", data.name);
    formDataUpdate.append("description", data.description);
    formDataUpdate.append("price_usd", data.price_usd);
    formDataUpdate.append("price_pen", data.price_pen);
    formDataUpdate.append("is_offer", data.is_offer);
    formDataUpdate.append("percentage_offer", data.percentage_offer);
    formDataUpdate.append("model_id", data.model_id);
    // Galeria tiene que enviarse de 1 en 1 y validamos que enviamos solo los que son FILE

    /*** GALERIES TO CREATE */
    const galeries = galeriesPendingCreate || data.gallery;
    const _galeryGroupBy3 = chunk(galeries.filter((item) => item instanceof File), 3);

    (_galeryGroupBy3[0] || []).forEach((img) => {
      if (img instanceof File) {
        formDataUpdate.append("gallery", img);
      }
    });

    /*** GALERIES TO REMOVE */
    const galeryToRemove = (data.gallery_removed || []).filter(
      (item) => item !== undefined
    );

    formDataUpdate.append(
      "gallery_removed",
      JSON.stringify(galeryToRemove || [])
    );

    /*** SEND DATA: Update */
    const idVehicle = data.id || idSelectVehicle;
    const result = await dispatch(
      updateVehicle(idVehicle, formDataUpdate, {
        page: currentPage,
        search: valueSearch,
      })
    );

    if (result.status >= 400) {
      if (galeriesPendingCreate)
        errorProcess("Error al cargar algunas imagenes");
      else errorProcess(result.data.message);
      return;
    }

    // Se elimina el primer grupo de 3 imagenes
    _galeryGroupBy3.shift();

    if (_galeryGroupBy3.length === 0) {
      if (operation === OPERATION_TYPE.UPDATE) successProcess(result);
    }
    // Si se cargo mas de 3 imagenes las posteriores a la tercera se registrarán como actualización
    if (_galeryGroupBy3.length > 0) {
      const galeriesPendingSave = flattenDeep(_galeryGroupBy3);
      await updateProcess(data, galeriesPendingSave);
    }
  };

  const successProcess = (result) => {
    dispatch(openModal(!true));
    setOpenModalPop(null);
    enqueueSnackbar(result.data.message, { variant: "success" });
  };

  const errorProcess = (messageData) => {
    const message = validateError(messageData, errorVehicle);
    enqueueSnackbar(message, { variant: "error" });
  };

  const {
    reset,
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const values = watch();


  const handleDropGallery = useCallback(
    (acceptedFiles) => {
      const images = values.gallery || [];

      setValue("gallery", [
        ...images,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    [setValue, values.gallery]
  );

  const handleRemoveAllGallery = () => {
    operation === 1 && setValue("gallery", []);
    // cuando es editar y se quiere remover todas las imagenes añadumos el uuid de la imagen que queremos remover y seteamos en valioor gallery_removed en un array
    if (operation === 2) {
      setValue("gallery_removed", [
        ...values.gallery.map((gallery) => gallery.id),
      ]);
      setValue("gallery", []);
    }
  };

  const handleRemoveGallery = (file) => {
    // cuando CREAMOS un nuevo registro
    if (operation === 1) {
      const filteredItems = values.gallery?.filter((_file) => _file !== file);
      setValue("gallery", filteredItems);
    }

    // cuando ACTUALIZAMOS un  registro debemos enviar el ID de la imagen que queremos remover a gallery_removed
    if (operation === 2) {
      // Tenemos que almacenar el valor actual de gallery en una variable para poder remover el archivo que queremos eliminar
      const gallery = values.gallery;
      // Tenemos que almacenar el valor actual de gallery_removed en una variable para poder remover el archivo que queremos eliminar
      const gallery_removed = values.gallery_removed || [];
      // Tenemos que remover el archivo que queremos eliminar
      const filteredItems = gallery?.filter(
        (_file) => _file.image_url !== file.image_url
      );
      // Tenemos que setear el valor de gallery con el archivo que queremos eliminar de la vista previa
      setValue("gallery", filteredItems);
      // Tenemos que setear el valor de gallery_removed con el ID del archivo que queremos eliminar
      setValue("gallery_removed", [...gallery_removed, file.id]);
    }
  };

  // Autocompletar los campos solo para editar Operation = 2
  useEffect(() => {
    if (operation === 2 && idSelectVehicle !== null) {
      const data = async () => {
        const result = await dispatch(getDataIdVehicle(idSelectVehicle));
        const data = {
          name: result.name,
          description: result.description,
          model_id: result.model_id,
          gallery: result.gallery,
          price_usd: result.price_usd,
          price_pen: result.price_pen,
          is_offer: result.is_offer,
          percentage_offer: result.percentage_offer,
        };
        reset(data);
        setModelID(result.model_id);
      };
      data();
    }
  }, [dispatch, idSelectVehicle, operation, reset]);

  // Obtener datos del modelo
  useEffect(() => {
    if (operation === 1 && modelID === '') {
      dispatch(getTableVehicle(modelID));
    }

    if (operation === 2 && modelID !== '') {
      dispatch(getTableVehicle(
        modelID,
      ));
    }
  }, [dispatch, modelID, operation]);

  return (
    <InitDialog title={title} fullWidth loading={isLoading} size="md">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ m: 1 }} spacing={2}>
          <Stack
            direction={{ xs: "column", md: "column" }}
            sx={{ alignItems: "flex-start" }}
            spacing={2}
          >
            <RHFSelect2 name="model_id" label="Modelo">
              <MenuItem value="">Seleccione modelo</MenuItem>
              {models.map((model) => (
                <MenuItem key={model.id} value={model.id}>
                  {model.description}
                </MenuItem>
              ))}
            </RHFSelect2>

            <RHFTextField
              name="name"
              label="Titulo descripción del vehículo"
              autoComplete="off"
            />
            <RHFTextField
              name="description"
              label="Descripción del vehículo"
              autoComplete="off"
              multiline
              maxRows={2}
            />
          </Stack>

          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <RHFTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$.</InputAdornment>
                ),
              }}
              name="price_usd"
              label="Precio en dólares"
              autoComplete="off"
            />
            <RHFTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">S/.</InputAdornment>
                ),
              }}
              name="price_pen"
              label="Precio en soles"
              autoComplete="off"
            />
          </Stack>

          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <RHFSwitch
              name="is_offer"
              label="¿Vehículo en oferta?"
              sx={{ width: "100%" }}
            />

            {values.is_offer && (
              <RHFTextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                name="percentage_offer"
                label="Porcentaje de oferta"
                autoComplete="off"
              />
            )}
          </Stack>

          {/* <RHFEditor name="description" label="Descripción del vehículo" /> */}
          <Stack
            direction={{ xs: "column", md: "column" }}
            sx={{ alignItems: "flex-start" }}
            spacing={2}
          >
            <Typography variant="subtitle1">
              Galería de fotos del vehículo
              <Typography variant="caption" sx={{ ml: 1 }}>
                Tamaño recomendando 500 x 700, MAX: 1Mb
              </Typography>
            </Typography>

            <RHFUploadMultiFile
              showPreview
              name="gallery"
              accept={{
                "image/*": [".jpeg", ".png", ".jpg", ".svg", ".webp"],
              }}
              // maxFiles={3} // maximo seleccionar 3 archivos
              maxSize={1024000} // maximo 1MB es 1024000
              onDrop={handleDropGallery}
              onRemove={handleRemoveGallery}
              onRemoveAll={handleRemoveAllGallery}
              // onUpload={() => console.log("ON UPLOAD")}
              isUpload={false}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", mx: 1, mt: 2, gap: 2 }}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ minWidth: 100 }}
            onClick={() => {
              dispatch(openModal(false));
              setModelID("");
            }}
          >
            Cancelar
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            disabled={isLoading || isSubmitting}
          >
            Guardar
          </Button>
        </Stack>
      </FormProvider>
    </InitDialog>
  );
};

export default FormVehicle;