import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Container,
  IconButton,
  Pagination,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";

import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";

import { useSnackbar } from "notistack";
import TableGeneral from "../../../components/InitTables/TableGeneral";
import Page from "../../../components/Page";
// Redux
import { useDispatch, useSelector } from "react-redux";
import FormBanner from "./components/FormDialog/FormBanner";
import { InitDialogAlert } from "../../../components/InitDialogs";
import {
  dataSlayer,
  deleteSlayer,
  updateSlayer,
} from "../../../redux/slices/slayer";
import { openModal } from "../../../redux/slices/global";
import LightboxModal from "../../../components/LightboxModal";
import { DeleteForeverRounded, Edit } from "@mui/icons-material";
import Label from "../../../components/Label";

const BannerHome = () => {
  const dispatch = useDispatch();
  const [openLightbox, setOpenLightbox] = useState(false);
  const [openLightboxResponsive, setOpenLightboxResponsive] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedImageResponsive, setSelectedImageResponsive] = useState(0)
  const [isFormCategoy, setIsFormCategoy] = useState(false);
  const [operation, setOperation] = useState(1); // 1 = Crear / 2= Editar
  const [idSelectBanner, setIdSelectBanner] = useState(null);
  const [openDialogDeleteBanner, setOpenDialogDeleteBanner] = useState(false);
  const { isOpenModal } = useSelector((state) => state.global);
  const { slayers, isLoading, pagination } = useSelector(
    (state) => state.slayer
  );
  const { total, currentPage, lastPage } = pagination;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const tableHeaders = [
    { id: "id", label: "N°" },
    { id: "preview", label: "IMG DESKTOP" },
    { id: "path_responsive", label: "IMG MOBILE" },
    { id: "url", label: "URL" },
    { id: "is_publish", label: "ESTADO" },
    { id: "actions", label: "ACCIONES" },
  ];

  // Ver imagenes en lightbox
  const imagesLightbox = slayers.map((img) => img.path);
  const imagesLightboxResponsive = slayers.map((img) => img.path_responsive);

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  const handleOpenLightboxResponsive = (url) => {
    const selectedImage = imagesLightboxResponsive.findIndex(
      (index) => index === url
    );
    setOpenLightboxResponsive(true);
    setSelectedImageResponsive(selectedImage);
  };

  // actualizar estado de publicacion
  const handleChangeIsPublish = async (event, idBanner) => {
    const formData = new FormData();
    formData.append("is_publish", event.target.checked);

    const result = await dispatch(
      updateSlayer(idBanner, formData, {
        page: currentPage,
        search: "",
      })
    );

    if (result.status === 200) {
      enqueueSnackbar(result.data.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  // Paginacion
  const handleChangePagination = (event, value) => {
    dispatch(dataSlayer({ page: value, search: "" }));
  };

  const DATA_BANNERS = slayers?.map((item, index) => ({
    id: index + 1,
    preview: (
      <Avatar
        variant="rounded"
        src={item.path}
        onClick={() => handleOpenLightbox(item.path)}
        sx={{ cursor: "pointer" }}
      />
    ),

    path_responsive: (
      <Avatar
        variant="rounded"
        src={item.path_responsive}
        onClick={() => handleOpenLightboxResponsive(item.path_responsive)}
        sx={{ cursor: "pointer" }}
      />
    ),

    name: item.name,
    url: (
      <Typography
        variant="body2"
        component="a"
        target="_blank"
        href={item.url}
        sx={{
          color: "text.primary",
        }}
      >
        {item.url ? item.url : "-"}
      </Typography>
    ),
    is_publish: (
      <>
        <Switch
          size="small"
          checked={item.is_publish}
          onChange={(event) => handleChangeIsPublish(event, item.id)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={item.is_publish ? "success" : "error"}
        >
          {item.is_publish ? "Publicado" : "No publicado"}
        </Label>
      </>
    ),
    actions: (
      <Stack direction="row" spacing={1}>
        <IconButton
          color="primary"
          size="small"
          onClick={() => {
            dispatch(openModal(true));
            setIsFormCategoy(true);
            setOperation(2);
            setIdSelectBanner(item.id);
          }}
        >
          <Edit />
        </IconButton>

        <IconButton
          color="error"
          size="small"
          onClick={() => {
            setOpenDialogDeleteBanner(true);
            setIdSelectBanner(item.id);
          }}
        >
          <DeleteForeverRounded />
        </IconButton>
      </Stack>
    ),
  }));

  const handleDeleteSlayer = async (id) => {
    // validamos para regresas anterior o no
    if (currentPage === lastPage && total % 10 === 1) {
      const result = await dispatch(
        deleteSlayer(id, {
          page: currentPage - 1,
          search: "",
        })
      );
      if (result.status === 200) {
        setOpenDialogDeleteBanner(false);
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      const result = await dispatch(
        deleteSlayer(id, {
          page: currentPage,
          search: "",
        })
      );
      if (result.status === 200) {
        setOpenDialogDeleteBanner(false);
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  // llamamos toda la data
  useEffect(() => {
    dispatch(dataSlayer({ page: 1, search: "" }));
  }, [dispatch]);

  return (
    <Page title="Administración: Banners">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Lista de banners"
          links={[{ name: "Inicio", href: "/" }, { name: "Banners" }]}
          action={
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={() => {
                dispatch(openModal(true));
                setIsFormCategoy(true);
                setOperation(1);
                setIdSelectBanner(null);
              }}
            >
              Nuevo banner
            </Button>
          }
        />
        {/* Tabla */}

        <Stack>
          <TableGeneral
            rows={DATA_BANNERS}
            headLabel={tableHeaders}
            isLoading={isLoading}
          />
        </Stack>

        <Stack
          direction="row"
          sx={{ p: 2, textAlign: "center", justifyContent: "flex-end" }}
        >
          <Typography variant="caption" sx={{ marginTop: "8px" }}>
            TOTAL: {total}
          </Typography>

          <Pagination
            count={lastPage}
            page={currentPage}
            onChange={handleChangePagination}
            showFirstButton
            showLastButton
          />
        </Stack>
      </Container>

      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />

      {/* Responsive */}
      <LightboxModal
        images={imagesLightboxResponsive}
        mainSrc={imagesLightboxResponsive[selectedImageResponsive]}
        photoIndex={selectedImageResponsive}
        setPhotoIndex={setSelectedImageResponsive}
        isOpen={openLightboxResponsive}
        onCloseRequest={() => setOpenLightboxResponsive(false)}
      />

      {isOpenModal && isFormCategoy ? (
        <FormBanner
          title={operation === 1 ? "NUEVO  BANNER" : "EDITAR  BANNER"}
          operation={operation}
          idSelectBanner={idSelectBanner}
        />
      ) : null}

      {/* Alerta eliminar el BANNER o SLIDER PRINCIPAL*/}
      <InitDialogAlert
        isOpen={openDialogDeleteBanner}
        setOpen={setOpenDialogDeleteBanner}
        onOk={() => handleDeleteSlayer(idSelectBanner)}
        title={`¿Desea eliminar "${slayers?.find((item) => item.id === idSelectBanner)?.path
          }"? `}
        type="error"
      />
    </Page>
  );
};

export default BannerHome;
