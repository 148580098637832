import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken, AppApiNoToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Loggin
    loginSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.token = action.payload?.access_token;
      state.message = "Bienvenido 🙌";
    },

    logoutSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.token = null;
      state.message = action.payload;
    },
  },
});

// Reducer - Aca va la data o los valores
export default authSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const { newRequest, hasError, loginSuccess, logoutSuccess } =
  authSlice.actions;

// ========Funciones===========
// -------------------------------------------------------
export function loginUser({ email, password }) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiNoToken.post("login", {
        email,
        password,
      });
      dispatch(loginSuccess(response.data));
      localStorage.setItem("token-incamotors", response?.data.access_token);
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
    }
  };
}

// !TODO: Logout queda pendiente realizarlo, falta implementar
export function logoutUser() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.post("/logout");
      dispatch(logoutSuccess(response.data?.message));
      localStorage.removeItem("token");
    } catch (error) {
      dispatch(
        hasError(error.response.data.message || "Información incorrecta")
      );
    }
  };
}
