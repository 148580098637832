import { Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import LoginPage from "../pages/auth/login";
import CompanyConfiguration from "../pages/dashboard/configuration";
import CompanyConfigGeneral from "../pages/dashboard/configuration/company";
import UsersConfig from "../pages/dashboard/configuration/user";
import EstablishmentConfig from "../pages/dashboard/configuration/establishment";
import SubscribedCustomers from "../pages/dashboard/subscribed";
import VehicleConfig from "../pages/dashboard/configuration/vehicle";
import Vehicles from "../pages/dashboard/vehicles";
import BannerHome from "../pages/dashboard/banners";
import SocialNetworks from "../pages/dashboard/social-networks";
import SpecificationsVehicle from "../pages/dashboard/specifications-vehicle";
import VariantsVehicle from "../pages/dashboard/variants-vehicle";
import Cotizacion from "../pages/dashboard/quotation";
import Sorteo from "../pages/dashboard/sorteo";
import CampaniaVehicle from "../pages/dashboard/capania-vehicle";
import DerechosArco from "../pages/dashboard/derechos-arco";

const Navigation = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        {/* Creando Subrutas */}
        {/* General */}
        <Route path="/" element={<DashboardLayout />}>
          <Route index path="banners" element={<BannerHome />} />
          <Route path="suscritos" element={<SubscribedCustomers />} />
          <Route path="cotizaciones" element={<Cotizacion />} />
          {/* <Route path="banners" element={<BannerHome />} /> */}
          <Route path="redes-sociales" element={<SocialNetworks />} />
          <Route path="vehiculos" element={<Vehicles />} />
          <Route
            path="vehiculos/especificaciones/:idVehicle"
            element={<SpecificationsVehicle />}
          />
          <Route
            path="vehiculos/variantes/:idVehicle"
            element={<VariantsVehicle />}
          />

          <Route path="configuracion" element={<CompanyConfiguration />} />
          <Route
            path="configuracion/general"
            element={<CompanyConfigGeneral />}
          />
          <Route path="configuracion/vehiculo" element={<VehicleConfig />} />

          <Route
            path="configuracion/sucursales"
            element={<EstablishmentConfig />}
          />
          <Route path="configuracion/usuarios" element={<UsersConfig />} />

          <Route path="/sorteos" element={<Sorteo />} />
          <Route path="/derechos-arco" element={<DerechosArco />} />
          <Route path="/capania-vehiculo" element={<CampaniaVehicle />} />

          {/* Para reemplazar el path por dashboard */}
          <Route path="/" element={<Navigate to={"banners"} replace />} />
        </Route>

        {/* si no existe la ruta se ira al inicio */}
        {/* <Route path="/*" element={<Navigate to={routes[0].to} replace />} />
         */}
        <Route path="/*" element={<Navigate to={"/login"} replace />} />
      </Routes>
    </>
  );
};

export default Navigation;
