import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  user: [],
  users: [],
  userDetails: [],
  modules: [],
  establishments: [],
  types: [],
  identity_document_types: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Obtener todos los usuarios
    dataUsersSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.users = action.payload;
      state.message = "Obtuvimos todos los usuarios 🙌";
    },

    getDataIdUserSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.user = action.payload;
      state.message = "Obtuvimos data del usuario 🙌";
    },

    //Crear usuario
    createUserSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Actualizar usuario
    updateUserSuccess(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //Eliminar serie
    deleteUserSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },
    getTablesUserSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.identity_document_types = action.payload.identity_document_types;
      state.modules = action.payload.modules;
      state.establishments = action.payload.establishments;
      state.types = action.payload.types;
    },
  },
});

// Reducer - Aca va la data o los valores
export default userSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  dataUsersSuccess,
  deleteUserSuccess,
  createUserSuccess,
  updateUserSuccess,
  getTablesUserSuccess,
  getDataIdUserSuccess,
} = userSlice.actions;

// ========Funciones===========

// Data de los usuarios
// -------------------------------------------------------
export function dataUsers() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`users/records`);
      dispatch(dataUsersSuccess(response?.data?.data));
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}

// Obtener Datos del Usuario por ID
// -------------------------------------------------------
export function getDataIdUser(id) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`/users/record/${id}`);
      dispatch(getDataIdUserSuccess(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error;
    }
  };
}

// Crear usuario
// -------------------------------------------------------
export function createUser(data) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.post("/users", data);
      dispatch(createUserSuccess(response.data.message));
      dispatch(dataUsers()); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Actualizar usuario
// -------------------------------------------------------
export function updateUser(id, data) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.put(`/users/${id}`, data);
      dispatch(updateUserSuccess(response.data.message));
      dispatch(dataUsers()); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Eliminar  usuario
// -------------------------------------------------------
export function deleteUser(id) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.delete(`/users/${id}`);
      dispatch(deleteUserSuccess(response.data.message));
      dispatch(dataUsers());
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}
