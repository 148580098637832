import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Typography,
  Divider,
  Stack,
  MenuItem,
  alpha,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import { setThemeSuccess } from "../../../redux/slices/config";
// components
import MenuPopover from "../../../components/MenuPopover";
import { SwicthTheme } from "../../../components/SwicthTheme";
import { setSearchVehicle } from "../../../redux/slices/vehicle";

const AccountPopover = () => {
  const [open, setOpen] = useState(null);
  const theme = useTheme();
  const [checked, setChecked] = useState(true);
  const { themeApp } = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      dispatch(setThemeSuccess("dark"));
    } else {
      dispatch(setThemeSuccess("light"));
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token-incamotors");
    dispatch(setSearchVehicle(""))
    navigate("/login", { replace: true });
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (themeApp === "light") {
      setChecked(false);
    }
  }, [themeApp, checked]);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[800], 0.5),
            },
          }),
        }}
      >
        <Avatar alt="avantar" src="/assets/resources/avatar.png" />
      </IconButton>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        // arrow='left-bottom'
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          width: 220,
          background: theme.palette.background.paper,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            variant="subtitle2"
            noWrap
            sx={{ textTransform: "uppercase" }}
          >
            ADMINISTRATOR
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textTransform: "capitalize" }}
            noWrap
          >
            {/* {me.type} */}
            ADMIN
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem sx={{ justifyContent: "space-between" }}>
            Tema {<SwicthTheme checked={checked} onChange={handleChange} />}
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem
          sx={{ m: 1, color: theme.palette.error.main }}
          onClick={handleLogout}
        >
          Cerrar Sesión
        </MenuItem>
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
