import React, { useEffect, useState } from "react";
import InitDialog from "../../../../../../components/InitDialog";
// Redux
import {
  addTrademarkEstablishment,
  dataTrademarkEstablishment,
  deleteTrademarkEstablishment,
  getTableEstablishment,
} from "../../../../../../redux/slices/establishment";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../../redux/slices/global";
// Formulario
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFSelect2,
} from "../../../../../../components/hook-form";
import { useSnackbar } from "notistack";
import { Button, IconButton, MenuItem, Stack } from "@mui/material";
import TableGeneral from "../../../../../../components/InitTables/TableGeneral";
import { DeleteForeverRounded } from "@mui/icons-material";

const FormAddTrademark = ({ idSelectEstablishment }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isViewForm, setIsViewForm] = useState(false);
  const { trademarks, trademarksEstablishment, isLoading } = useSelector(
    (state) => state.establishment
  );

  const defaultValues = {
    trademark_id: "",
  };
  // Validaciones
  const FormSchema = Yup.object().shape({
    trademark_id: Yup.string().required("Marca es requerido"),
  });
  // Obtener los valores del formulario
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  const onSubmit = async (data) => {
    const result = await dispatch(
      addTrademarkEstablishment(idSelectEstablishment, data)
    );
    if (result.status === 201 || result.status === 200) {
      reset();
      enqueueSnackbar(result?.data?.message, { variant: "success" });
    } else {
      enqueueSnackbar(result?.message, { variant: "error" });
    }
  };

  const tableHeaders = [
    { id: "id", label: "N°" },
    { id: "trademark", label: "Marcas" },
    { id: "actions", label: "Acciones", align: "center" },
  ];

  const DATA_TRADEMARKS = trademarksEstablishment?.map((item, index) => ({
    id: index + 1,
    trademark: item.trademark,
    actions: (
      <IconButton
        color="error"
        onClick={() =>
          handleDeleteTrademarEstablisment(
            item.establishment_id,
            item.trademark_id
          )
        }
      >
        <DeleteForeverRounded />
      </IconButton>
    ),
  }));

  const handleDeleteTrademarEstablisment = async (
    establishment_id,
    trademark_id
  ) => {
    if (establishment_id !== "") {
      const result = await dispatch(
        deleteTrademarkEstablishment(establishment_id, trademark_id)
      );
      if (result.status === 201) {
        // setOpenDialogDeleteModel(false);
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  // Obtener tables
  useEffect(() => {
    dispatch(getTableEstablishment());
  }, [dispatch]);

  // Obtener listado de marcas del establecimiento
  useEffect(() => {
    dispatch(dataTrademarkEstablishment(idSelectEstablishment));
  }, [dispatch, idSelectEstablishment]);

  // Listado de marcas disponibles
  const marcasDisponibles = trademarks.filter((trademark) => {
    return !trademarksEstablishment.some(
      (trademarkEstablishment) =>
        trademarkEstablishment.trademark_id === trademark.id
    );
  });

  return (
    <InitDialog fullWidth size="sm" title="Marcas asignadas a la sucursal">
      {isViewForm && (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-end", mx: 1, mt: 2 }}
            spacing={2}
          >
            <RHFSelect2 name="trademark_id" label="Marca">
              <MenuItem value="">Seleccione una marca</MenuItem>
              {/* Filtrar solo las marcas disponibles que no esten ya seleccionados en el establecimiento trademarksEstablishment */}

              {marcasDisponibles?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </RHFSelect2>
          </Stack>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-end", mx: 1, mt: 2 }}
            spacing={2}
          >
            <Button
              variant="outlined"
              color="error"
              sx={{ minWidth: 100 }}
              onClick={() => setIsViewForm(false)}
            >
              Cancelar
            </Button>
            <Button
              size="large"
              type="submit"
              variant="contained"
              disabled={!isDirty || isSubmitting}
            >
              agregar marca
            </Button>
          </Stack>
        </FormProvider>
      )}

      <Stack sx={{ justifyContent: "flex-end", mx: 1, mt: 2 }}>
        <TableGeneral
          rows={DATA_TRADEMARKS}
          headLabel={tableHeaders}
          isLoading={isLoading}
        />
      </Stack>
      <Stack
        direction="row"
        sx={{ justifyContent: "flex-end", mx: 1, mt: 2 }}
        spacing={2}
      >
        <Button
          variant="outlined"
          color="error"
          sx={{ minWidth: 100 }}
          onClick={() => {
            dispatch(openModal(false));
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          disabled={isViewForm}
          onClick={() => setIsViewForm(true)}
        >
          Añadir marca
        </Button>
      </Stack>
    </InitDialog>
  );
};

export default FormAddTrademark;
