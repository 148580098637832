import { forwardRef } from "react";
import PropTypes from "prop-types";
import { isNil } from "lodash";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  LinearProgress,
} from "@mui/material";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "../../redux/slices/global";
// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

InitDialog.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  btnConfirm: PropTypes.string,
  btnCancel: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  loading: PropTypes.bool,
};

export default function InitDialog({
  size,
  title,
  subtitle,
  btnConfirm,
  btnCancel,
  onOk,
  onCancel,
  children,
  fullWidth = false,
  visible,
  closeModal,
  loading = false, // mostrar el loading
}) {
  const dispatch = useDispatch();
  const { isOpenModal } = useSelector((state) => state.global);
  const handleClose = () => {
    if (isNil(visible)) dispatch(openModal(false));
    else closeModal();
  };

  const handleCancel = () => {
    if (!isNil(onCancel)) onCancel();
    handleClose();
  };

  const handleConfirm = () => {
    if (!isNil(onOk)) onOk();
    handleClose();
  };

  return (
    <Dialog
      open={visible || isOpenModal}
      TransitionComponent={Transition}
      keepMounted
      maxWidth={size || "xl"} // xs , sm , md, xl
      onClose={handleClose}
      fullWidth={fullWidth}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title" fontSize={16}>
        {title}
      </DialogTitle>
      <DialogContent>
        {subtitle && (
          <DialogContentText id="dialog-description">
            {subtitle}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      {(btnCancel || btnConfirm) && (
        <DialogActions>
          {btnCancel && (
            <Button color="inherit" onClick={handleCancel}>
              {btnCancel || "Cancelar"}
            </Button>
          )}
          {btnConfirm && (
            <Button variant="contained" onClick={handleConfirm}>
              {btnConfirm || "Aceptar"}
            </Button>
          )}
        </DialogActions>
      )}
      {loading && <LinearProgress />}
    </Dialog>
  );
}
