import React, { useEffect } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  FormProvider,
  RHFSelect2,
  RHFTextField,
} from "../../../../../components/hook-form";
import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
} from "@mui/material";
import InitDialog from "../../../../../components/InitDialog";

// Formulario
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { openModal } from "../../../../../redux/slices/global";
import { useSnackbar } from "notistack";
import { AddCircle, Delete } from "@mui/icons-material";
import {
  getDataIdCompany,
  getTableCompany,
  updateCompany,
} from "../../../../../redux/slices/company";

const FormCompany = ({ title }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, social_netkork_types } = useSelector(
    (state) => state.company
  );

  // Formulario
  // valores
  const defaultValues = {
    email: "",
    social_networks: [
      {
        type_of_social_network: "",
        path: "",
      },
    ],
    telephones: [
      {
        description: "",
        subdescription: "",
        number: "",
        url: "",
      },
    ],
  };

  // Validaciones
  const FormSchema = Yup.object().shape({
    email: Yup.string()
      .required("Correo electrónico es requerido")
      .email("Correo electrónico no válido"),
  });

  // Obtener los valores del formulario
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    control,
  } = methods;

  // Campos dinamicos
  const {
    fields: socialNetworkFields,
    append: socialNetworkAppend,
    remove: socialNetworkRemove,
  } = useFieldArray({ control, name: "social_networks" });

  const {
    fields: telefFields,
    append: telefAppend,
    remove: telefRemove,
  } = useFieldArray({ control, name: "telephones" });

  const onSubmit = async (data) => {
    const updateData = {
      email: data.email,
      social_networks: data.social_networks.map((item) => ({
        type_of_social_network: item.type_of_social_network,
        path: item.path,
      })),
      telephones: data.telephones,
    };

    const result = await dispatch(updateCompany(updateData));
    if (result.status === 200) {
      enqueueSnackbar("Datos actualizados correctamente", {
        variant: "success",
      });
      dispatch(openModal(!true));
    } else {
      enqueueSnackbar("Error al actualizar los datos", {
        variant: "error",
      });
    }
  };

  // Autocompletar datos cuando es editar el usuario
  useEffect(() => {
    const data = async () => {
      const result = await dispatch(getDataIdCompany());
      const data = {
        email: result.email,
        social_networks: result.social_networks,
        telephones: result.telephones,
      };
      reset(data);
    };
    data();
  }, [dispatch, reset]);

  // Obtener tables
  useEffect(() => {
    dispatch(getTableCompany());
  }, [dispatch]);

  return (
    <InitDialog title={title} size="lg" fullWidth loading={isLoading}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Stack sx={{ m: 1 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: "column", md: "column" }} spacing={2}>
                  <RHFTextField
                    name="email"
                    label="Correo electrónico"
                    type="email"
                    autoComplete="off"
                  />
                </Stack>
                {/* Direcciones */}
                <Divider textAlign="center">
                  <Chip label="Redes Sociales" />
                </Divider>

                <Stack
                  direction={{ xs: "column", md: "column" }}
                  sx={{ alignItems: "flex-start" }}
                  spacing={2}
                >
                  {socialNetworkFields.map((item, index) => (
                    <Stack
                      key={item.id}
                      flexDirection="row"
                      sx={{
                        width: "100%",
                        alignItems: "center",
                      }}
                      gap={1}
                    >
                      <RHFSelect2
                        name={`social_networks.${index}.type_of_social_network`}
                        label="Red Social"
                      >
                        <MenuItem value=""> Seleccione </MenuItem>

                        {social_netkork_types.map((item, i) => (
                          <MenuItem key={i} value={item.id}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </RHFSelect2>

                      <RHFTextField
                        name={`social_networks.${index}.path`}
                        label="URL red social"
                      />

                      {index === 0 ? (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            socialNetworkAppend({
                              type_of_social_network: "",
                              path: "",
                            });
                          }}
                        >
                          <AddCircle />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="primary"
                          onClick={() => socialNetworkRemove(index)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </Stack>
                  ))}
                </Stack>

                {/* telefonos */}
                <Divider textAlign="center">
                  <Chip label="Teléfonos WhatsApp" />
                </Divider>

                <Stack
                  direction={{ xs: "column", md: "column" }}
                  sx={{ alignItems: "flex-start" }}
                  spacing={2}
                >
                  {telefFields.map((item, index) => (
                    <Stack
                      key={item.id}
                      flexDirection="row"
                      sx={{
                        width: "100%",
                        alignItems: "center",
                      }}
                      gap={1}
                    >
                      <RHFTextField
                        name={`telephones.${index}.description`}
                        label="Descripción"
                      />

                      <RHFTextField
                        name={`telephones.${index}.subdescription`}
                        label="Subdescripción"
                      />

                      <RHFTextField
                        name={`telephones.${index}.number`}
                        label="Número"
                      />

                      <RHFTextField
                        name={`telephones.${index}.url`}
                        label="URL rediccionamiento"
                      />

                      {index === 0 ? (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            telefAppend({
                              description: "",
                              subdescription: "",
                              number: "",
                              url: "",
                            });
                          }}
                        >
                          <AddCircle />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="primary"
                          onClick={() => telefRemove(index)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", m: 1, mt: 3 }}
          spacing={2}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ minWidth: 100 }}
            onClick={() => {
              dispatch(openModal(false));
            }}
          >
            Cancelar
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            disabled={!isDirty || isSubmitting}
          >
            Guardar
          </Button>
        </Stack>
      </FormProvider>
    </InitDialog>
  );
};

export default FormCompany;
