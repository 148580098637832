import React, { useState } from "react";
import {
  Grid,
  Stack,
  styled,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  Button,
  useTheme,
  FormControlLabel,
  Checkbox,
  Divider,
  Chip,
  Alert,
  CircularProgress,
} from "@mui/material";
import {
  FacebookOutlined,
  Instagram,
  LinkedIn,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../redux/slices/auth";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const ContentStyle = styled("div")(() => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: 6,
}));

// valores
const defaultValues = {
  email: "",
  password: "",
};

// Validaciones
const FormSchema = Yup.object().shape({
  email: Yup.string()
    .required("Correo es requerido")
    .email("Correo no es válido"),

  password: Yup.string()
    .required("Contraseña es requerido")
    .min(6, "La contraseña debe tener una longitud mínima de 6 caracteres"),
});

const LoginForm = () => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const { message, isError, isLoading } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  // Obtener los valores del formulario
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const onSubmit = async (data) => {
    const response = await dispatch(
      loginUser({
        email: data.email,
        password: data.password,
      })
    );
    if (response?.status === 200) {
      navigate("/banners", { replace: true });
      enqueueSnackbar(`Bienvenido a IncaMotors  🙌`, { variant: "success" });
      reset();
    } else {
      console.error("error iniciar sesion", response);
    }
  };

  return (
    <ContentStyle>
      {/* Fondo SVG */}
      <Box
        component="img"
        alt="image shape"
        src="/assets/resources/shape.svg"
        sx={{
          top: 0,
          right: 0,
          bottom: 0,
          position: "absolute",
          filter: "grayscale(1) opacity(48%)",
          display: { xs: "block", md: "block" },
          zIndex: -9999,
        }}
      />
      {/* Fondo SVG */}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            mb: 2,
            mt: 6,
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box
              sx={{
                flexGrow: 1,
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <Grid container direction="column" alignItems="center">
                <Box
                  component="img"
                  src={
                    theme.palette.mode === "dark"
                      ? "/assets/logo/logo-dark.svg"
                      : "/assets/logo/logo-white.svg"
                  }
                  sx={{ width: 280, height: "100%" }}
                />
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ mt: 2 }}
                >
                  <Typography
                    variant="h1"
                    gutterBottom
                    sx={{ fontWeight: 600, mb: 0, fontSize: 24 }}
                  >
                    Bienvenido
                  </Typography>
                  <Chip
                    label="Incamotors"
                    color="primary"
                    variant="filled"
                    sx={{ fontSize: 24, borderRadius: 2 }}
                  />
                </Stack>
              </Grid>

              <Typography
                sx={{
                  color: "text.secondary",
                  fontStyle: "italic",
                  mb: 3,
                  mt: 0.5,
                }}
              >
                "Las mejores marcas en un solo lugar"
              </Typography>

              {isError && (
                <Alert severity="error" sx={{ my: 2.5 }}>
                  {message}
                </Alert>
              )}

              <RHFTextField name="email" label="Correo" fullWidth />

              <Box sx={{ mb: 2 }} />

              <RHFTextField
                fullWidth
                name="password"
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        {showPassword ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Box
                sx={{
                  my: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              />

              <Button
                size="large"
                type="submit"
                variant="contained"
                disabled={!isDirty || isLoading}
                fullWidth
                sx={{ fontWeight: 600 }}
              >
                {isLoading ? <CircularProgress size={30} /> : "Iniciar Sesión"}
              </Button>
              <Box
                sx={{
                  my: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <FormControlLabel control={<Checkbox />} label="Recuérdame" />
              </Box>
              <Divider />

              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ mt: 4, mb: { xs: 3, md: 0 } }}
              >
                <IconButton
                  size="large"
                  color="primary"
                  sx={{ p: 1 }}
                  target="_blank"
                  variant="a"
                >
                  <FacebookOutlined />
                </IconButton>
                <IconButton
                  size="large"
                  color="primary"
                  sx={{ p: 1 }}
                  target="_blank"
                >
                  <LinkedIn />
                </IconButton>
                <IconButton
                  size="large"
                  color="primary"
                  sx={{ p: 1 }}
                  target="_blank"
                >
                  <Instagram />
                </IconButton>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </FormProvider>
    </ContentStyle>
  );
};

export default LoginForm;
