import { useEffect, useState } from "react";
import {
  Button,
  Container,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CsvDownloadButton from "react-json-to-csv";
import { SearchOutlined } from "@mui/icons-material";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";

import TableGeneral from "../../../components/InitTables/TableGeneral";
import Page from "../../../components/Page";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  dataCampaignCar,
  exportcampaignCar,
} from "../../../redux/slices/campaignCar";
// Componentes externos

const CampaniaVehicle = () => {
  const dispatch = useDispatch();

  const { participants, pagination, isLoading } = useSelector(
    (state) => state.campaignCar
  );
  const { total, currentPage, lastPage } = pagination;
  const [allDataSubs, setAllDataSubs] = useState([]);

  // Paginacion
  const handleChangePagination = (event, value) => {
    dispatch(dataCampaignCar({ page: value, search: "" }));
  };

  // Buscador
  const handleChangeSearch = async (event) => {
    event.preventDefault();
    dispatch(
      dataCampaignCar({
        page: 1,
        search: event.target.value,
      })
    );
  };

  const tableHeaders = [
    { id: "id", label: "N°" },
    { id: "full_name", label: "Nombres", align: "left" },
    { id: "email", label: "Correo", align: "left" },
    { id: "document_number", label: "DNI", align: "left" },
    { id: "phone", label: "Celular", align: "left" },
    { id: "interest_model", label: "Modelo Interesado", align: "left" },
    { id: "financing", label: "Financiamiento", align: "left" },
    { id: "client_has_notification", label: "Notif. cliente", align: "center" },
    {
      id: "company_has_notification",
      label: "Notif. Compañia",
      align: "center",
    },
  ];

  const DATA_CAMPANIA_VEHICLES = participants?.map((item, index) => ({
    id: index + 1,
    email: item?.email,
    document_number: item?.document_number,
    phone: item?.phone,
    full_name: item?.full_name,
    interest_model: item?.interest_model,
    financing: item?.financing,
    client_has_notification: item?.client_has_notification ? "Si" : "No",
    company_has_notification: item?.company_has_notification ? "Si" : "No",
  }));

  // Obtener suscriptores
  useEffect(() => {
    dispatch(dataCampaignCar({ page: 1, search: "" }));
  }, [dispatch]);

  const handleDowloandCsv = async () => {
    const data = await dispatch(exportcampaignCar());
    setAllDataSubs(data);
  };

  return (
    <Page title="Administración: Campaña vehículos">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Lista de Campaña vehículos"
          links={[{ name: "Inicio", href: "/" }, { name: "Campaña vehículos" }]}
          action={
            <>
              {allDataSubs.length < 1 ? (
                <Button
                  variant="contained"
                  onClick={handleDowloandCsv}
                  disabled={isLoading}
                >
                  Generar reporte
                </Button>
              ) : (
                <CsvDownloadButton
                  data={allDataSubs}
                  filename="capaña-vehiculo-incamotors.csv"
                  style={{
                    //pass other props, like styles
                    boxShadow: "inset 0px 1px 0px 0px #287233",
                    backgroundColor: "#287233",
                    borderRadius: "6px",
                    border: "1px solid #287233",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "#ffffff",
                    fontSize: "15px",
                    fontWeight: "bold",
                    padding: "6px 24px",
                    textDecoration: "none",
                    textShadow: "0px 1px 0px #287233",
                  }}
                >
                  Descargar reporte
                </CsvDownloadButton>
              )}
            </>
          }
        />
        {/* Tabla */}

        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: 630,
            justifyContent: "space-between",
          }}
        >
          <Stack>
            <Stack direction="row" justifyContent="flex-end" sx={{ p: 1 }}>
              <TextField
                onChange={handleChangeSearch}
                variant="outlined"
                placeholder="Buscar..."
                sx={{ m: 1, width: "40ch" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <TableGeneral
              rows={DATA_CAMPANIA_VEHICLES}
              headLabel={tableHeaders}
              isLoading={isLoading}
            />
          </Stack>

          <Stack
            direction="row"
            sx={{ p: 1, textAlign: "center", justifyContent: "flex-end" }}
          >
            <Typography variant="caption" sx={{ marginTop: "8px" }}>
              TOTAL: {total}
            </Typography>

            <Pagination
              count={lastPage}
              page={currentPage}
              onChange={handleChangePagination}
              showFirstButton
              showLastButton
            />
          </Stack>
        </Paper>
      </Container>
    </Page>
  );
};

export default CampaniaVehicle;
