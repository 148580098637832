import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Box,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { HighlightOffRounded, WarningAmberRounded } from "@mui/icons-material";

export default function AlertDialog({
  isLoading = false,
  isOpen,
  setOpen,
  onOk,
  title,
  subTitle,
  type = "error",
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <LinearProgress
        color={type === "error" ? "error" : "warning"}
        value={100}
        variant="determinate"
      />
      <DialogTitle
        id="alert-dialog-title"
        sx={{ textTransform: "uppercase", textAlign: "center", fontSize: 16 }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ justifyContent: "center", alignContent: "center" }}
        >
          <Box
            sx={{
              textAlign: "center",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            {type === "error" ? (
              <HighlightOffRounded color="error" sx={{ fontSize: 50 }} />
            ) : (
              <WarningAmberRounded color="warning" sx={{ fontSize: 50 }} />
            )}
          </Box>
        </Stack>
        {subTitle && (
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontSize: 14 }}
          >
            {subTitle}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={() => setOpen(false)} variant="outlined">
          Cancelar
        </Button>
        <Box sx={{ px: 1 }} />
        <Button
          onClick={onOk}
          color={type === "error" ? "error" : "warning"}
          variant="contained"
          disabled={isLoading}
        >
          Eliminar
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  isOpen: PropTypes.any,
  onOk: PropTypes.any,
  setOpen: PropTypes.func,
  subTitle: PropTypes.any,
  title: PropTypes.any,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
};
