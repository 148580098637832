import React from "react";
import Page from "../../../components/Page";
import { Box, Container, Grid, Typography } from "@mui/material";
import CardConfig from "../../../components/CardConfig";

const CompanyConfiguration = () => {
  const ITEMS_CARD = [
    {
      name: "EMPRESA",
      description: "Datos de generales de la empresa, logo y cuentas bancarias",
      icon: "/assets/img/company/company.png",
      href: "/configuracion/general",
    },
    {
      name: "VEHICULO",
      description:
        "Configuración general de los vehículos, marca, modelo y categorías.",
      icon: "/assets/img/company/coche.png",
      href: "/configuracion/vehiculo",
    },
    {
      name: "SUCURSALES",
      description: "Registro y edición de sucursales, almacenes y series.",
      icon: "/assets/img/company/agencia.png",
      href: "/configuracion/sucursales",
    },
    {
      name: "USUARIOS",
      description: "Configura permisos del personal para emitir comprobantes",
      icon: "/assets/img/company/personal.png",
      href: "/configuracion/usuarios",
    },
  ];

  return (
    <Page title="Configuración">
      <Container maxWidth="xl">
        <Box sx={{ pb: 4, borderRadius: 2 }}>
          <Typography variant="h2">Configuración</Typography>
          <Typography variant="caption">
            Configura los datos generales de la empresa IncaMotors.
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {ITEMS_CARD.map((item) => (
            <Grid item key={item.name} xs={12} sm={6} lg={3}>
              <CardConfig key={item.name} item={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
};

export default CompanyConfiguration;
