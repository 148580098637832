import React, { useEffect } from "react";
import { Button, Chip, Divider, Grid, IconButton, Stack } from "@mui/material";

import InitDialog from "../../../../../../components/InitDialog";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../../redux/slices/global";
import {
  createEstablishment,
  getDataIdEstablishment,
  updateEstablishment,
} from "../../../../../../redux/slices/establishment";

// Formulario
import * as Yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFSwitch,
  RHFTextField,
} from "../../../../../../components/hook-form";
import { useSnackbar } from "notistack";
import { AddCircle, Delete } from "@mui/icons-material";

const FormEstablishment = ({
  title,
  idSelectEstablishment,
  operationEstablishment,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.establishment);

  // valores
  const defaultValues = {
    description: "",
    telephone_info: "",
    is_priority: false,
    is_publish: false,
    email: "",
    addresses: [
      {
        description: "",
        url: "",
      },
    ],
    saleHours: [
      {
        business_hours: "",
      },
    ],

    salePhone: [
      {
        telephones: "",
      },
    ],

    postSaleHours: [
      {
        business_hours: "",
      },
    ],

    postSalePhone: [
      {
        telephones: "",
      },
    ],
  };

  // Validaciones
  const FormSchema = Yup.object().shape({
    description: Yup.string()
      .required("Descripción es requerido")
      .min(2, "Mínimo 2 caracteres")
      .max(25, "Máximo 25 caracteres"),
    telephone_info: Yup.string()
      .required("Teléfono es requerido")
      .min(2, "Mínimo 2 caracteres")
      .max(25, "Máximo 25 caracteres"),
    email: Yup.string().required("Email es requerido"),
    addresses: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required("Descripción es requerido"),
        url: Yup.string().required("Url es requerido"),
      })
    ),
    saleHours: Yup.array().of(
      Yup.object().shape({
        business_hours: Yup.string().required("Horario es requerido"),
      })
    ),
    salePhone: Yup.array().of(
      Yup.object().shape({
        telephones: Yup.string().required("Teléfono es requerido"),
      })
    ),
    postSaleHours: Yup.array().of(
      Yup.object().shape({
        business_hours: Yup.string().required("Horario es requerido"),
      })
    ),
    postSalePhone: Yup.array().of(
      Yup.object().shape({
        telephones: Yup.string().required("Teléfono es requerido"),
      })
    ),
  });

  // Obtener los valores del formulario
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    control,
  } = methods;

  const {
    fields: addressesFields,
    append: addressesAppend,
    remove: addressesRemove,
  } = useFieldArray({ control, name: "addresses" });

  const {
    fields: saleHoursFields,
    append: saleHoursAppend,
    remove: saleHoursRemove,
  } = useFieldArray({ control, name: "saleHours" });

  const {
    fields: salePhoneFields,
    append: salePhoneAppend,
    remove: salePhoneRemove,
  } = useFieldArray({ control, name: "salePhone" });

  const {
    fields: postSaleHoursFields,
    append: postSaleHoursAppend,
    remove: postSaleHoursRemove,
  } = useFieldArray({ control, name: "postSaleHours" });

  const {
    fields: postSalePhoneFields,
    append: postSalePhoneAppend,
    remove: postSalePhoneRemove,
  } = useFieldArray({ control, name: "postSalePhone" });

  // LLenar el formulario los datos para editar
  useEffect(() => {
    if (idSelectEstablishment !== null && operationEstablishment === 2) {
      const data = async () => {
        const result = await dispatch(
          getDataIdEstablishment(idSelectEstablishment)
        );
        const data = {
          is_priority: result.is_priority,
          is_publish: result.is_publish,
          description: result.description,
          telephone_info: result.telephone_info,
          email: result.email,
          addresses: result.addresses,
          saleHours: result.sale.business_hours.map((item) => {
            return {
              business_hours: item,
            };
          }),
          salePhone: result.sale.telephones.map((item) => {
            return {
              telephones: item,
            };
          }),
          postSaleHours: result.post_sale.business_hours.map((item) => {
            return {
              business_hours: item,
            };
          }),
          postSalePhone: result.post_sale.telephones.map((item) => {
            return {
              telephones: item,
            };
          }),
        };
        reset(data);
      };
      data();
    }
  }, [dispatch, idSelectEstablishment, operationEstablishment, reset]);

  const onSubmit = async (data) => {
    const dataCreate = {
      is_priority: data.is_priority,
      description: data.description,
      telephone_info: data.telephone_info,
      email: data.email,
      is_publish: data.is_publish,
      addresses: data.addresses.map((item) => {
        return {
          description: item.description,
          url: item.url,
        };
      }),
      sale: {
        telephones: data.salePhone.map((item) => {
          return item.telephones;
        }),
        business_hours: data.saleHours.map((item) => {
          return item.business_hours;
        }),
      },
      post_sale: {
        telephones: data.postSalePhone.map((item) => {
          return item.telephones;
        }),
        business_hours: data.postSaleHours.map((item) => {
          return item.business_hours;
        }),
      },
    };

    if (idSelectEstablishment === null && operationEstablishment === 1) {
      // MANDAMOS A CREAR ID NULL
      const result = await dispatch(createEstablishment(dataCreate));
      if (result.status === 201) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else if (idSelectEstablishment !== null && operationEstablishment === 2) {
      // ACTUALIZAR
      const result = await dispatch(
        updateEstablishment(idSelectEstablishment, dataCreate)
      );
      if (result.status === 200) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  return (
    <InitDialog title={title} size="lg" fullWidth loading={isLoading}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ m: 1 }}>
          <Stack spacing={{ xs: 2, md: 3 }}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{ alignItems: "flex-start" }}
              spacing={2}
            >
              <RHFTextField
                name="description"
                label="Descripción"
                autoComplete="off"
              />
              <RHFTextField
                name="telephone_info"
                label="Teléfono informes"
                autoComplete="off"
              />
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{ alignItems: "flex-start" }}
              spacing={2}
            >
              <RHFTextField
                name="email"
                label="Correo sede"
                autoComplete="off"
              />
              <RHFSwitch name="is_publish" label="¿Publicar sucursal?" />

              <RHFSwitch name="is_priority" label="¿Sucursal principal?" />
            </Stack>

            {/* Direcciones */}
            <Divider textAlign="center">
              <Chip label="Direcciones" />
            </Divider>

            <Stack
              direction={{ xs: "column", md: "column" }}
              sx={{ alignItems: "flex-start" }}
              spacing={2}
            >
              {addressesFields.map((item, index) => (
                <Stack
                  key={item.id}
                  flexDirection="row"
                  sx={{
                    width: "100%",
                    alignItems: "center",
                  }}
                  gap={1}
                >
                  <RHFTextField
                    name={`addresses.${index}.description`}
                    label="Dirección"
                  />

                  <RHFTextField
                    key={item.id}
                    name={`addresses.${index}.url`}
                    label="URL (Google Maps)"
                  />

                  {index === 0 ? (
                    <IconButton
                      color="primary"
                      onClick={() => {
                        addressesAppend({
                          description: "",
                          url: "",
                        });
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                  ) : (
                    <IconButton
                      color="primary"
                      onClick={() => addressesRemove(index)}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Stack>
              ))}
            </Stack>

            <Divider
              textAlign="center"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <Chip label="Horario Ventas / Teléfonos Ventas" />
            </Divider>

            <Grid container>
              <Grid item xs={12} sm={0}>
                <Divider
                  textAlign="center"
                  sx={{ display: { xs: "flex", md: "none" } }}
                >
                  <Chip label="Horario Ventas " />
                </Divider>
              </Grid>

              <Grid item xs={12} md={6}>
                {/* Horario Ventas */}
                {saleHoursFields.map((item, index) => (
                  <Stack
                    key={item.id}
                    flexDirection="row"
                    sx={{
                      width: "100%",
                      alignItems: "center",
                      my: 1.2,
                    }}
                  >
                    <RHFTextField
                      name={`saleHours.${index}.business_hours`}
                      label="Horario Ventas"
                    />

                    {index === 0 ? (
                      <IconButton
                        color="primary"
                        onClick={() => {
                          saleHoursAppend({ business_hours: "" });
                        }}
                      >
                        <AddCircle />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() => saleHoursRemove(index)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Stack>
                ))}
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid item xs={12} sm={0}>
                  <Divider
                    textAlign="center"
                    sx={{ display: { xs: "flex", md: "none" } }}
                  >
                    <Chip label="Teléfonos Ventas " />
                  </Divider>
                </Grid>
                {/* Teléfonos Ventas */}
                {salePhoneFields.map((item, index) => (
                  <Stack
                    key={item.id}
                    flexDirection="row"
                    sx={{
                      width: "100%",
                      alignItems: "center",
                      my: 1.2,
                    }}
                  >
                    <RHFTextField
                      name={`salePhone.${index}.telephones`}
                      label="Teléfonos Ventas"
                    />

                    {index === 0 ? (
                      <IconButton
                        color="primary"
                        onClick={() => {
                          salePhoneAppend({ telephones: "" });
                        }}
                      >
                        <AddCircle />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() => salePhoneRemove(index)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Stack>
                ))}
              </Grid>
            </Grid>

            {/* POSTVENTA */}
            <Divider
              textAlign="center"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <Chip label="Horario PostVentas / Teléfonos PostVentas" />
            </Divider>

            <Grid container>
              <Grid item xs={12} sm={0}>
                <Divider
                  textAlign="center"
                  sx={{ display: { xs: "flex", md: "none" } }}
                >
                  <Chip label="Horario Postventa " />
                </Divider>
              </Grid>

              <Grid item xs={12} md={6}>
                {/* Horario Postventa */}
                {postSaleHoursFields.map((item, index) => (
                  <Stack
                    key={item.id}
                    flexDirection="row"
                    sx={{
                      width: "100%",
                      alignItems: "center",
                      my: 1.2,
                    }}
                  >
                    <RHFTextField
                      name={`postSaleHours.${index}.business_hours`}
                      label="Horario PostVenta"
                    />

                    {index === 0 ? (
                      <IconButton
                        color="primary"
                        onClick={() => {
                          postSaleHoursAppend({ business_hours: "" });
                        }}
                      >
                        <AddCircle />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() => postSaleHoursRemove(index)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Stack>
                ))}
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid item xs={12} sm={0}>
                  <Divider
                    textAlign="center"
                    sx={{ display: { xs: "flex", md: "none" } }}
                  >
                    <Chip label="Teléfonos PostVenta " />
                  </Divider>
                </Grid>
                {/* Teléfonos Postventa */}
                {postSalePhoneFields.map((item, index) => (
                  <Stack
                    key={item.id}
                    flexDirection="row"
                    sx={{
                      width: "100%",
                      alignItems: "center",
                      my: 1.2,
                    }}
                  >
                    <RHFTextField
                      name={`postSalePhone.${index}.telephones`}
                      label="Teléfonos PostVenta"
                    />

                    {index === 0 ? (
                      <IconButton
                        color="primary"
                        onClick={() => {
                          postSalePhoneAppend({ telephones: "" });
                        }}
                      >
                        <AddCircle />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() => postSalePhoneRemove(index)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Stack>
                ))}
              </Grid>
            </Grid>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", mx: 1, mt: 2 }}
          spacing={2}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ minWidth: 100 }}
            onClick={() => {
              dispatch(openModal(false));
            }}
          >
            Cancelar
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            disabled={!isDirty || isSubmitting}
          >
            Guardar
          </Button>
        </Stack>
      </FormProvider>
    </InitDialog>
  );
};

export default FormEstablishment;
