import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import authReducer from "./slices/auth";
import configReducer from "./slices/config";
import genericReducer from "./slices/generic";
import globalReducer from "./slices/global";
import userReducer from "./slices/user";
import establishmentReducer from "./slices/establishment";
import categoryReducer from "./slices/category";
import slayerReducer from "./slices/slayer";
import trademarkReducer from "./slices/trademark";
import modelReducer from "./slices/model";
import subscriptionReducer from "./slices/subscription";
import publicationReducer from "./slices/publication";
import companyReducer from "./slices/company";
import vehicleReducer from "./slices/vehicle";
import specificationVehicleReducer from "./slices/specificationVehicle";
import variantVehicleReducer from "./slices/variantVehicle";
import quotationReducer from "./slices/quotation";
import raflleReducer from "./slices/raflle";
import campaignCarReducer from "./slices/campaignCar";
import dataPrivacyReducer from "./slices/dataPrivacy";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const authPersistConfig = {
  key: "auth",
  storage,
  keyPrefix: "redux-",
  whitelist: ["token"],
};

const configPersistConfig = {
  key: "config",
  storage,
  keyPrefix: "redux-",
  whitelist: ["themeApp"],
};

const vehiclePersistConfig = {
  key: "vehicle",
  storage,
  keyPrefix: "redux-",
  whitelist: ["searchVehicle"],
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  config: persistReducer(configPersistConfig, configReducer),
  vehicle: persistReducer(vehiclePersistConfig, vehicleReducer),
  generic: genericReducer,
  global: globalReducer,
  user: userReducer,
  establishment: establishmentReducer,
  category: categoryReducer,
  slayer: slayerReducer,
  trademark: trademarkReducer,
  model: modelReducer,
  subscription: subscriptionReducer,
  publication: publicationReducer,
  company: companyReducer,
  specificationVehicle: specificationVehicleReducer,
  variantVehicle: variantVehicleReducer,
  quotation: quotationReducer,
  raflle: raflleReducer,
  campaignCar: campaignCarReducer,
  dataPrivacy: dataPrivacyReducer,
});

export { rootPersistConfig, rootReducer };
