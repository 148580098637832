import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSpecificationVehicle,
  getSpecificationVehicle,
} from "../../../redux/slices/specificationVehicle";
import { openModal } from "../../../redux/slices/global";
import FormSpecificationVehicle from "./components/FormDialog/FormSpecificationVehicle";
import { InitDialogAlert } from "../../../components/InitDialogs";
import { useSnackbar } from "notistack";

const SpecificationsVehicle = () => {
  const { idVehicle } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isFormSpecification, setIsFormSpecification] = useState(false);
  const [dataIdEspecficationVehicle, setDataIdEspecficationVehicle] = useState(
    {}
  );
  const [
    openDialogDeleteSpecificationVehicle,
    setOpenDialogDeleteSpecificationVehicle,
  ] = useState(false);

  const [idSpecificationVehicle, setIdSpecificationVehicle] = useState(null);

  const [isOperation, setIsOperation] = useState(1); //1: create, 2: update
  const { isOpenModal } = useSelector((state) => state.global);
  const { specificationsVehicle, isLoading } = useSelector(
    (state) => state.specificationVehicle
  );

  const handleDeleteSpecificationVehicle = async (idVehicle) => {
    const result = await dispatch(
      deleteSpecificationVehicle(idSpecificationVehicle, idVehicle)
    );
    if (result.status === 200) {
      setOpenDialogDeleteSpecificationVehicle(false);
      enqueueSnackbar(result.data.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  // OBTENER LAS ESPECIFICACIONES DEL VEHICULO
  useEffect(() => {
    if (idVehicle) dispatch(getSpecificationVehicle(idVehicle));
  }, [idVehicle, dispatch]);

  return (
    <>
      <Page title="Administración: Vehículos Especificaciones">
        <Container maxWidth="lg">
          <HeaderBreadcrumbs
            heading={`Lista de especificaciones - Modelo: ${specificationsVehicle?.model}`}
            links={[
              { name: "Vehiculos", href: "/vehiculos" },
              { name: "Vehículo especificaciones" },
            ]}
            action={
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(openModal(true));
                  setIsFormSpecification(true);
                  setIsOperation(1); // 1: create, 2: update
                }}
              >
                Nueva especificación
              </Button>
            }
          />

          <Grid container spacing={2} alignItems="center">
            {isEmpty(specificationsVehicle?.specifications || []) ? (
              <Grid item xs={12}>
                <Card
                  sx={{
                    height: 300,
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Typography variant="h3">
                    ❌ No hay especificaciones ❌
                  </Typography>
                </Card>
              </Grid>
            ) : (
              <>
                {(specificationsVehicle?.specifications || []).map(
                  (specification) => (
                    <Grid item xs={12} sm={6} md={4} key={specification.id}>
                      <Card
                        sx={{
                          height: "max-content",
                        }}
                      >
                        <CardMedia
                          component="img"
                          height="140"
                          image={specification.icon_url}
                          alt="green iguana"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h2" component="div">
                            {specification.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            // anadimos el dangerusHTML
                            dangerouslySetInnerHTML={{
                              __html: specification.description,
                            }}
                          />
                        </CardContent>
                        <CardActions sx={{ justifyContent: "flex-end" }}>
                          <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              setIdSpecificationVehicle(specification.id);
                              setOpenDialogDeleteSpecificationVehicle(true);
                              setDataIdEspecficationVehicle(specification);
                            }}
                          >
                            Eliminar
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              dispatch(openModal(true));
                              setIsFormSpecification(true);
                              setIsOperation(2); // 1: create, 2: update
                              setDataIdEspecficationVehicle(specification);
                            }}
                          >
                            Editar
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  )
                )}
              </>
            )}

            <Grid
              item
              xs={12}
              justifyContent="end"
              alignItems="end"
              display="flex"
            >
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/vehiculos");
                }}
              >
                Regresar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Page>

      {/* Formulario Especificaciones VEHICULO */}
      {isOpenModal && isFormSpecification ? (
        <FormSpecificationVehicle
          isOperation={isOperation}
          idSelectVehicle={idVehicle}
          dataIdEspecficationVehicle={dataIdEspecficationVehicle}
          title={`
            ${isOperation === 1
              ? "Crear especificación vehículo"
              : `Editar especificación vehículo ${dataIdEspecficationVehicle?.name}`
            }`}
        />
      ) : null}

      {/* Alerta eliminar ESpecificacion del vehiculo*/}
      <InitDialogAlert
        isLoading={isLoading}
        isOpen={openDialogDeleteSpecificationVehicle}
        setOpen={setOpenDialogDeleteSpecificationVehicle}
        onOk={() => handleDeleteSpecificationVehicle(idVehicle)}
        title={`¿Desea eliminar "${dataIdEspecficationVehicle?.name}"? `}
        type="error"
      />
    </>
  );
};

export default SpecificationsVehicle;
