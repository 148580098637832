import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  model: [],
  models: [],
  trademarks: [],
  categories: [],
  pagination: {
    total: 0,
    currentPage: 0,
    pageSize: 0,
    lastPage: 0,
  },
};

const modelSlice = createSlice({
  name: "model",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Lista de modelos
    dataModelsSuccess(state, action) {
      const pagination = action.payload.meta;
      state.isError = false;
      state.isLoading = false;
      state.models = action.payload.data;
      state.message = "Obtuvimos todos los modelos 🙌";
      // Paginacion
      state.pagination.currentPage = pagination.current_page;
      state.pagination.pageSize = pagination.per_page * 1;
      state.pagination.total = pagination.total;
      state.pagination.lastPage = pagination.last_page;
    },

    // Obtener data del modelo por ID
    getDataIdModelSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.model = action.payload;
      state.message = "Obtuvimos data del modelo 🙌";
    },

    //Crear modelo
    createModelSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Actualizar modelo
    updateModelSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Eliminar modelo
    deleteModelSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    // Table = datos necesarios para el formulario
    getTableModelSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.trademarks = action.payload.trademarks;
      state.categories = action.payload.categories;
    },
  },
});

// Reducer - Aca va la data o los valores
export default modelSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  dataModelsSuccess,
  getDataIdModelSuccess,
  createModelSuccess,
  updateModelSuccess,
  deleteModelSuccess,
  getTableModelSuccess,
} = modelSlice.actions;

// ========Funciones===========

// Data de las modeloes
// -------------------------------------------------------
export function dataModel(query) {
  const { page = 1, per_page = 10, search = "" } = query;
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(
        `models/records?is_paginate=true&per_page=${per_page}&page=${page}&search=${search}`
      );
      dispatch(dataModelsSuccess(response?.data));
    } catch (error) {
      dispatch(hasError(error.response?.data.message));
    }
  };
}

// Obtener dato del modelo por ID
// -------------------------------------------------------
export function getDataIdModel(id) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`/models/record/${id}`);
      dispatch(getDataIdModelSuccess(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error;
    }
  };
}

// Crear modelo
// -------------------------------------------------------
export function createModel(data, query) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.post("/models", data);
      dispatch(createModelSuccess(response.data.message));
      dispatch(dataModel(query)); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Actualizar modelo
// -------------------------------------------------------
export function updateModel(id, data, query) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.put(`/models/${id}`, data);
      dispatch(updateModelSuccess(response.data.message));
      dispatch(dataModel(query)); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Eliminar  modelo
// -------------------------------------------------------
export function deleteModel(id, query) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.delete(`/models/${id}`);
      dispatch(deleteModelSuccess(response.data.message));
      dispatch(dataModel(query)); // actualizamos la lista de modelos
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Table - Datos necesarios para la tabla
// -------------------------------------------------------
export function getTableModel() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get("/models/table");
      dispatch(getTableModelSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error.response.data.message));
    }
  };
}
