import React, { useEffect, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import InitDialog from "../../../../../../components/InitDialog";
import {
  FormProvider,
  RHFTextField,
} from "../../../../../../components/hook-form";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
// Formulario
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  createUser,
  getDataIdUser,
  updateUser,
} from "../../../../../../redux/slices/user";
import { openModal } from "../../../../../../redux/slices/global";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const FormUser = ({ title, idSelectUser, operation }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading } = useSelector((state) => state.user);

  // Formulario
  // valores
  const defaultValues = {
    name: "",
    email: "",
    password: "",
  };

  // Validaciones
  const FormSchema = Yup.object().shape({
    password:
      operation === 1
        ? Yup.string()
          .required("Contraseña es requerido")
          .min(6, "La contraseña debe tener mín. 6 caracteres")
        : Yup.string().nullable(),
    name: Yup.string().required("Nombre es requerido"),
    email: Yup.string()
      .required("Correo electrónico es requerido")
      .email("Correo electrónico no válido"),
  });

  // Obtener los valores del formulario
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  const onSubmit = async (data) => {

    if (operation === 1) {
      //crear nuevo
      const result = await dispatch(createUser(data));
      if (result.status === 201 || result.status === 200) {
        dispatch(openModal(!true));
        enqueueSnackbar(result?.data?.message, { variant: "success" });
      } else {
        enqueueSnackbar(result?.message, { variant: "error" });
      }
    } else {
      // editar
      const result = await dispatch(updateUser(idSelectUser, data));
      if (result.status === 200) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  // Autocompletar datos cuando es editar el usuario
  useEffect(() => {
    if (idSelectUser !== null && operation === 2) {
      const data = async () => {
        const result = await dispatch(getDataIdUser(idSelectUser));
        const data = {
          name: result.name,
          email: result.email,
          password: result.password,
        };
        reset(data);
      };
      data();
    }
  }, [idSelectUser, operation, dispatch, reset]);

  // Label ver o no ver contraseña
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <InitDialog title={title} size="sm" fullWidth loading={isLoading}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Stack sx={{ m: 1 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: "column", md: "column" }} spacing={2}>
                  <RHFTextField name="name" label="Nombre" autoComplete="off" />
                  <RHFTextField
                    name="email"
                    label="Correo electrónico"
                    type="email"
                    autoComplete="off"
                  />

                  <RHFTextField
                    name="password"
                    label="Contraseña"
                    type={showPassword ? "text" : "password"}
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", m: 1, mt: 3 }}
          spacing={2}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ minWidth: 100 }}
            onClick={() => {
              dispatch(openModal(false));
            }}
          >
            Cancelar
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            disabled={!isDirty || isSubmitting}
          >
            Guardar
          </Button>
        </Stack>
      </FormProvider>
    </InitDialog>
  );
};

export default FormUser;
