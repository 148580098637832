import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { Delete, Edit, SearchOutlined } from "@mui/icons-material";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";

import { useSnackbar } from "notistack";
import TableGeneral from "../../../components/InitTables/TableGeneral";
import Page from "../../../components/Page";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  dataPublication,
  deletePublication,
  updatePublication,
} from "../../../redux/slices/publication";
import Label from "../../../components/Label";
import LightboxModal from "../../../components/LightboxModal";
import FormPublication from "./components/FormPublication";
import { openModal } from "../../../redux/slices/global";
import { InitDialogAlert } from "../../../components/InitDialogs";

const SocialNetworks = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [operation, setOperation] = useState(1); // 1 = Crear / 2= Editar
  const [idSelectPublication, setIdSelelectPublication] = useState(null);
  const [isFormPublication, setIsFormPublication] = useState(false);
  const [openDialogDeletePublication, setOpenDialogDeletePublication] =
    useState(false);
  // Imagenes
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [valueSearch, setValueSearch] = useState('')

  const { isOpenModal } = useSelector((state) => state.global);
  const { publications, pagination, isLoading } = useSelector(
    (state) => state.publication
  );
  const { total, currentPage, lastPage } = pagination;
  const { enqueueSnackbar } = useSnackbar();

  // Paginacion
  const handleChangePagination = (event, value) => {
    dispatch(dataPublication({ page: value, search: "" }));
  };

  // Buscador
  const handleChangeSearch = async (event) => {
    event.preventDefault();
    setValueSearch(event.target.value)
    dispatch(
      dataPublication({
        page: 1,
        search: event.target.value,
      })
    );
  };

  // actualizar estado de publicacion
  const handleChangeIsPublishRedSocial = async (event, idRedSocial) => {
    const formData = new FormData();
    formData.append("is_publish", event.target.checked);

    const result = await dispatch(
      updatePublication(idRedSocial, formData, {
        page: currentPage,
        search: valueSearch,
      })
    );

    if (result.status === 200) {
      enqueueSnackbar(result.data.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const tableHeaders = [
    { id: "id", label: "N°" },
    { id: "description", label: "DESCRIPCION" },
    { id: "image_url", label: "IMAGEN - POST", align: "left" },
    { id: "url", label: "URL RED SOCIAL" },
    { id: "is_publish", label: "ESTADO" },
    { id: "actions", label: "ACCIONES" },
  ];

  const DATA_PUBLICATIONS_SOCIAL = publications?.map((item, index) => ({
    id: index + 1,
    image_url: (
      <Avatar
        variant="square"
        src={item.image_url}
        onClick={() => handleOpenLightbox(item.image_url)}
        sx={{ cursor: "zoom-in" }}
      />
    ),
    description: item.description,

    url: (
      <Typography
        variant="body2"
        component="a"
        target="_blank"
        href={item.url}
        sx={{
          color: "text.primary",
        }}
      >
        Abrir enlace red
      </Typography>
    ),
    is_publish: (
      <>
        <Switch
          size="small"
          checked={item.is_publish}
          onChange={(event) => handleChangeIsPublishRedSocial(event, item.id)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={item.is_publish ? "success" : "error"}
        >
          {item.is_publish ? "Publicado" : "No publicado"}
        </Label>
      </>
    ),
    actions: (
      <>
        <IconButton
          color="primary"
          size="small"
          onClick={() => {
            dispatch(openModal(true));
            setIsFormPublication(true);
            setOperation(2); //editar
            setIdSelelectPublication(item.id);
          }}
        >
          <Edit />
        </IconButton>
        <IconButton
          color="error"
          size="small"
          onClick={() => {
            setOpenDialogDeletePublication(true);
            setIdSelelectPublication(item.id);
          }}
        >
          <Delete />
        </IconButton>
      </>
    ),
  }));

  // Para las imagenes
  const imagesLightbox = publications.map((img) => img.image_url);

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  //  Eliminar publicacion
  const handleDeletePublication = async (id) => {
    // validamos para regresas anterior o no
    if (currentPage === lastPage && total % 10 === 1) {
      const result = await dispatch(
        deletePublication(id, {
          page: currentPage - 1,
          search: "",
        })
      );
      if (result.status === 200) {
        setOpenDialogDeletePublication(false);
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      const result = await dispatch(
        deletePublication(id, {
          page: currentPage,
          search: "",
        })
      );
      if (result.status === 200) {
        setOpenDialogDeletePublication(false);
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  // llamamos toda la data
  useEffect(() => {
    dispatch(
      dataPublication({
        page: 1,
        search: "",
      })
    );
  }, [dispatch]);

  return (
    <Page title="Administración: Redes Sociales">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Lista de redes sociales"
          links={[{ name: "Inicio", href: "/" }, { name: "Redes sociales" }]}
          action={
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={() => {
                dispatch(openModal(true));
                setIsFormPublication(true);
                setOperation(1);
                setIdSelelectPublication(null);
                setValueSearch('')
              }}
            >
              Nuevo post red social
            </Button>
          }
        />
        {/* Tabla */}
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: 630,
            justifyContent: "space-between",
          }}
        >
          <Stack>
            <Stack direction="row" justifyContent="flex-end" sx={{ p: 1 }}>
              <TextField
                onChange={handleChangeSearch}
                variant="outlined"
                placeholder="Buscar post red social"
                sx={{ m: 1, width: "40ch" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <TableGeneral
              rows={DATA_PUBLICATIONS_SOCIAL}
              headLabel={tableHeaders}
              isLoading={isLoading}
            />
          </Stack>
          {/* PAGINACION */}
          <Stack
            direction="row"
            sx={{ p: 2, textAlign: "center", justifyContent: "flex-end" }}
          >
            <Typography variant="caption" sx={{ marginTop: "8px" }}>
              TOTAL: {total}
            </Typography>
            <Pagination
              count={lastPage}
              page={currentPage}
              onChange={handleChangePagination}
              showFirstButton
              showLastButton
            />
          </Stack>
          {/* PAGINACION */}
        </Paper>
      </Container>

      {/* Modal crear publicaciones */}
      {isOpenModal && isFormPublication ? (
        <FormPublication
          title={operation === 1 ? "NUEVA PUBLICACIÓN" : "EDITAR  PUBLICACIÓN"}
          operation={operation}
          idSelectPublication={idSelectPublication}
          valueSearch={valueSearch}
        />
      ) : null}

      {/* Alerta eliminar la RED SOCIAL*/}
      <InitDialogAlert
        isOpen={openDialogDeletePublication}
        setOpen={setOpenDialogDeletePublication}
        onOk={() => handleDeletePublication(idSelectPublication)}
        title={`¿Desea eliminar "${publications?.find((item) => item.id === idSelectPublication)
          ?.description
          }"? `}
        type="error"
      />

      {/* Visualizar las imagenes */}
      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </Page>
  );
};

export default SocialNetworks;
