import { Divider, Typography, Stack } from '@mui/material';
import { isNil } from 'lodash';

const TableInformation = ({ data }) => {
  return (
    <>
      {data.map((item, index) => (
        <Stack sx={{ p: 1.5, border: '1px solid #c3c6ca', borderRadius: 2 }} key={index}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack>
              <Typography variant="h3">{item.sectionTitle}</Typography>
              <Typography variant="caption" color="text.secondary">
                {item.description}
              </Typography>
            </Stack>
            {isNil(item.editComponent) ? null : item.editComponent}
          </Stack>

          {item.items.map((i, index) => (
            <div key={index}>
              <Divider sx={{ my: 1 }} />
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  {i.title}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  {i.subtitle}
                </Typography>
              </Stack>
            </div>
          ))}
        </Stack>
      ))}
    </>
  );
};

export default TableInformation;
