import React, { useEffect } from "react";
import { Button, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
// componentes
import InitDialog from "../../../../../../components/InitDialog";
// formularios
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFTextField,
} from "../../../../../../components/hook-form";

// redux
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../../redux/slices/global";
import {
  createCategory,
  getDataIdCategory,
  updateCategory,
} from "../../../../../../redux/slices/category";

const FormCategory = ({ title, operation, idSelectCategory, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.category);

  // valores
  const defaultValues = {
    name: "",
  };

  // Validaciones
  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Nombre de la categoría es requerido"),
  });

  // Obtener los valores del formulario
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  const onSubmit = async (data) => {
    if (operation === 1) {
      //crear nuevo
      const result = await dispatch(createCategory(data));
      if (result.status === 201) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      // editar
      const result = await dispatch(updateCategory(idSelectCategory, data));
      if (result.status === 200) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
        setOpen(null);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  // Autocompletar los campos solo para editar Operation = 2
  useEffect(() => {
    if (operation === 2 && idSelectCategory !== null) {
      const data = async () => {
        const result = await dispatch(getDataIdCategory(idSelectCategory));
        const data = {
          name: result?.name,
        };
        reset(data);
      };
      data();
    }
  }, [dispatch, idSelectCategory, operation, reset]);

  return (
    <InitDialog title={title} size="xs" fullWidth loading={isLoading}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ m: 1 }}>
          <Stack
            direction={{ xs: "column", md: "column" }}
            sx={{ alignItems: "flex-start" }}
            spacing={2}
          >
            <RHFTextField
              name="name"
              label="Nombre de la categoría"
              autoComplete="off"
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", mx: 1, mt: 2 }}
          spacing={2}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ minWidth: 100 }}
            onClick={() => {
              dispatch(openModal(false));
            }}
          >
            Cancelar
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            disabled={!isDirty || isSubmitting}
          >
            Guardar
          </Button>
        </Stack>
      </FormProvider>
    </InitDialog>
  );
};

export default FormCategory;
