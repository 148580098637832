import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelect2.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default function RHFSelect2({ name, children, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth size="small">
          <InputLabel id="demo-select-small">{other.label}</InputLabel>
          <Select labelId="demo-select-small" id="demo-select-small" {...field} fullWidth error={!!error} {...other}>
            {children}
          </Select>
          {!!error && (
            <FormHelperText error sx={{ px: 0, fontSize: 10 }}>
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
