import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  publication: [],
  publications: [],
  pagination: {
    total: 0,
    currentPage: 0,
    pageSize: 0,
    lastPage: 0,
  },
};

const publicationSlice = createSlice({
  name: "publication",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Lista de publications
    dataPublicationSuccess(state, action) {
      const pagination = action.payload.meta;
      state.isError = false;
      state.isLoading = false;
      state.publications = action.payload.data;
      state.message = "Obtuvimos todos los publications 🙌";
      // Paginacion
      state.pagination.currentPage = pagination.current_page;
      state.pagination.pageSize = pagination.per_page * 1;
      state.pagination.total = pagination.total;
      state.pagination.lastPage = pagination.last_page;
    },

    // Obtener data del sliders por ID
    getDataIdPublicationSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.publication = action.payload;
      state.message = "Obtuvimos data del slayer 🙌";
    },

    //Crear slayer
    createPublicationSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Actualizar slayer
    updatePublicationSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Eliminar slayer
    deletePublicationSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },
  },
});

// Reducer - Aca va la data o los valores
export default publicationSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  dataPublicationSuccess,
  getDataIdPublicationSuccess,
  createPublicationSuccess,
  updatePublicationSuccess,
  deletePublicationSuccess,
} = publicationSlice.actions;

// ========Funciones===========

// Data de las publications
// -------------------------------------------------------
export function dataPublication(query) {
  const { page = 1, per_page = 10, search = "" } = query;
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      // const response = await AppApiToken.get(
      //   `publications/records?is_paginate=true&per_page=12&page=1&search=`
      // );
      const response = await AppApiToken.get(
        `publications/records?is_paginate=true&per_page=${per_page}&page=${page}&search=${search}`
      );
      dispatch(dataPublicationSuccess(response?.data));
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}

// Obtener dato de slayer por ID
// -------------------------------------------------------
export function getDataIdPublication(id) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`/publications/record/${id}`);
      dispatch(getDataIdPublicationSuccess(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error;
    }
  };
}

// Crear slayer
// -------------------------------------------------------
export function createPublication(data, query) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.post("/publications", data);
      dispatch(createPublicationSuccess(response.data.message));
      dispatch(dataPublication(query)); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Actualizar slayer
// -------------------------------------------------------
export function updatePublication(id, data, query) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.put(`/publications/${id}`, data);
      dispatch(updatePublicationSuccess(response.data.message));
      dispatch(dataPublication(query)); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Eliminar  slayer
// -------------------------------------------------------
export function deletePublication(id, query) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.delete(`/publications/${id}`);
      dispatch(deletePublicationSuccess(response.data.message));
      dispatch(dataPublication(query)); // actualizamos la lista de publications
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}
