import { Avatar, Box, Stack, Typography, useTheme, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const NavbarAccount = ({ open, isMobile }) => {
  const theme = useTheme();

  return (
    <Link underline="none" color="inherit" component={RouterLink} to={"/"}>
      {isMobile ? (
        <Stack
          component="div"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 4,
            backgroundColor: theme.palette.background.neutral,
            px: 2,
            py: 2,
            mx: { xs: 1, sm: 1.5, md: 1.6 },
            my: open ? 2 : 2,
          }}
        >
          <Avatar alt="avantar" src="/assets/resources/avatar.png" />

          <Box sx={{ ml: 2 }}>
            <Typography
              variant="subtitle2"
              noWrap
              sx={{ width: 156, textTransform: "uppercase" }}
            >
              ADMINISTRADOR
            </Typography>
            <Typography
              variant="body2"
              noWrap
              sx={{ color: "text.secondary", textTransform: "capitalize" }}
            >
              Admin
            </Typography>
          </Box>
        </Stack>
      ) : (
        <Stack
          component="div"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 4,
            backgroundColor: open ? theme.palette.background.neutral : "none",
            px: open ? 2 : 0,
            py: 2,
            mx: { xs: 1, sm: 1.5, md: 1.6 },
            my: open ? 2 : 0,
          }}
        >
          <Avatar alt="avantar" src="/assets/resources/avatar.png" />
          {open && (
            <Box sx={{ ml: 2 }}>
              <Typography
                variant="subtitle2"
                noWrap
                sx={{ width: 156, textTransform: "uppercase" }}
              >
                ADMINISTRADOR
              </Typography>
              <Typography
                variant="body2"
                noWrap
                sx={{ color: "text.secondary", textTransform: "capitalize" }}
              >
                Admin
              </Typography>
            </Box>
          )}
        </Stack>
      )}
    </Link>
  );
};

export default NavbarAccount;
