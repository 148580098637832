import React, { useEffect, useState } from "react";
import { Button, Divider, IconButton, MenuItem, Stack } from "@mui/material";
import {
  Add,
  DeleteForever,
  EditOutlined,
  MoreVertOutlined,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { InitDialogAlert } from "../../../../../components/InitDialogs";
import { TableGeneral } from "../../../../../components/InitTables";
import MenuPopover from "../../../../../components/MenuPopover";
import FormCategoy from "./FormDialog/FormCategoy";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  dataCategories,
  deleteCategory,
} from "../../../../../redux/slices/category";
import { openModal } from "../../../../../redux/slices/global";

const CategoryTab = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isOpenModal } = useSelector((state) => state.global);
  const { categories } = useSelector((state) => state.category);

  const [open, setOpen] = useState(null);
  const [isFormCategoy, setIsFormCategoy] = useState(false);
  const [operation, setOperation] = useState(1); // 1 = Crear / 2= Editar
  const [openDialogDeleteCategory, setOpenDialogDeleteCategory] =
    useState(false);
  const [idSelectCategory, setIdSelectCategory] = useState(null);

  const handleOpenMenuPop = (event) => {
    setOpen(event.currentTarget);
  };

  const handleDeleteCategory = async (id) => {
    if (id !== "") {
      const result = await dispatch(deleteCategory(id));
      if (result.status === 200) {
        setOpenDialogDeleteCategory(false);
        enqueueSnackbar(result.data.message, { variant: "success" });
        setOpen(null);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const tableHeaders = [
    { id: "id", label: "N°" },
    { id: "name", label: "CATEGORÍA", align: "left" },
    { id: "actions", label: "ACCIONES", align: "right" },
  ];

  const DATA_TRADEMARK = categories?.map((item, index) => ({
    id: index + 1,
    name: item.name,
    actions: (
      <>
        <IconButton
          onClick={(e) => {
            handleOpenMenuPop(e);
            setIdSelectCategory(item.id);
          }}
        >
          <MoreVertOutlined />
        </IconButton>

        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={() => setOpen(null)}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          arrow="right-top"
          sx={{
            boxShadow: "none",
            borderRadius: 2,
            mt: -1,
            width: 160,
            background: (theme) => theme.palette.background.paper,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
              "& svg": { mr: 2, width: 20, height: 20 },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              dispatch(openModal(true));
              setIsFormCategoy(true);
              setOperation(2);
            }}
          >
            <EditOutlined />
            Editar
          </MenuItem>

          <Divider sx={{ borderStyle: "dashed" }} />

          <MenuItem
            onClick={() => {
              setOpenDialogDeleteCategory(true);
            }}
            sx={{ color: "error.main" }}
          >
            <DeleteForever />
            Eliminar
          </MenuItem>
        </MenuPopover>
      </>
    ),
  }));

  useEffect(() => {
    dispatch(dataCategories());
  }, [dispatch]);

  return (
    <>
      <Stack alignItems="end" sx={{ mt: -2, mb: 1 }}>
        <Button
          variant="contained"
          startIcon={<Add />}
          sx={{ textTransform: "none" }}
          onClick={() => {
            dispatch(openModal(true));
            setIsFormCategoy(true);
            setOperation(1);
            setIdSelectCategory(null);
          }}
        >
          Nueva categoría
        </Button>
      </Stack>

      <TableGeneral rows={DATA_TRADEMARK} headLabel={tableHeaders} />

      {isOpenModal && isFormCategoy ? (
        <FormCategoy
          title={operation === 1 ? "NUEVA  CATEGORÍA" : "EDITAR  CATEGORÍA"}
          operation={operation}
          idSelectCategory={idSelectCategory}
          setOpen={setOpen}
        />
      ) : null}

      {/* Alerta eliminar la CATEGORÍA */}
      <InitDialogAlert
        isOpen={openDialogDeleteCategory}
        setOpen={setOpenDialogDeleteCategory}
        onOk={() => handleDeleteCategory(idSelectCategory)}
        title={`¿Desea eliminar "${categories?.find((item) => item.id === idSelectCategory)?.name
          }"? `}
        type="error"
      />
    </>
  );
};

export default CategoryTab;
