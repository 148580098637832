import React, { useEffect, useCallback } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
// componentes
import InitDialog from "../../../../../components/InitDialog";
// formularios
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
  RHFUploadSingleFile,
} from "../../../../../components/hook-form";

// redux
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../redux/slices/global";
import {
  createSlayer,
  getDataIdSlayer,
  updateSlayer,
} from "../../../../../redux/slices/slayer";
import { fData } from "../../../../../utils/formatNumber";

const FormBanner = ({ title, operation, idSelectBanner }) => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { isLoading, pagination } = useSelector((state) => state.slayer);
  const { currentPage } = pagination;
  // valores
  const defaultValues = {
    image: "",
    image_responsive: "",
    url: "",
    is_publish: false,
  };

  // TODO: VALIDAR LOS TIPOS DE IMAGNES QUE QUIERE EL CLIENTE
  // Validaciones
  const FormSchema = Yup.object().shape({
    // validar solo varios tipos de imagenes es importante que sea MIME TYPE y no solo extension de archivo y que sea un array con yup mixed
    image: Yup.mixed().required("Por favor seleccione una imagen."),
    url: Yup.string().url("Por favor ingrese una URL válida.").nullable(),
    image_responsive: Yup.mixed().required("Por favor seleccione una imagen."),
  });

  // Obtener los valores del formulario
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    // Enviamos la data como FormData para poder enviar el archivo
    if (operation === 1) {
      const newFormData = new FormData();
      newFormData.append("image", data.image);
      newFormData.append("image_responsive", data.image_responsive);
      newFormData.append("is_publish", data.is_publish);
      newFormData.append("url", data.url);
      //crear nuevo
      const result = await dispatch(
        createSlayer(newFormData, {
          page: currentPage,
          search: "",
        })
      );
      if (result.status === 201) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(
          "Error al cargar imagen - revisar el formato aceptado",
          {
            variant: "error",
          }
        );
      }
    } else {
      const updateFormData = new FormData();
      updateFormData.append("image", data.image);
      updateFormData.append("image_responsive", data.image_responsive);
      updateFormData.append("is_publish", data.is_publish);
      updateFormData.append("url", data.url);
      // editar
      const result = await dispatch(
        updateSlayer(idSelectBanner, updateFormData, {
          page: currentPage,
          search: "",
        })
      );
      if (result.status === 200) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue("image", file);

        // Quitamos URL.createObjectURL(file) para que podamos tener en formato FILE y no en formato BLOB para poder enviarlo al backend
        // Pero para poder previsualizar modificamos en el componente RHFUploadSingleFile
        // setValue(
        //   "cover",
        //   Object.assign(file, {
        //     preview: URL.createObjectURL(file),
        //   })
        // );
      }
    },
    [setValue]
  );

  const handleDropResponsive = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue("image_responsive", file);

        // Quitamos URL.createObjectURL(file) para que podamos tener en formato FILE y no en formato BLOB para poder enviarlo al backend
        // Pero para poder previsualizar modificamos en el componente RHFUploadSingleFile
        // setValue(
        //   "cover",
        //   Object.assign(file, {
        //     preview: URL.createObjectURL(file),
        //   })
        // );
      }
    },
    [setValue]
  );

  // Autocompletar los campos solo para editar Operation = 2
  useEffect(() => {
    if (operation === 2 && idSelectBanner !== null) {
      const data = async () => {
        const result = await dispatch(getDataIdSlayer(idSelectBanner));
        const data = {
          image: result?.path,
          image_responsive: result?.path_responsive,
          is_publish: result?.is_publish,
          url: result?.url,
        };
        reset(data);
      };
      data();
    }
  }, [dispatch, idSelectBanner, operation, reset]);

  return (
    <InitDialog title={title} size="md" fullWidth loading={isLoading}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ m: { xs: 0, sm: 1 } }} spacing={2}>
          <Stack gap={2} sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
            <RHFTextField name="url" label="URL" />
            <RHFCheckbox name="is_publish" label="Publicar" />
          </Stack>
          <div>
            <Typography variant="caption" sx={{ ml: 1 }}>
              Tamaño recomendando WxH: 1600 x 600 Máximo {fData(1024000)}
            </Typography>
            <RHFUploadSingleFile
              disabled={isLoading}
              name="image"
              accept={{
                "image/*": [".jpeg", ".png", ".jpg", ".svg", ".webp"],
              }}
              maxSize={1024000} // 1MB
              onDrop={handleDrop}
            />
          </div>
          <br />
          <Typography variant="subtitle1">
            Banner Responsivo
            <Typography variant="caption" sx={{ ml: 1 }}>
              Tamaño recomendando WxH: 440 x 330, Máximo {fData(1024000)}
            </Typography>
          </Typography>
          <div>
            <RHFUploadSingleFile
              disabled={isLoading}
              name="image_responsive"
              accept={{
                "image/*": [".jpeg", ".png", ".jpg", ".svg", ".webp"],
              }}
              maxSize={1024000} // 1MB
              onDrop={handleDropResponsive}
            />
          </div>
        </Stack>
        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", mx: 1, mt: 2 }}
          spacing={2}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ minWidth: 100 }}
            onClick={() => {
              dispatch(openModal(false));
            }}
          >
            Cancelar
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            disabled={isSubmitting || isLoading}
          >
            Guardar
          </Button>
        </Stack>
      </FormProvider>
    </InitDialog>
  );
};

export default FormBanner;