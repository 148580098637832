import React from "react";
import { Box, Toolbar, IconButton, Stack, useTheme } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import AccountPopover from "./AccountPopover";
// import NotificationsPopover from "./NotificationsPopover";

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isMobile",
})(({ theme, open, isMobile }) => ({
  zIndex: isMobile ? theme.zIndex.drawer : theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: isMobile ? `calc(100% - 0px)` : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBarHeader = ({
  open,
  handleDrawerOpen,
  handleDrawerOpenMobile,
  isMobile,
}) => {
  const theme = useTheme();
  return (
    <AppBar position="fixed" open={open} isMobile={isMobile}>
      <Toolbar>
        {!isMobile && (
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ml: 5, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        )}

        {isMobile && (
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpenMobile}
            edge="start"
            sx={{ ml: 1 }}
          >
            <MenuIcon />
          </IconButton>
        )}

        {!open && (
          <Box
            component="img"
            src={
              theme.palette.mode === "dark"
                ? "/assets/logo/logo-dark.svg"
                : "/assets/logo/logo-white.svg"
            }
            sx={{ width: 140, height: "100%" }}
          />
        )}

        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={{ xs: 0.5, sm: 3 }}
        >
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarHeader;
