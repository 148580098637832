import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  vehicle: [],
  vehicles: [],
  models: [],
  searchVehicle: '',
  pagination: {
    total: 0,
    currentPage: 0,
    pageSize: 0,
    lastPage: 0,
  },
};

const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Lista de vehicles
    dataVehiclesSuccess(state, action) {
      const pagination = action.payload.meta;
      state.isError = false;
      state.isLoading = false;
      state.vehicles = action.payload.data;
      state.message = "Obtuvimos todos los vehiculos 🚗";
      // Paginacion
      state.pagination.currentPage = pagination.current_page;
      state.pagination.pageSize = pagination.per_page * 1;
      state.pagination.total = pagination.total;
      state.pagination.lastPage = pagination.last_page;
    },

    // Obtener data del modelo por ID
    getDataIdVehicleSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.vehicle = action.payload;
      state.message = "Obtuvimos data del vehiculo 🚗";
    },

    //Crear modelo
    createVehicleSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Actualizar modelo
    updateVehicleSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Eliminar modelo
    deleteVehicleSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    // Table = datos necesarios para el formulario
    getTableVehicleSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.models = action.payload.models;
    },

    // Buscador
    setSearchVehicle(state, action) {
      state.searchVehicle = action.payload;
    }
  },
});

// Reducer - Aca va la data o los valores
export default vehicleSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  dataVehiclesSuccess,
  getDataIdVehicleSuccess,
  createVehicleSuccess,
  updateVehicleSuccess,
  deleteVehicleSuccess,
  getTableVehicleSuccess,
  setSearchVehicle
} = vehicleSlice.actions;

// ========Funciones===========

// Data de las vehiculos
// -------------------------------------------------------
export function dataVehicle(query) {
  const { page = 1, per_page = 10, search = "" } = query;
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(
        `vehicles/records?is_paginate=true&per_page=${per_page}&page=${page}&search=${search}`
      );
      dispatch(dataVehiclesSuccess(response?.data));
      dispatch(setSearchVehicle(search));
    } catch (error) {
      dispatch(hasError(error.response?.data.message));
    }
  };
}

// Obtener dato del vehiculo por ID
// -------------------------------------------------------
export function getDataIdVehicle(id) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`/vehicles/record/${id}`);
      dispatch(getDataIdVehicleSuccess(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error;
    }
  };
}

// Crear vehiculo
// -------------------------------------------------------
export function createVehicle(data, query) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.post("/vehicles", data);
      dispatch(createVehicleSuccess(response.data.message));
      dispatch(dataVehicle(query)); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response;
    }
  };
}

// Actualizar vehiculo
// -------------------------------------------------------
export function updateVehicle(id, data, query) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.put(`/vehicles/${id}`, data);
      dispatch(updateVehicleSuccess(response.data.message));
      dispatch(dataVehicle(query)); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response;
    }
  };
}

// Eliminar  vehiculo
// -------------------------------------------------------
export function deleteVehicle(id, query) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.delete(`/vehicles/${id}`);
      dispatch(deleteVehicleSuccess(response.data.message));
      dispatch(dataVehicle(query)); // actualizamos la lista de modelos
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Table - Datos necesarios para la tabla
// -------------------------------------------------------
export function getTableVehicle(modelId) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`/vehicles/table?model_id=${modelId}`);
      dispatch(getTableVehicleSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error.response.data.message));
    }
  };
}