import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Link, Typography, CardActionArea, Divider, Card, Stack } from '@mui/material';
//
import { varFadeInUp, MotionInView } from './animate2';

// ----------------------------------------------------------------------

CardConfig.propTypes = {
  item: PropTypes.object.isRequired,
};

export default function CardConfig({ item }) {
  const { name, icon, href, description } = item;

  return (
    <MotionInView variants={varFadeInUp}>
      <Link component={RouterLink} to={href} underline="none">
        <Card
          sx={{
            p: 1,
            display: 'flex',
            direction: 'row',
            minWidth: '100%',
            minHeight: 160,
            boxShadow: (theme) => theme.customShadows.z8,
            '&:hover img': { transform: 'scale(1.1)' },
          }}
        >
          <CardActionArea
            sx={{
              borderRadius: 1,
              color: 'primary.main',
              bgcolor: 'background.neutral',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              src={icon}
              alt={name}
              sx={{
                height: 40,
                width: 40,
                mx: 'auto',
                transition: (theme) => theme.transitions.create('all'),
              }}
            />
          </CardActionArea>
          <Stack>
            <Typography variant="subtitle1" sx={{ mt: 1, p: 1 }}>
              {name}
            </Typography>
            <Divider sx={{ mt: -1, mb: 1 }} />
            <Typography variant="caption" sx={{ mt: -1, p: 1, color: 'text.secondary' }}>
              {description}
            </Typography>
          </Stack>
        </Card>
      </Link>
    </MotionInView>
  );
}
