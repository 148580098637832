import React, { useState } from "react";
import {
  DeleteForever,
  EditOutlined,
  EmailOutlined,
  HourglassEmptyRounded,
  MoreVertOutlined,
  OnlinePrediction,
  PhoneAndroidOutlined,
  PlaceOutlined,
  WorkspacePremium,
} from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import MenuPopover from "../../../../../components/MenuPopover";
import { useDispatch } from "react-redux";
import { InitDialogAlert } from "../../../../../components/InitDialogs";
import { useSnackbar } from "notistack";
import { deleteEstablishment } from "../../../../../redux/slices/establishment";
import { openModal } from "../../../../../redux/slices/global";

const CardEstablisment = ({
  dataEstablishment,
  setOperationEstablishment,
  setIdSelectEstablishment,
  setIsFormEstablishment,
  setIsFormAddTrademark,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const [openDialogDeleteEstablishment, setOpenDialogDeleteEstablishment] =
    useState(false);

  const handleOpenMenuPop = (event) => {
    setOpen(event.currentTarget);
  };

  // Eliminar el establecimiento
  const handleDeleteEstablishment = async (id) => {
    if (id !== null) {
      const result = await dispatch(deleteEstablishment(id));
      if (result.status === 200) {
        setOpenDialogDeleteEstablishment(false);
        enqueueSnackbar(result.data.message, { variant: "success" });
        setOpen(null);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setOpen(null);
      }
    }
  };

  return (
    <>
      <Card
        sx={{
          py: 2,
          display: "flex",
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          borderRadius: 3,
        }}
      >
        <Avatar
          alt="foto-sucursal"
          src={
            dataEstablishment.is_priority
              ? "/assets/resources/principal.png"
              : "/assets/resources/car.png"
          }
          sx={{ width: 64, height: 64 }}
        />

        <CardContent sx={{ m: 1, minHeight: 100 }}>
          <Typography sx={{ textAlign: "center", fontWeight: 600 }}>
            {dataEstablishment.description}

            {dataEstablishment.is_priority && (
              <Typography
                variant="caption"
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: 10,
                  ml: 1,
                }}
              >
                (Principal)
              </Typography>
            )}
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            sx={{ mt: 0.5, color: "text.secondary" }}
          >
            <OnlinePrediction
              fontSize="small"
              sx={{ width: 16, height: 16 }}
            />
            <Typography variant="caption" sx={{ ml: 0.5, mr: 1 }}>
              ¿Publicado?:   {dataEstablishment.is_publish ? 'Si' : 'No'}
            </Typography>
          </Stack>


          <Stack
            direction="row"
            alignItems="center"
            sx={{ mt: 0.5, color: "text.secondary" }}
          >
            <PhoneAndroidOutlined
              fontSize="small"
              sx={{ width: 16, height: 16 }}
            />
            <Typography variant="caption" sx={{ ml: 0.5, mr: 1 }}>
              {dataEstablishment.telephone_info}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            sx={{ mt: 0.5, color: "text.secondary" }}
          >
            <EmailOutlined fontSize="small" sx={{ width: 16, height: 16 }} />
            <Typography variant="caption" sx={{ ml: 0.5, mr: 1 }}>
              {dataEstablishment.email
                ? dataEstablishment.email
                : "No tiene correo"}
            </Typography>
          </Stack>

          {dataEstablishment.addresses.map((address, i) => (
            <Stack
              key={i}
              direction="row"
              alignItems="center"
              sx={{ mt: 0.5, color: "text.secondary" }}
            >
              <PlaceOutlined fontSize="small" sx={{ width: 16, height: 16 }} />
              <Typography variant="caption" sx={{ ml: 0.5, mr: 1 }}>
                {address.description}
              </Typography>
            </Stack>
          ))}

          <Divider>
            <Typography variant="caption">VENTA</Typography>
          </Divider>

          {dataEstablishment?.sale?.business_hours.map((hours, i) => (
            <Stack
              key={i}
              direction="row"
              alignItems="center"
              sx={{ mt: 0.5, color: "text.secondary" }}
            >
              <HourglassEmptyRounded
                fontSize="small"
                sx={{ width: 16, height: 16 }}
              />
              <Typography variant="caption" sx={{ ml: 0.5, mr: 1 }}>
                {hours}
              </Typography>
            </Stack>
          ))}

          {dataEstablishment?.sale?.telephones.map((telephone, i) => (
            <Stack
              key={i}
              direction="row"
              alignItems="center"
              sx={{ mt: 0.5, color: "text.secondary" }}
            >
              <PhoneAndroidOutlined
                fontSize="small"
                sx={{ width: 16, height: 16 }}
              />
              <Typography variant="caption" sx={{ ml: 0.5, mr: 1 }}>
                {telephone}
              </Typography>
            </Stack>
          ))}

          <Divider>
            <Typography variant="caption">POSTVENTA</Typography>
          </Divider>

          {dataEstablishment?.post_sale?.business_hours.map((hours, i) => (
            <Stack
              key={i}
              direction="row"
              alignItems="center"
              sx={{ mt: 0.5, color: "text.secondary" }}
            >
              <HourglassEmptyRounded
                fontSize="small"
                sx={{ width: 16, height: 16 }}
              />
              <Typography variant="caption" sx={{ ml: 0.5, mr: 1 }}>
                {hours}
              </Typography>
            </Stack>
          ))}

          {dataEstablishment?.post_sale?.telephones.map((telephone, i) => (
            <Stack
              key={i}
              direction="row"
              alignItems="center"
              sx={{ mt: 0.5, color: "text.secondary" }}
            >
              <PhoneAndroidOutlined
                fontSize="small"
                sx={{ width: 16, height: 16 }}
              />
              <Typography variant="caption" sx={{ ml: 0.5, mr: 1 }}>
                {telephone}
              </Typography>
            </Stack>
          ))}
        </CardContent>

        {/* Boton */}
        <IconButton
          onClick={(e) => {
            handleOpenMenuPop(e);
            // setIdSelectEstablishment(dataEstablishment.id);
          }}
          sx={{
            top: 8,
            right: 8,
            position: "absolute",
          }}
        >
          <MoreVertOutlined />
        </IconButton>

        {/* menu */}
        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={() => setOpen(null)}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          sx={{
            boxShadow: "none",
            borderRadius: 2,
            mt: 1,
            width: 160,
            background: theme.palette.background.paper,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
              "& svg": { mr: 2, width: 20, height: 20 },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              dispatch(openModal(true));
              setOperationEstablishment(2);
              setIdSelectEstablishment(dataEstablishment.id);
              setIsFormEstablishment(true);
              setIsFormAddTrademark(false);
            }}
          >
            <EditOutlined />
            Editar
          </MenuItem>

          <MenuItem
            onClick={() => {
              dispatch(openModal(true));
              setOperationEstablishment(null);
              setIdSelectEstablishment(dataEstablishment.id);
              setIsFormAddTrademark(true);
              setIsFormEstablishment(false);
            }}
          >
            <WorkspacePremium />
            Añadir marca
          </MenuItem>

          <Divider sx={{ borderStyle: "dashed" }} />

          <MenuItem
            onClick={() => {
              setOpenDialogDeleteEstablishment(true);
              setIsFormEstablishment(false);
            }}
            sx={{ color: "error.main" }}
          >
            <DeleteForever />
            Eliminar
          </MenuItem>
        </MenuPopover>
      </Card>

      {/* Alerta eliminar establecimiento */}
      <InitDialogAlert
        isOpen={openDialogDeleteEstablishment}
        setOpen={setOpenDialogDeleteEstablishment}
        onOk={() => handleDeleteEstablishment(dataEstablishment.id)}
        title={`¿Desea eliminar la sucursal '${dataEstablishment.description}'? `}
        type="error"
      />
    </>
  );
};

export default CardEstablisment;
