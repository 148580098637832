import React, { forwardRef } from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  ListSubheader,
  useTheme,
  Divider,
  Tooltip,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { NavBarRoutes } from "./NavBarRoutes";

const MyNavLink = forwardRef((props, ref) => (
  <NavLink
    ref={ref}
    to={props.to}
    className={({ isActive }) =>
      `${props.className} ${isActive ? props.activeClassName : ""}`
    }
  >
    {props.children}
  </NavLink>
));

const NavBarSettings = ({ open, isMobile, handleDrawerCloseMobile }) => {
  const theme = useTheme();
  const location = useLocation();
  return (
    <>
      {isMobile ? (
        <Box>
          <List disablePadding sx={{ px: 2, display: "block", mb: 2 }}>
            {NavBarRoutes.map((item, index) => (
              <div key={index}>
                <ListSubheader
                  sx={{
                    color: "text.primary",
                    fontWeight: 600,
                    fontSize: 12,
                    textTransform: "uppercase",
                    backgroundColor:
                      theme.palette.mode === "dark" ? "#2b2b2b" : "#ffffff",
                  }}
                >
                  {item.section}
                </ListSubheader>

                {item.items.map((item, index) => (
                  <ListItem
                    disablePadding
                    component={MyNavLink}
                    sx={{
                      color: "#8C8C8C",
                      display: "block",
                      borderRadius: 2,
                      "& .Mui-selected": {
                        backgroundColor: "rgb(0 171 85 / 10%)",
                        borderLeft: "2px solid",
                        borderColor: theme.palette.primary.main,
                        "& .MuiSvgIcon-root": {
                          fontSize: 22,
                          color: theme.palette.primary.main,
                          // stroke: theme.palette.primary.main,
                          // fill: theme.palette.primary.main,
                        },
                        "& .MuiTypography-root": {
                          fontWeight: 600,
                          color: theme.palette.primary.main,
                        },
                      },
                    }}
                    to={item.route}
                    key={index}
                  >
                    <ListItemButton
                      onClick={handleDrawerCloseMobile}
                      selected={location.pathname.includes(item.route)}
                      sx={{
                        borderRadius: open ? 2 : 0,
                        minHeight: 42,
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color: "#8C8C8C",
                          minWidth: 0,
                          mr: open ? 2 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title}
                        primaryTypographyProps={{
                          // fontSize: 12, // Tamaño de letra del navbar
                          fontWeight: 500,
                          variant: "body2",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </div>
            ))}
          </List>
        </Box>
      ) : (
        <Box>
          <List
            disablePadding
            sx={{ px: open ? 2 : 0, display: "block", mb: 2 }}
          >
            {NavBarRoutes.map((item, index) => (
              <div key={index}>
                {open ? (
                  <ListSubheader
                    sx={{
                      color: "text.primary",
                      fontWeight: 600,
                      fontSize: 12,
                      textTransform: "uppercase",
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    {item.section}
                  </ListSubheader>
                ) : (
                  <Divider sx={{ my: 1 }} variant="middle" />
                )}

                {item.items.map((item, index) => (
                  <ListItem
                    disablePadding
                    component={MyNavLink}
                    sx={{
                      color: "#8C8C8C",
                      display: "block",
                      borderRadius: open ? 2 : 0,
                      "& .Mui-selected": {
                        backgroundColor: "rgb(0 171 85 / 10%)",
                        borderLeft: "2px solid",
                        borderColor: theme.palette.primary.main,
                        "& .MuiSvgIcon-root": {
                          fontSize: 22,
                          color: theme.palette.primary.main,
                          // stroke: theme.palette.primary.main,
                          // fill: theme.palette.primary.main,
                        },
                        "& .MuiTypography-root": {
                          fontWeight: 600,
                          color: theme.palette.primary.main,
                        },
                      },
                    }}
                    to={item.route}
                    key={index}
                  // activeClassName={classes.activeLink}
                  >
                    <Tooltip title={open ? null : item.title} placement="right">
                      <ListItemButton
                        selected={location.pathname.includes(item.route)}
                        sx={{
                          borderRadius: open ? 2 : 0,
                          minHeight: 42,
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            color: "#8C8C8C",
                            minWidth: 0,
                            mr: open ? 2 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.title}
                          sx={{ opacity: open ? 1 : 0 }}
                          primaryTypographyProps={{
                            // fontSize: 12, // Tamaño de letra del navbar
                            fontWeight: 500,
                            variant: "body2",
                          }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                ))}
              </div>
            ))}
          </List>
        </Box>
      )}
    </>
  );
};

export default NavBarSettings;
