import { useEffect, useState, useCallback } from "react";
import {
  Button,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { debounce } from 'lodash'
import {
  DashboardCustomizeRounded,
  DeleteForever,
  EditOutlined,
  MoreVertOutlined,
  PictureAsPdfRounded,
  PixRounded,
  SearchOutlined,
} from "@mui/icons-material";
// Componets
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import TableGeneral from "../../../components/InitTables/TableGeneral";
import MenuPopover from "../../../components/MenuPopover";
import Label from "../../../components/Label";
import FormVehicle from "./components/FormDialog/FormVehicle";
import Page from "../../../components/Page";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../redux/slices/global";
import { InitDialogAlert } from "../../../components/InitDialogs";
import {
  dataVehicle,
  deleteVehicle,
  updateVehicle,
} from "../../../redux/slices/vehicle";

// Components
import FormFichaTecnicaVehicle from "./components/FormDialog/FormFichaTecnicaVehicle";

// utilidades
import { fCurrencyPEN, fCurrencyUSD } from "../../../utils/formatNumber";

const Vehicles = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { isOpenModal } = useSelector((state) => state.global);
  const { vehicles, searchVehicle, pagination, isLoading } = useSelector(
    (state) => state.vehicle
  );
  const { total, currentPage, lastPage } = pagination;
  const [operation, setOperation] = useState(1); // 1 = Crear / 2= Editar
  const [idSelectVehicle, setIdSelectVehicle] = useState(null);
  const [valueSearch, setValueSearch] = useState(searchVehicle)
  // Formularios para abrir y cerrar
  const [isFormVehicle, setIsFormVehicle] = useState(false);
  const [isFormFooterFicha, setIsFormFooterFicha] = useState(false);
  const [openDialogDeleteVehicle, setOpenDialogDeleteVehicle] = useState(false);


  const handleOpenMenuPop = (event) => {
    setOpen(event.currentTarget);
  };

  // Paginacion
  const handleChangePagination = (event, value) => {
    dispatch(dataVehicle({ page: value, search: searchVehicle }));
  };

  // Crear una función debounced que ejecutará dataVehicle después de 500 milisegundos
  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedSearch = useCallback(
    debounce((searchText) => {
      dispatch(
        dataVehicle({
          page: 1,
          search: searchText,
        })
      );
    }, 500),
    [dispatch]
  );
  /* eslint-disable react-hooks/exhaustive-deps */

  const handleChangeSearch = (event) => {
    event.preventDefault();
    const searchText = event.target.value;
    setValueSearch(searchText);
    // Llamar a la función debounced con el texto de búsqueda
    debouncedSearch(searchText);
  };

  const handleChangeIsPublish = async (event, idVehicle) => {
    const formData = new FormData();
    formData.append("is_publish", event.target.checked);

    const result = await dispatch(
      updateVehicle(idVehicle, formData, {
        page: currentPage,
        search: valueSearch,
      })
    );

    if (result.status === 200) {
      enqueueSnackbar(result.data.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const tableHeaders = [
    { id: "id", label: "N°" },
    { id: "trademark", label: "Marca" },
    { id: "model", label: "Modelo" },
    { id: "name", label: "Nombre", align: "left" },
    { id: "price_usd", label: "Precio USD", align: "left" },
    { id: "price_pen", label: "Precio PEN", align: "left" },
    { id: "is_offer", label: "Oferta", align: "left" },

    { id: "is_publish", label: "Publicado", align: "left" },
    { id: "actions", label: "ACCIONES", align: "right" },
  ];

  const DATA_CLIENTS = vehicles?.map((item, index) => ({
    id: index + 1,
    trademark: item.trademark?.name,
    model: item.model?.name,
    name: item.name,
    is_offer: (
      <Label
        variant={theme.palette.mode === "light" ? "ghost" : "filled"}
        color={item.is_offer ? "success" : "error"}
      >
        {item.is_offer ? `Si - ${item?.percentage_offer}%` : "No"}
      </Label>
    ),
    price_usd: `${fCurrencyUSD(item.price_usd)}`,
    price_pen: `S/. ${fCurrencyPEN(item.price_pen)}`,
    is_publish: (
      <>
        <Switch
          size="small"
          checked={item.is_publish}
          onChange={(event) => handleChangeIsPublish(event, item.id)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={item.is_publish ? "success" : "error"}
        >
          {item.is_publish ? "Publicado" : "No publicado"}
        </Label>
      </>
    ),
    actions: (
      <>
        <IconButton
          onClick={(e) => {
            handleOpenMenuPop(e);
            setIdSelectVehicle(item.id);
          }}
        >
          <MoreVertOutlined />
        </IconButton>

        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={() => setOpen(null)}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          arrow="right-top"
          sx={{
            boxShadow: "none",
            borderRadius: 2,
            mt: -1,
            width: 200,
            background: (theme) => theme.palette.background.paper,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
              "& svg": { mr: 2, width: 20, height: 20 },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              dispatch(openModal(true));
              setIsFormVehicle(true);
              setOperation(2); //editar
              setIsFormFooterFicha(false);
            }}
          >
            <EditOutlined />
            Editar
          </MenuItem>

          <Divider sx={{ borderStyle: "dashed" }} />

          <MenuItem
            onClick={() => {
              setIsFormFooterFicha(true);
              dispatch(openModal(true));
              setIsFormVehicle(false);
            }}
          >
            <PictureAsPdfRounded />
            Sección PDF (Footer)
          </MenuItem>

          <Divider sx={{ borderStyle: "dashed" }} />

          <MenuItem
            onClick={() => {
              navigate(`/vehiculos/especificaciones/${idSelectVehicle}`);
            }}
          >
            <PixRounded />
            Especificaciones
          </MenuItem>
          <Divider sx={{ borderStyle: "dashed" }} />

          <MenuItem
            onClick={() => {
              navigate(`/vehiculos/variantes/${idSelectVehicle}`);
            }}
          >
            <DashboardCustomizeRounded />
            Variantes
          </MenuItem>

          <Divider sx={{ borderStyle: "dashed" }} />

          <MenuItem
            onClick={() => {
              setOpenDialogDeleteVehicle(true);
            }}
            sx={{ color: "error.main" }}
          >
            <DeleteForever />
            Eliminar
          </MenuItem>
        </MenuPopover>
      </>
    ),
  }));

  //  Eliminar vehiculo
  const handleDeleteVehicle = async (id) => {
    // validamos para regresas anterior o no
    if (currentPage === lastPage && total % 10 === 1) {
      const result = await dispatch(
        deleteVehicle(id, {
          page: currentPage - 1,
          search: "",
        })
      );
      if (result.status === 200) {
        setOpenDialogDeleteVehicle(false);
        enqueueSnackbar(result.data.message, { variant: "success" });
        setOpen(null);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      const result = await dispatch(
        deleteVehicle(id, {
          page: currentPage,
          search: "",
        })
      );
      if (result.status === 200) {
        setOpenDialogDeleteVehicle(false);
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  // llamamos toda la data de vehiculos validando si hay busqueda o no
  useEffect(() => {

    if (searchVehicle) {
      dispatch(dataVehicle({
        page: currentPage, search: searchVehicle
      }));
    } else {
      dispatch(dataVehicle({ page: currentPage === 0 ? 1 : currentPage, search: "" }));
    }

  }, [dispatch, searchVehicle, currentPage]);

  return (
    <Page title="Administración: Vehículos">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="Lista de vehículos"
          links={[{ name: "Inicio", href: "/" }, { name: "Vehículos" }]}
          action={
            <Button
              variant="contained"
              onClick={() => {
                setOperation(1);
                setIdSelectVehicle(null);
                dispatch(openModal(true));
                setIsFormVehicle(true);
                setIsFormFooterFicha(false);
                setValueSearch("")
              }}
            >
              Nuevo vehículo
            </Button>
          }
        />
        {/* Tabla */}

        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: 630,
            justifyContent: "space-between",
          }}
        >
          <Stack>
            <Stack direction="row" justifyContent="flex-end" sx={{ p: 1 }}>
              <TextField
                value={valueSearch}
                onChange={handleChangeSearch}
                variant="outlined"
                placeholder="Buscar vehículo"
                sx={{ m: 1, width: "40ch" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <TableGeneral
              rows={DATA_CLIENTS}
              headLabel={tableHeaders}
              isLoading={isLoading}
            />
          </Stack>

          <Stack
            direction="row"
            sx={{ p: 2, textAlign: "center", justifyContent: "flex-end" }}
          >
            <Typography variant="caption" sx={{ marginTop: "8px" }}>
              TOTAL: {total}
            </Typography>

            <Pagination
              count={lastPage}
              page={currentPage}
              onChange={handleChangePagination}
              showFirstButton
              showLastButton
            />
          </Stack>
        </Paper>
      </Container>

      {/* Formulario crear y actualizar vehiculo principal*/}
      {isOpenModal && isFormVehicle ? (
        <FormVehicle
          operation={operation}
          idSelectVehicle={idSelectVehicle}
          title={operation === 1 ? "Nuevo vehículo" : "Editar vehículo"}
          setOpenModalPop={setOpen}
          valueSearch={valueSearch}
        />
      ) : null}

      {/* Formulario actualizar el footer - FICHA TECNICA */}
      {isOpenModal && isFormFooterFicha ? (
        <FormFichaTecnicaVehicle
          idSelectVehicle={idSelectVehicle}
          setOpenModalPop={setOpen}
          valueSearch={valueSearch}
          title={`Ficha técnica vehículo  "${vehicles?.find((item) => item.id === idSelectVehicle)?.model?.name
            }" `}
        />
      ) : null}

      {/* Alerta eliminar la RED SOCIAL*/}
      <InitDialogAlert
        isOpen={openDialogDeleteVehicle}
        setOpen={setOpenDialogDeleteVehicle}
        onOk={() => handleDeleteVehicle(idSelectVehicle)}
        title={`¿Desea eliminar "${vehicles?.find((item) => item.id === idSelectVehicle)?.model?.name
          }"? `}
        type="error"
      />
    </Page>
  );
};

export default Vehicles;
