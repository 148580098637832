import PropTypes from 'prop-types';
import { useState } from 'react';
import { Tab, Box, Stack, Tabs } from '@mui/material';

const InitTabsGeneral = ({ items, tabSelection }) => {
  const [currentTab, setCurrentTab] = useState(tabSelection);
  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Stack spacing={5}>
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {items.map((tab) => (
            <Tab disableRipple key={tab.id} label={tab.label} icon={tab.icon} value={tab.id} />
          ))}
        </Tabs>

        {items.map((tab) => {
          const isMatched = tab.id === currentTab;
          return isMatched && <Box key={tab.id}>{tab.component}</Box>;
        })}
      </Stack>
    </>
  );
};

InitTabsGeneral.propTypes = {
  items: PropTypes.array,
  tabSelection: PropTypes.number,
};

export default InitTabsGeneral;
