import React from "react";
import CategoryTab from "./components/CategoryTab";
import Model from "./components/Model";
import Trademark from "./components/Trademark";
import {
  CategoryRounded,
  ViewArrayOutlined,
  WorkspacePremium,
} from "@mui/icons-material";
import Page from "../../../../components/Page";
import { Container } from "@mui/material";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
import InitTabsGeneral from "../../../../components/InitTabsGeneral";

const VehicleConfig = () => {
  const TABS = [
    {
      id: 1,
      label: "Marcas",
      icon: <WorkspacePremium />,
      component: <Trademark />,
    },
    {
      id: 2,
      label: "Modelos",
      icon: <ViewArrayOutlined />,
      component: <Model />,
    },
    {
      id: 3,
      label: "Categorias",
      icon: <CategoryRounded />,
      component: <CategoryTab />,
    },
  ];

  return (
    <Page title="Configuración: Vehículos">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Configuración Vehículos"
          links={[
            { name: "Configuración", href: "/configuracion" },
            { name: "Vehículos" },
          ]}
        />
        <InitTabsGeneral items={TABS} tabSelection={1} />
      </Container>
    </Page>
  );
};

export default VehicleConfig;
