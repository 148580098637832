export const VARIANT_TYPES = {
  color: {
    id: 1,
    description: "COLOR VEHÍCULO",
  },
  version: {
    id: 2,
    description: "VERSIÓN VEHÍCULO",
  },
};

export const OPERATION_TYPE = {
  CREATE: 1,
  UPDATE: 2,
};

export const errorMessageTradeMark = {};

export const errorVehicle = {
  gallery: "Error con la galeria de imagenes",
};
