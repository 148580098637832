import { useEffect, useState } from "react";
import {
  Button,
  Container,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import CsvDownloadButton from "react-json-to-csv";
import {
  FilePresentOutlined,
  InfoRounded,
  SearchOutlined,
} from "@mui/icons-material";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";

import TableGeneral from "../../../components/InitTables/TableGeneral";
import Page from "../../../components/Page";
// Redux
import { useDispatch, useSelector } from "react-redux";

import {
  dataPrivacy,
  exportDataPrivacy,
} from "../../../redux/slices/dataPrivacy";

const DerechosArco = () => {
  const dispatch = useDispatch();

  const { participants, pagination, isLoading } = useSelector(
    (state) => state.dataPrivacy
  );
  const { total, currentPage, lastPage } = pagination;
  const [allDataSubs, setAllDataSubs] = useState([]);

  // Paginacion
  const handleChangePagination = (event, value) => {
    dispatch(dataPrivacy({ page: value, search: "" }));
  };

  // Buscador
  const handleChangeSearch = async (event) => {
    event.preventDefault();
    dispatch(
      dataPrivacy({
        page: 1,
        search: event.target.value,
      })
    );
  };

  const tableHeaders = [
    { id: "id", label: "N°" },
    { id: "company_name", label: "Compañia", align: "left" },
    { id: "full_name", label: "Nombres", align: "left" },
    { id: "email", label: "Correo", align: "left" },
    { id: "document_number", label: "DNI", align: "left" },
    { id: "phone", label: "Celular", align: "left" },
    {
      id: "authorized_notification",
      label: "Autoriza Notificar Email",
      align: "center",
    },
    { id: "client_has_notification", label: "Notif. cliente", align: "center" },
    {
      id: "company_has_notification",
      label: "Notif. compañia",
      align: "center",
    },
    {
      id: "legal_rights",
      label: "Derechos legales",
      align: "center",
    },
    {
      id: "file_url",
      label: "Archivo adjunto",
      align: "center",
    },
  ];

  const DATA_CLIENTS = participants?.map((item, index) => ({
    id: index + 1,
    company_name: item?.company_name,
    email: item?.email,
    document_number: item?.document_number,
    phone: item?.phone,
    full_name: item?.name + " " + item?.last_name,
    authorized_notification: item?.authorized_notification ? "Si" : "No",
    client_has_notification: item?.client_has_notification ? "Si" : "No",
    company_has_notification: item?.company_has_notification ? "Si" : "No",
    legal_rights: (
      <Tooltip
        title={
          <Stack>
            {item?.legal_rights
              ?.filter((item) => item.value === true)
              .map((item) => (
                <Typography key={item.id} variant="caption">
                  - {item.description}
                </Typography>
              ))}
          </Stack>
        }
      >
        <IconButton>
          <InfoRounded />
        </IconButton>
      </Tooltip>
    ),
    file_url: (
      <>
        <a href={item?.file_url} target="_blank" rel="noreferrer">
          <FilePresentOutlined />
        </a>
      </>
    ),
  }));

  // Obtener suscriptores
  useEffect(() => {
    dispatch(dataPrivacy({ page: 1, search: "" }));
  }, [dispatch]);

  const handleDowloandCsv = async () => {
    const data = await dispatch(exportDataPrivacy());
    setAllDataSubs(data);
  };

  return (
    <Page title="Administración: Derechos de Arco">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Lista de Derechos de Arco"
          links={[{ name: "Inicio", href: "/" }, { name: "Derechos de arco" }]}
          action={
            <>
              {allDataSubs.length < 1 ? (
                <Button
                  variant="contained"
                  onClick={handleDowloandCsv}
                  disabled={isLoading}
                >
                  Generar reporte
                </Button>
              ) : (
                <CsvDownloadButton
                  data={allDataSubs}
                  filename="derechosarco-incamotors.csv"
                  style={{
                    //pass other props, like styles
                    boxShadow: "inset 0px 1px 0px 0px #287233",
                    backgroundColor: "#287233",
                    borderRadius: "6px",
                    border: "1px solid #287233",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "#ffffff",
                    fontSize: "15px",
                    fontWeight: "bold",
                    padding: "6px 24px",
                    textDecoration: "none",
                    textShadow: "0px 1px 0px #287233",
                  }}
                >
                  Descargar reporte
                </CsvDownloadButton>
              )}
            </>
          }
        />
        {/* Tabla */}

        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: 630,
            justifyContent: "space-between",
          }}
        >
          <Stack>
            <Stack direction="row" justifyContent="flex-end" sx={{ p: 1 }}>
              <TextField
                onChange={handleChangeSearch}
                variant="outlined"
                placeholder="Buscar..."
                sx={{ m: 1, width: "40ch" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <TableGeneral
              rows={DATA_CLIENTS}
              headLabel={tableHeaders}
              isLoading={isLoading}
            />
          </Stack>

          <Stack
            direction="row"
            sx={{ p: 1, textAlign: "center", justifyContent: "flex-end" }}
          >
            <Typography variant="caption" sx={{ marginTop: "8px" }}>
              TOTAL: {total}
            </Typography>

            <Pagination
              count={lastPage}
              page={currentPage}
              onChange={handleChangePagination}
              showFirstButton
              showLastButton
            />
          </Stack>
        </Paper>
      </Container>
    </Page>
  );
};

export default DerechosArco;
