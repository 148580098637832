import numeral from "numeral";

// ----------------------------------------------------------------------

export function fCurrencyUSD(number) {
  return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
}

export function fCurrencyPEN(number) {
  return numeral(number).format(Number.isInteger(number) ? "0,0" : "0,0.00");
}

export function fPercent(number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format("0.00a").replace(".00", "");
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}
export function fDate(date) {
  // 2023-09-13 23:38 solo retornaremos la fecha
  date = date.split(" ")[0];
  // 2023-09-13
  return date.split("-").reverse().join("/");
}
