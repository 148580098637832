import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  establishment: [],
  establishments: [],
  trademarks: [],
  trademarksEstablishment: [],
};

const establishmentSlice = createSlice({
  name: "sucursal",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Lista de sucursales
    dataEstablishmentSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.establishments = action.payload;
      state.message = "Obtuvimos todos las sucursales 🙌";
    },

    // Obtener data de la sucursal por ID
    getDataIdEstablishmentSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.establishment = action.payload;
      state.message = "Obtuvimos data de la sucursal 🙌";
    },

    //Crear sucursal
    createEstablishmentSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Actualizar sucursal
    updateEstablishmentSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Eliminar sucursal
    deleteEstablishmentSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Tabla de sucursales
    getTableEstablishmentSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.trademarks = action.payload.trademarks;
      state.message = "Obtuvimos datas para tables 🙌";
    },

    addTrademarkEstablishmentSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    deleteTrademarkEstablishmentSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    dataTrademarkEstablishmentSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.trademarksEstablishment = action.payload;
      state.message = "Obtuvimos todos las sucursales 🙌";
    },
  },
});

// Reducer - Aca va la data o los valores
export default establishmentSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  dataEstablishmentSuccess,
  getDataIdEstablishmentSuccess,
  createEstablishmentSuccess,
  updateEstablishmentSuccess,
  deleteEstablishmentSuccess,
  getTableEstablishmentSuccess,
  addTrademarkEstablishmentSuccess,
  deleteTrademarkEstablishmentSuccess,
  dataTrademarkEstablishmentSuccess,
} = establishmentSlice.actions;

// ========Funciones===========

// Data de las sucursales
// -------------------------------------------------------
export function dataEstablishments() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`establishments/records`);
      dispatch(dataEstablishmentSuccess(response?.data?.data));
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}

// Obtener dato de la surcursal por ID
// -------------------------------------------------------
export function getDataIdEstablishment(id) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`/establishments/record/${id}`);
      dispatch(getDataIdEstablishmentSuccess(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error;
    }
  };
}

// Crear sucursal
// -------------------------------------------------------
export function createEstablishment(data) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.post("/establishments", data);
      dispatch(createEstablishmentSuccess(response.data.message));
      dispatch(dataEstablishments()); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Actualizar sucursal
// -------------------------------------------------------
export function updateEstablishment(id, data) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.put(`/establishments/${id}`, data);
      dispatch(updateEstablishmentSuccess(response.data.message));
      dispatch(dataEstablishments()); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Eliminar  sucursal
// -------------------------------------------------------
export function deleteEstablishment(idEstablishment) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.delete(
        `/establishments/${idEstablishment}`
      );
      dispatch(deleteEstablishmentSuccess(response.data.message));
      dispatch(dataEstablishments()); // actualizamos la lista de sucursales
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Table - Datos necesarios para la tabla
// -------------------------------------------------------
export function getTableEstablishment() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get("/establishments/table");
      dispatch(getTableEstablishmentSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error.response.data.message));
    }
  };
}

// Añadir marcas a la sucursal
// -------------------------------------------------------
export function addTrademarkEstablishment(idEstablishment, data) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.put(
        `/establishments/add-trademark/${idEstablishment}`,
        data
      );
      dispatch(addTrademarkEstablishmentSuccess(response.data.message));
      dispatch(dataTrademarkEstablishment(idEstablishment)); // actualizamos la lista de marcas de la sucursal
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Eliminar marcas de la sucursal
export function deleteTrademarkEstablishment(idEstablishment, idTrademark) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.delete(
        `/establishments/remove-trademark/${idEstablishment}`,
        {
          data: {
            trademark_id: idTrademark,
          },
        }
      );
      dispatch(deleteTrademarkEstablishmentSuccess(response.data.message));
      dispatch(dataTrademarkEstablishment(idEstablishment)); // actualizamos la lista de sucursales
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

//
export function dataTrademarkEstablishment(idEstablishment) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(
        `establishments/records-trademark/${idEstablishment}`
      );
      dispatch(dataTrademarkEstablishmentSuccess(response?.data?.data));
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}
