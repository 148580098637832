import React, { useEffect, useState } from "react";
import { Button, Container, Grid } from "@mui/material";
import { Add } from "@mui/icons-material";
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
// redux
import { useDispatch, useSelector } from "react-redux";
import { dataEstablishments } from "../../../../redux/slices/establishment";
import CardEstablishment from "./components/CardEstablisment";
import { openModal } from "../../../../redux/slices/global";
import FormEstablishment from "./components/FormDialog/FormEstablishment";
import FormAddTrademark from "./components/FormDialog/FormAddTrademark";

const EstablishmentConfig = () => {
  const dispatch = useDispatch();
  const { establishments } = useSelector((state) => state.establishment);
  const { isOpenModal } = useSelector((state) => state.global);

  // Para los Establecimientos
  const [operationEstablishment, setOperationEstablishment] = useState(1); // 1 = Crear / 2= Editar
  const [isFormEstablishment, setIsFormEstablishment] = useState(false);
  const [idSelectEstablishment, setIdSelectEstablishment] = useState(null);

  const [isFormAddTrademark, setIsFormAddTrademark] = useState(false);

  // Obtener todos los establecimientos
  useEffect(() => {
    dispatch(dataEstablishments());
  }, [dispatch]);

  return (
    <Page title="Configuración: Sucursales">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Sucursales"
          links={[
            { name: "Configuración", href: "/configuracion" },
            { name: "Sucursales" },
          ]}
          action={
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              sx={{ textTransform: "none" }}
              onClick={() => {
                dispatch(openModal(true));
                setIsFormEstablishment(true);
                setOperationEstablishment(1);
                setIdSelectEstablishment(null);
                setIsFormAddTrademark(false);
              }}
            >
              Nueva Sucursal
            </Button>
          }
        />

        <Grid container spacing={3}>
          {establishments?.map((item) => (
            <Grid key={item.id} item xs={12} sm={6} md={4}>
              <CardEstablishment
                dataEstablishment={item}
                setIdSelectEstablishment={setIdSelectEstablishment}
                setOperationEstablishment={setOperationEstablishment}
                setIsFormEstablishment={setIsFormEstablishment}
                setIsFormAddTrademark={setIsFormAddTrademark}
              />
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* FORMULARIO ESTABLECIMIENTO */}
      {isOpenModal && isFormEstablishment ? (
        <FormEstablishment
          title={
            operationEstablishment === 1 ? "NUEVO SUCURSAL" : "EDITAR SUCURSAL"
          }
          operationEstablishment={operationEstablishment}
          idSelectEstablishment={idSelectEstablishment}
        />
      ) : null}

      {/* Formulario añadir marca */}
      {isFormAddTrademark && isOpenModal ? (
        <FormAddTrademark idSelectEstablishment={idSelectEstablishment} />
      ) : null}
    </Page>
  );
};

export default EstablishmentConfig;
