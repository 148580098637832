import axios from "axios";

// !URL PARA PRODUCCION
const baseURL = "https://ipk7op2r6b.execute-api.sa-east-1.amazonaws.com/prod/api";

// !URL PARA PRUEBAS - QA
// const baseURL = 'https://b6v5gn38zb.execute-api.us-east-2.amazonaws.com/test/api'

// Para las peticiones que necesitan token
const AppApiToken = axios.create({ baseURL });

// Middelware de axios
AppApiToken.interceptors.request.use((config) => {
  const token = localStorage.getItem("token-incamotors");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`; // Authorization solicita marco
  }
  return config;
});

// si se vencio la sesion enviaremos al inicio del login
AppApiToken.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token-incamotors");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

// Para las peticiones que no necesitan token
const AppApiNoToken = axios.create({ baseURL });

export { AppApiToken, AppApiNoToken };
