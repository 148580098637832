import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery, IconButton } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import { KeyboardDoubleArrowLeftOutlined } from "@mui/icons-material";
import NavbarAccount from "./navbar/NavbarAccount";
import NavBarSettings from "./navbar/NavBarSettings";
import AppBarHeader from "./header";
import Scrollbar from "../../components/Scrollbar";
const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerDesktop = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  zIndex: 1240,
  "& .MuiDrawer-paper": {
    borderRight:
      theme.palette.mode === "dark"
        ? "0.1px dashed #384048"
        : "0.1px dashed #e0e0e0",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-docked": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DashboardLayout = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [openMobile, setOpenMobile] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Desktop
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Mobile

  const handleDrawerOpenMobile = () => {
    setOpenMobile(true);
  };

  const handleDrawerCloseMobile = () => {
    setOpenMobile(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarHeader
        open={open}
        isMobile={isMobile}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerOpenMobile={handleDrawerOpenMobile}
      />
      {isMobile ? (
        <Drawer
          anchor="left"
          open={openMobile}
          onClose={handleDrawerCloseMobile}
          sx={{
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor:
                theme.palette.mode === "dark" ? "#000" : "#ffffff",
              boxSizing: "border-box",
              borderRight:
                theme.palette.mode === "dark"
                  ? "0.1px dashed #384048"
                  : "0.1px dashed #e0e0e0",
            },
          }}
        >
          <Scrollbar
            sx={{
              width: drawerWidth, // COLOCAR SIEMPRE EL ACNCHO DEL DRAWER en el SCROLLBAR
              height: "100vh", //TODO: IMPORTANT: colocar importante PARA EL SCROLL
              "& .simplebar-content": {
                height: 1,
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            {open && (
              <DrawerHeader
                sx={{ justifyContent: "space-between", pt: 2, px: 2 }}
              >
                {open ? (
                  <Box
                    component="img"
                    src={
                      theme.palette.mode === "dark"
                        ? "/assets/logo/logo-dark.svg"
                        : "/assets/logo/logo-white.svg"
                    }
                    sx={{ width: 140, height: "100%" }}
                  />
                ) : null}

                {isMobile ? (
                  <IconButton onClick={handleDrawerCloseMobile}>
                    <KeyboardDoubleArrowLeftOutlined />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleDrawerClose}>
                    <KeyboardDoubleArrowLeftOutlined />
                  </IconButton>
                )}
              </DrawerHeader>
            )}
            <NavbarAccount open={open} isMobile={isMobile} />
            <NavBarSettings
              open={open}
              isMobile={isMobile}
              handleDrawerCloseMobile={handleDrawerCloseMobile}
            />
          </Scrollbar>
        </Drawer>
      ) : (
        <DrawerDesktop variant="permanent" open={open}>
          <Scrollbar
            sx={{
              width: open ? drawerWidth : 70, // COLOCAR SIEMPRE EL ACNCHO DEL DRAWER en el SCROLLBAR
              height: "100vh", //TODO: IMPORTANT: colocar importante PARA EL SCROLL
              "& .simplebar-content": {
                height: 1,
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            {open && (
              <DrawerHeader
                sx={{ justifyContent: "space-between", pt: 2, px: 2 }}
              >
                {open ? (
                  <Box
                    component="img"
                    src={
                      theme.palette.mode === "dark"
                        ? "/assets/logo/logo-dark.svg"
                        : "/assets/logo/logo-white.svg"
                    }
                    sx={{ width: 140, height: "100%" }}
                  />
                ) : null}

                <IconButton onClick={handleDrawerClose}>
                  <KeyboardDoubleArrowLeftOutlined />
                </IconButton>
              </DrawerHeader>
            )}
            <NavbarAccount open={open} />
            <NavBarSettings open={open} />
          </Scrollbar>
        </DrawerDesktop>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 1.5, sm: 3 },
          width: { xs: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardLayout;
