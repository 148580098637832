import React, { useCallback, useEffect } from "react";
import InitDialog from "../../../../../components/InitDialog";
import { useSnackbar } from "notistack";

// Formularios
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFTextField,
  RHFEditor,
  RHFUploadSingleFile,
} from "../../../../../components/hook-form";

import { useDispatch, useSelector } from "react-redux";
import {
  createSpecificationVehicle,
  updateSpecificationVehicle,
} from "../../../../../redux/slices/specificationVehicle";
import { openModal } from "../../../../../redux/slices/global";
import { Button, Stack, Typography } from "@mui/material";

const FormSpecificationVehicle = ({
  title,
  isOperation,
  idSelectVehicle,
  dataIdEspecficationVehicle,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.specificationVehicle);

  // valores
  const defaultValues = {
    name: "",
    description: "",
    icon: "",
    vehicle_id: idSelectVehicle,
  };

  // Validaciones
  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Este campo es requerido"),
    description: Yup.string().required("Este campo es requerido"),
    vehicle_id: Yup.string().required("Este campo es requerido"),
  });

  // Obtener los valores del formulario a
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleDropIcon = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue("icon", file);
      }
    },
    [setValue]
  );

  const onSubmit = async (data) => {
    if (isOperation === 1) {
      // Enviamos la data como FormData para poder enviar el archivo
      const formDataCreate = new FormData();
      formDataCreate.append("name", data.name);
      formDataCreate.append("description", data.description);
      formDataCreate.append("icon", data.icon);
      formDataCreate.append("vehicle_id", data.vehicle_id);
      const result = await dispatch(
        createSpecificationVehicle(formDataCreate, idSelectVehicle)
      );
      if (result.status === 201) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(
          result.data.message ||
            "Error al crear la especificación del vehículo",
          {
            variant: "error",
          }
        );
      }
    } else if (isOperation === 2) {
      const dataFormUpdate = new FormData();
      dataFormUpdate.append("name", data.name);
      dataFormUpdate.append("description", data.description);
      dataFormUpdate.append("icon", data.icon);

      const result = await dispatch(
        updateSpecificationVehicle(
          dataFormUpdate,
          dataIdEspecficationVehicle?.id,
          idSelectVehicle
        )
      );
      if (result.status === 200) {
        dispatch(openModal(!true));
        enqueueSnackbar(result.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(
          JSON.stringify(result.message) || "Error al actualizar",
          { variant: "error" }
        );
      }
    }
  };

  // Autocompletar los campos solo para editar isOperation = 2
  useEffect(() => {
    // Autocompletamos con la data que nos llega
    if (isOperation === 2 && dataIdEspecficationVehicle !== null) {
      const data = {
        name: dataIdEspecficationVehicle.name,
        description: dataIdEspecficationVehicle.description,
        icon: dataIdEspecficationVehicle.icon_url,
        vehicle_id: dataIdEspecficationVehicle.vehicle_id,
      };
      reset(data);
    }
  }, [dispatch, dataIdEspecficationVehicle, isOperation, reset]);

  return (
    <InitDialog title={title} fullWidth loading={isLoading} size="md">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ m: 1 }} spacing={2}>
          <Stack direction={{ xs: "column", md: "column" }} spacing={2}>
            <RHFTextField
              name="name"
              label="Título especificación del vehículo"
              autoComplete="off"
            />
            <Typography variant="inherit" sx={{ fontWeight: 600 }}>
              Descripción especificación del vehículo
            </Typography>
            <RHFEditor
              name="description"
              label="Descripción especificación del vehículo"
            />
          </Stack>

          <Typography variant="inherit" sx={{ fontWeight: 600 }}>
            Icono especificación del vehículo
          </Typography>
          <div>
            <RHFUploadSingleFile
              disabled={isLoading}
              name="icon"
              accept={{
                "image/*": [".jpeg", ".png", ".jpg", ".svg", ".webp"],
              }}
              maxSize={3145728} // 3MB TODO: Consultar a que tamaño se debe limitar
              onDrop={handleDropIcon}
            />
          </div>
        </Stack>
        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", mx: 1, mt: 2, gap: 2 }}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ minWidth: 100 }}
            onClick={() => {
              dispatch(openModal(false));
            }}
          >
            Cancelar
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            disabled={isLoading || isSubmitting}
          >
            Guardar
          </Button>
        </Stack>
      </FormProvider>
    </InitDialog>
  );
};

export default FormSpecificationVehicle;
