import { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
import {
  Container,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { EditOutlined } from "@mui/icons-material";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getDataIdCompany } from "../../../../redux/slices/company";
import { openModal } from "../../../../redux/slices/global";
import TableInformation from "../../../../components/TablesInformation/TableInformation";
import FormCompany from "./FormDialog/FormCompany";

const CompanyConfigGeneral = () => {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.company);
  const { isOpenModal } = useSelector((state) => state.global);
  const [isFormCompany, setIsFormCompany] = useState(false);

  // Data para el componente de datos generales
  const dataGeneral = [
    {
      sectionTitle: "Datos Generales",
      description: `Información general de la empresa Incamotors`,
      editComponent: (
        <>
          <Tooltip title="Editar" placement="right-end">
            <IconButton
              size="small"
              onClick={() => {
                dispatch(openModal(true));
                setIsFormCompany(true);
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
        </>
      ),
      items: [
        {
          title: "Email",
          subtitle: company?.email,
        },
        {
          title: "Teléfonos principales de la sucursal",
          subtitle: (
            <>
              {company?.telephones?.map((telephone, index) => (
                <Stack key={index}>
                  <Typography variant="caption">
                    {index + 1}: {telephone.description} -{" "}
                    {telephone.subdescription} -{" "}
                    <Typography
                      variant="caption"
                      component="a"
                      target="_blank"
                      href={telephone.url}
                    >
                      {telephone.number}
                    </Typography>
                  </Typography>
                </Stack>
              ))}
            </>
          ),
        },
        {
          title: "Redes Sociales",
          subtitle: (
            <>
              {company?.social_networks?.map((social, index) => (
                <Stack key={index}>
                  <Typography variant="caption">
                    {index + 1} : {social.description} -{" "}
                    <Typography
                      variant="caption"
                      component="a"
                      target="_blank"
                      href={social.path}
                    >
                      URL
                    </Typography>
                  </Typography>
                </Stack>
              ))}
            </>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(getDataIdCompany());
  }, [dispatch]);

  return (
    <Page title="Configuración: Empresa">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Configuración Empresa"
          links={[
            { name: "Configuración", href: "/configuracion" },
            { name: "Empresa" },
          ]}
        />

        {/* Datos Generales, contiene formato PDF y entorno SUNAT */}

        <TableInformation data={dataGeneral} />
      </Container>
      {/* Formulario compañia */}
      {isOpenModal && isFormCompany ? (
        <FormCompany title="ACTUALIZAR DATA COMPAÑIA" />
      ) : null}
    </Page>
  );
};

export default CompanyConfigGeneral;
