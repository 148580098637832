import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  slayer: [],
  slayers: [],
  pagination: {
    total: 0,
    currentPage: 0,
    pageSize: 0,
    lastPage: 0,
  },
};

const slayerSlice = createSlice({
  name: "slayer",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // Lista de slayers
    dataSlayersSuccess(state, action) {
      const pagination = action.payload.meta;
      state.isError = false;
      state.isLoading = false;
      state.slayers = action.payload.data;
      state.message = "Obtuvimos todos los slayers 🙌";
      // Paginacion
      state.pagination.currentPage = pagination.current_page;
      state.pagination.pageSize = pagination.per_page * 1;
      state.pagination.total = pagination.total;
      state.pagination.lastPage = pagination.last_page;
    },

    // Obtener data del sliders por ID
    getDataIdSlayerSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.slayer = action.payload;
      state.message = "Obtuvimos data del slayer 🙌";
    },

    //Crear slayer
    createSlayerSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Actualizar slayer
    updateSlayerSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },

    //Eliminar slayer
    deleteSlayerSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.message = action.payload;
    },
  },
});

// Reducer - Aca va la data o los valores
export default slayerSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  dataSlayersSuccess,
  getDataIdSlayerSuccess,
  createSlayerSuccess,
  updateSlayerSuccess,
  deleteSlayerSuccess,
} = slayerSlice.actions;

// ========Funciones===========

// Data de las slayers
// -------------------------------------------------------
export function dataSlayer(query) {
  const { page = 1, per_page = 10, search = "" } = query;

  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(
        `/slayers/records?is_paginate=true&per_page=${per_page}&page=${page}&search=${search}`
      );
      dispatch(dataSlayersSuccess(response?.data));
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}

// Obtener dato de slayer por ID
// -------------------------------------------------------
export function getDataIdSlayer(id) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`/slayers/record/${id}`);
      dispatch(getDataIdSlayerSuccess(response.data.data));
      return response.data.data;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error;
    }
  };
}

// Crear slayer
// -------------------------------------------------------
export function createSlayer(data, query) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.post("/slayers", data);
      dispatch(createSlayerSuccess(response.data.message));
      dispatch(dataSlayer(query)); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Actualizar slayer
// -------------------------------------------------------
export function updateSlayer(id, data, query) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.put(`/slayers/${id}`, data);
      dispatch(updateSlayerSuccess(response.data.message));
      dispatch(dataSlayer(query)); //llamamos a la funcion para actualizar la lista
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}

// Eliminar  slayer
// -------------------------------------------------------
export function deleteSlayer(id, query) {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.delete(`/slayers/${id}`);
      dispatch(deleteSlayerSuccess(response.data.message));
      dispatch(dataSlayer(query)); // actualizamos la lista de slayers
      return response;
    } catch (error) {
      dispatch(hasError(error.response.data.message));
      return error.response.data;
    }
  };
}
