import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export function RHFCheckbox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Checkbox {...field} checked={field.value} />}
        />
      }
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export function RHFMultiCheckbox({ name, options, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option) =>
          field.value.includes(option) ? field.value.filter((value) => value !== option) : [...field.value, option];
        return (
          <FormGroup>
            <Grid container spacing={2}>
              {options.map((option) => (
                <Grid item xs={6} sm={4} md={2} key={option.id}>
                  <FormControlLabel
                    value={option.id}
                    key={option.id}
                    control={
                      <Checkbox
                        checked={field.value.includes(option.id)}
                        onChange={() => {
                          field.onChange(onSelected(option.id));
                        }}
                      />
                    }
                    label={option.description}
                    {...other}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        );
      }}
    />
  );
}
