import { createTheme } from "@mui/material";
import { COMMON, GREY, PRIMARY } from "./palette";
import shadows, { customShadows } from "./shadows";

export const lightTheme = createTheme({
  typography: {
    fontFamily: "IBM Plex Sans, sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    ...COMMON,
    mode: "light",
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: "#fff", default: "#fff", neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  shadows: shadows.light,
  customShadows: customShadows.light,
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: 32,
          fontWeight: 600,
        },
        h2: {
          fontSize: 20,
          fontWeight: 600,
        },
        h3: {
          fontSize: 18,
          fontWeight: 600,
        },
        h4: {
          fontSize: 16,
          fontWeight: 400,
        },
        h5: {
          fontSize: 14,
          fontWeight: 400,
        },
        h6: {
          fontSize: 12,
          fontWeight: 600,
        },
        subtitle1: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
    },

    MuiChip: {
      defaultProps: {},
      styleOverrides: {
        root: {
          // borderRadius: 4,
          fontWeight: 600,
        },
      },
    },

    MuiDialog: {
      defaultProps: {},
      styleOverrides: {
        paper: {
          boxShadow: customShadows.light.dialog,
          "&.MuiPaper-rounded": {
            borderRadius: 12,
          },
        },
        root: {
          "& .MuiDialog-paper": {},
        },
      },
    },

    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        root: {
          ":hover": {
            color: PRIMARY.main,
          },
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          [`& fieldset`]: {
            borderRadius: 6,
          },
          [`& .MuiFormHelperText-root`]: {
            fontSize: 10,
          },
        },
      },
    },

    MuiSelect: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          [`& fieldset`]: {
            borderRadius: 6,
          },
        },
      },
    },

    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
        },
      },
    },

    MuiIconButton: {
      defaultProps: {},
      styleOverrides: {
        root: {
          ":hover": {
            transform: "scale(1.16)",
          },
        },
      },
    },

    // tabs
    MuiTab: {
      defaultProps: {},
      styleOverrides: {
        root: {
          padding: 0,
          textTransform: "none",
          minHeight: 48,
          "&.Mui-selected": {
            color: GREY[800],
          },
          "&:not(:last-of-type)": {
            marginRight: 18,
          },
          "@media (min-width: 600px)": {
            minWidth: 48,
          },
        },
        labelIcon: {
          minHeight: 48,
          flexDirection: "row",
          "& > *:first-of-type": {
            marginBottom: 0,
            marginRight: 8,
          },
          wrapper: {
            flexDirection: "row",
            whiteSpace: "nowrap",
          },
          textColorInherit: {
            opacity: 1,
          },
        },
      },
    },

    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: customShadows.light.z8,
        },
      },
    },

    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          width: 48,
          borderRadius: "50%",
        },
      },
    },

    // Estilo Scrollbar
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
            width: "14px",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#959595",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
  },
});
