import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { groupBy, map, isEmpty } from "lodash";
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVariantVehicle,
  getVariantVehicle,
} from "../../../redux/slices/variantVehicle";
import { openModal } from "../../../redux/slices/global";
import { InitDialogAlert } from "../../../components/InitDialogs";
import { useSnackbar } from "notistack";
import FormVariantColorVehicle from "./components/FormDialog/FormVariantColorVehicle";
import FormVariantVersionVehicle from "./components/FormDialog/FormVariantVersionVehicle";
// Datas
import { VARIANT_TYPES } from "../../../data/dataGeneral";
import CardVariant from "./components/CardVariant";

const VariantsVehicle = () => {
  const { idVehicle } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isFormVariantColor, setIsFormVariantColor] = useState(false);
  const [isFormVariantVersion, setIsFormVariantVersion] = useState(false);
  const [dataIdVariantVehicle, setDataIdVariantVehicle] = useState({});
  const [openDialogDeleteVariantVehicle, setOpenDialogDeleteVariantVehicle] =
    useState(false);
  const [idVariantVehicle, setIdVariantVehicle] = useState(null);
  const [isOperationColor, setIsOperationColor] = useState(1); //1: create, 2: update
  const [isOperationVersion, setIsOperationVersion] = useState(1); //1: create, 2: update

  const { isOpenModal } = useSelector((state) => state.global);
  const { variantsVehicle, isLoading } = useSelector(
    (state) => state.variantVehicle
  );

  const handleDeleteVariantVehicle = async (idVehicle) => {
    const result = await dispatch(
      deleteVariantVehicle(idVariantVehicle, idVehicle)
    );
    if (result.status === 200) {
      setOpenDialogDeleteVariantVehicle(false);
      enqueueSnackbar(result.data.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  // OBTENER LAS VARIANTES DEL VEHICULO
  useEffect(() => {
    if (idVehicle) dispatch(getVariantVehicle(idVehicle));
  }, [idVehicle, dispatch]);

  // Hacemos un groupBy para agrupar las variantes por tipo
  const variantGroup = groupBy(
    variantsVehicle?.specifications || [],
    "variant_type"
  );

  const onClickEdit = (item) => {
    // 1: create color, 2: update
    if (item?.variant_type === 1) {
      dispatch(openModal(true));
      setIsFormVariantColor(true);
      setIsOperationColor(2);
      setDataIdVariantVehicle(item);
      setIsFormVariantVersion(false);
    } else {
      dispatch(openModal(true));
      setIsFormVariantVersion(true);
      setIsOperationVersion(2);
      setDataIdVariantVehicle(item);
      setIsFormVariantColor(false);
    }
  };

  const onClickDelete = (item) => {
    setIdVariantVehicle(item.id);
    setOpenDialogDeleteVariantVehicle(true);
    setDataIdVariantVehicle(item);
  };
  return (
    <>
      <Page title="Administración: Vehículos variantes">
        <Container maxWidth="lg">
          <HeaderBreadcrumbs
            heading={`Lista de variantes - Modelo: ${
              variantsVehicle?.model || ""
            }`}
            links={[
              { name: "Vehiculos", href: "/vehiculos" },
              { name: "Vehículo variantes" },
            ]}
            action={
              <Stack spacing={2} direction="column">
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch(openModal(true));
                    setIsFormVariantColor(true);
                    setIsOperationColor(1); // 1: create color, 2: update color
                    setIsFormVariantVersion(false);
                  }}
                >
                  Nuevo color
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch(openModal(true));
                    setIsFormVariantVersion(true);
                    setIsOperationVersion(1); // 1: create variante, 2: update variante
                    setIsFormVariantColor(false);
                  }}
                >
                  Nueva versión
                </Button>
              </Stack>
            }
          />

          <Grid container spacing={2} alignItems="center">
            {isEmpty(variantGroup) ? (
              <Grid item xs={12}>
                <Card
                  sx={{
                    height: 300,
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Typography variant="h3">❌ No hay Variantes ❌</Typography>
                </Card>
              </Grid>
            ) : (
              map(variantGroup, (variant, key) => (
                <Grid item xs={12} key={key}>
                  <Grid item xs={12}>
                    <Typography variant="h3" component="div">
                      VARIANTE{" "}
                      {+key === VARIANT_TYPES.color.id
                        ? VARIANT_TYPES.color.description
                        : VARIANT_TYPES.version.description}
                    </Typography>
                    <Divider sx={{ py: 0.5 }} />
                  </Grid>
                  <Grid item xs={12} sx={{ py: 1 }}>
                    <Grid container spacing={2}>
                      {variant.map((item, index) => (
                        <CardVariant
                          key={index}
                          item={item}
                          onClickDelete={onClickDelete}
                          onClickEdit={onClickEdit}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              ))
            )}

            <Grid
              item
              xs={12}
              justifyContent="end"
              alignItems="end"
              display="flex"
            >
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/vehiculos");
                }}
              >
                Regresar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Page>

      {/* Formulario variante COLOR VEHICULO */}
      {isOpenModal && isFormVariantColor ? (
        <FormVariantColorVehicle
          isOperation={isOperationColor}
          idSelectVehicle={idVehicle}
          dataIdVariantVehicle={dataIdVariantVehicle}
          title={`
            ${
              isOperationColor === 1
                ? "CREAR VARIANTE COLOR"
                : `EDITAR VARIANTE COLOR - ${dataIdVariantVehicle?.name}`
            }`}
        />
      ) : null}

      {/* Formulario para verison de VEHICULO */}
      {isOpenModal && isFormVariantVersion ? (
        <FormVariantVersionVehicle
          isOperation={isOperationVersion}
          idSelectVehicle={idVehicle}
          dataIdVariantVehicle={dataIdVariantVehicle}
          title={`
            ${
              isOperationVersion === 1
                ? "CREAR VARIANTE VERSIÓN"
                : `EDITAR VARIANTE VERSIÓN - ${
                    (dataIdVariantVehicle?.name || "").includes("{")
                      ? JSON.parse(dataIdVariantVehicle?.name)?.model
                      : dataIdVariantVehicle?.name
                  }`
            }`}
        />
      ) : null}

      {/* Alerta eliminar vatiante del vehiculo*/}
      <InitDialogAlert
        isLoading={isLoading}
        isOpen={openDialogDeleteVariantVehicle}
        setOpen={setOpenDialogDeleteVariantVehicle}
        onOk={() => handleDeleteVariantVehicle(idVehicle)}
        title={`¿Desea eliminar variante " ${
          (dataIdVariantVehicle?.name || "").includes("{")
            ? JSON.parse(dataIdVariantVehicle?.name)?.model
            : dataIdVariantVehicle?.name
        } "? `}
        type="error"
      />
    </>
  );
};

export default VariantsVehicle;
