import { Grid } from "@mui/material";
import LoginForm from "./LoginForm";

const LoginPage = () => {
  return (
    <div className={{ height: "100vh", overflow: "auto" }}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ height: "100%", overflow: "auto", pr: 4, pl: 4 }}
        >
          <LoginForm />
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;
