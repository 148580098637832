import {
  Casino,
  ConnectWithoutContactRounded,
  DirectionsCar,
  EmailOutlined,
  PeopleOutlineRounded,
  PhotoLibrary,
  Settings,
  CarRental, ShieldOutlined
} from "@mui/icons-material";

export const NavBarRoutes = [
  {
    section: "Administración",
    items: [
      {
        title: "Banners",
        route: "banners",
        icon: <PhotoLibrary />,
      },
      {
        title: "Redes Sociales",
        route: "redes-sociales",
        icon: <ConnectWithoutContactRounded />,
      },
      {
        title: "Vehículos",
        route: "vehiculos",
        icon: <DirectionsCar />,
      },
      {
        title: "Suscriptores",
        route: "suscritos",
        icon: <PeopleOutlineRounded />,
      },
      {
        title: "Cotizaciones",
        route: "cotizaciones",
        icon: <EmailOutlined />,
      },
      {
        title: "Sorteo",
        route: "sorteos",
        icon: <Casino />,
      },
      {
        title: "Campaña Vehiculos",
        route: "capania-vehiculo",
        icon: <CarRental />,
      },
      {
        title: "Derechos de Arco",
        route: "derechos-arco",
        icon: <ShieldOutlined />,
      },
    ],
  },

  {
    section: "Empresa",
    items: [
      {
        title: "Configuración",
        route: "configuracion",
        icon: <Settings />,
      },
    ],
  },
];
